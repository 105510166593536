@import './helpers';

.AbstractContainer {
  border-color: $AbstractContainer-border-color;
  border-style: solid;
  border-width: 0;
  padding: map-get($AbstractContainer-padding, default);
}

.AbstractContainer--flexRow {
  display: flex;
  flex-direction: row;
}

.AbstractContainer--flexColumn {
  display: flex;
  flex-direction: column;
}

.AbstractContainer--flexGrow {
  flex-grow: 1;
}

.AbstractContainer--fullHeight {
  height: 100%;
}

.AbstractContainer--pulledTop {
  margin-top: -#{map-get($AbstractContainer-padding, default)};
}

.AbstractContainer--pulledBottom {
  margin-bottom: -#{map-get($AbstractContainer-padding, default)};
}

.AbstractContainer--pulledHorizontal {
  margin-left: -#{map-get($AbstractContainer-padding, default)};
  margin-right: -#{map-get($AbstractContainer-padding, default)};
}

.AbstractContainer--borderBottom {
  border-bottom-width: $AbstractContainer-border-width;
}

.AbstractContainer--borderTop {
  border-top-width: $AbstractContainer-border-width;
}

.AbstractContainer--borderRight {
  border-right-width: $AbstractContainer-border-width;
}

.AbstractContainer--borderLeft {
  border-left-width: $AbstractContainer-border-width;
}

.AbstractContainer--marginBottom {
  margin-bottom: map-get($AbstractContainer-padding, default);
}

.AbstractContainer--marginTop {
  margin-top: map-get($AbstractContainer-padding, default);
}

.AbstractContainer--scroll {
  overflow: auto;
}

.AbstractContainer--disablePadding {
  padding: 0;
}

.AbstractContainer--backgroundColor {
  @include AbstractContainer-background-color($AbstractContainer-background);
}
