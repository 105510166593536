@import '../_Field-helpers';

.TextInputWrapper {
  display: flex;
  flex-direction: row;
}

.TextInputWrapper__item {
  position: relative;
}

.TextInputWrapper__item--grow {
  flex-grow: 1;
}
