@import '../GroupCard/helpers.scss';

.GroupCardAccordion__badge {
  border: $border-width-sm solid;
  border-radius: $fn-border-radius-full;
  display: inline-block;
  font-size: $badge-font-size;
  height: $badge-width;
  margin: 0 $spacing-xs;
  text-align: center;
  width: $badge-width;
}

.GroupCardAccordion__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
};

.GroupCardAccordion__container--borderTop {
  border-top: $border-width-sm solid $color-gray-50;
}

.GroupCardAccordion__container--borderBottom {
  border-bottom: $border-width-sm solid $color-gray-50;
}

.GroupCardAccordion__labelContent {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin-right: $spacing-sm;
}

.GroupCardAccordion__labelContent--header {
  margin-right: $spacing-sm;
}

.GroupCardAccordion__labelContent--subNode {
  margin-right: $spacing-sm;
}

.GroupCardAccordion__labelContent--subHeading {
  color: $text-muted;
  margin: 0;
}

.GroupCardAccordion__labelMiddleContent--header {
  display: block;
  margin: auto;
}

.GroupCardAccordion__content {
  align-self: center;
  flex-grow: 1;
  padding: $spacing-md;
};

.GroupCardAccordion__label {
  align-self: center;
  flex-grow: 1;
  word-break: break-all;
  @media screen and (max-width: 782px) {
    display: flex;
    flex-basis: 100%;
  }
};

.GroupCardAccordion__value {
  align-self: center;
  flex-grow: 1;
  font-weight: bold;
  text-align: right;
};

.GroupCardAccordion--default {
  background-color: inherit;
}

.GroupCardAccordion--info {
  background-color: $color-blue-20;
}

.GroupCardAccordion--warning {
  background-color: $color-yellow-20;
}

.GroupCardAccordion--critical {
  background-color: $color-red-20;
}

.GroupCardAccordion__header--info {
  background-color: $color-blue-20;
  position: relative;
}

.GroupCardAccordion--hover {
  &:hover {
    background: rgba(0, 0, 0, .14);
    border-color: transparent;
    cursor: pointer;
  }
}

.GroupCardAccordion--clip {
  &::after {
    border-bottom-color: white;
    border-left: $spacing-sm solid transparent;
    border-right: $spacing-sm solid transparent;
    border-top: 1rem solid $color-white;
    content: '';
    height: 0;
    left: 0;
    margin-left: $spacing-md;
    margin-top: -$spacing-2-xs;
    position: absolute;
    top: 0;
    width: 0;
  }
}

.GroupCardAccordion__header--warning {
  background-color: $color-yellow-20;
  position: relative;
}

.GroupCardAccordion__header--critical {
  background-color: $color-red-20;
  position: relative;
}

.GroupCardAccordion__value--default {
  color: $color-gray-100;
}

.GroupCardAccordion__value--strikethrough {
  color: $color-gray-100;
  text-decoration: line-through;
}

.GroupCardAccordion__value--positive {
  color: $color-green-80;
}

.GroupCardAccordion__value--negative {
  color: $color-red-80;
}

.GroupCardAccordion__value--pending {
  color: $color-yellow-80;
}

.GroupCardAccordion__value--info {
  color: $color-blue-80;
}

.GroupCardAccordion__action {
  @include GroupCard-right();
  align-items: center;
  display: flex;
  justify-content: center;

  & > Button {
    color: $color-gray-100;

    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .14);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }

    span {
      margin-right: 0;
    }
  }
};

.GroupCardAccordion__expander {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $spacing-sm;
};

.GroupCardAccordion__wrap {
  @media screen and (max-width: 782px) {
    flex-wrap: wrap;
    gap: $spacing-sm;
  }
};

.GroupCardAccordion__wrap_label {
  @media screen and (max-width: 782px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 404px) {
    flex-wrap: wrap;
    padding-right: $spacing-xl*1.38;
  }
};

.IconComplete {
  color: $color-green-60;
};

.Icon {
  color: $color-indigo-60;
};
