@import './_IconAside-helpers';

.IconAside {
  overflow: hidden;
  position: relative;
}

@mixin IconAside-padding($selector,$isnarrow: false) {
  $iconWdith: $IconAside-iconWidth;
  $iconWdith-lg: $IconAside-iconWidth-lg;

  @if $isnarrow {
    $iconWdith: $IconAside-iconWidth * .75;
    $iconWdith-lg: $IconAside-iconWidth-lg * .75;
  }

  padding-left: $iconWdith;

  &#{$selector}--lg {
    padding-left: $iconWdith-lg;
  }

  &#{$selector}--reverse {
    padding-left: 0;
    padding-right: $iconWdith;
  }

  &#{$selector}--lg#{$selector}--reverse {
    padding-right: $iconWdith-lg;
  }
}

.IconAside {
  $rootSelector: &;
  @include IconAside-padding($rootSelector,false);

  &--narrow {
    @include IconAside-padding($rootSelector,true);
  }
}

.IconAside__iconContainer {
  $iconVerticalPadding: .3rem;
  left: 0;
  // Add vertical padding because most text has a bit of a line-height
  // and we don't want the icons completely flush
  padding-bottom: $iconVerticalPadding;
  padding-top: $iconVerticalPadding;
  position: absolute;

  > * {
    @include u-flushIcon();
  }
}

.IconAside__iconContainer--lg {
  font-size: 2rem;
}

.IconAside__iconContainer--reverse {
  left: auto;
  right: 0;
}

.IconAside__iconContainer--vMiddle {
  top: 50%;
  transform: translateY(-50%);
}

.IconAside__iconContainer__dot {
  $widthHeight: .45em;
  background-color: $base-color;
  border-radius: 50%;
  display: block;
  height: $widthHeight;
  width: $widthHeight;
}

@each $label, $map in $IconAside-colors {
  .IconAside__iconContainer--#{$label} {
    color: $map;

    .IconAside__iconContainer__dot {
      background-color: $map;
    }
  }
}
