@import '../Table/helpers.scss';

.TableBody {
  @include Table-head-and-body();
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
