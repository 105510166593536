@import './_Tabs-helpers';

@keyframes bubbleloading {
  from {
    opacity: 0;
    top: 100%;
  }

  50% {
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    top: -100%;
  }
}

.Tabs__tabList {
  @include u-resetList();
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -$Tabs-border-width;
  padding-left: $Tabs-border-width;
}

.Tabs__item {
  @include Tabs-item();
}

.Tabs__item__inner {
  @include Tabs-item-inner();
}

.Tabs__item--active {
  @include Tabs-item-active();
}

.Tabs__item__inner--active {
  @include Tabs-item-inner-active();
}

.Tabs__menu {
  @include Tabs-item();
  @include Tabs-item-active();
  cursor: pointer;
  display: inline-flex;
  margin-bottom: -$Tabs-border-width;
  margin-left: 0;
}

.Tabs__menuInner {
  @include Tabs-item-inner();
  @include Tabs-item-inner-active();
  margin-bottom: map-get($Tabs-item-padding, v);
  margin-left: map-get($Tabs-item-padding, h);
  padding-bottom: 0;
  padding-left: 0;
}

.Tabs__panel {
  background-color: map-get($Tabs-background, default);
  border: $Tabs-border-width solid $Tabs-border-color;
  padding: map-get($Tabs-panel-padding, v) map-get($Tabs-panel-padding, h);
}

.Tabs__panel__inner--isFlush {
  margin: -#{map-get($Tabs-item-padding, h)};
}
