@import './helpers.scss';

:global(.tether-target.tether-enabled) .SelectionMenu__icon::before {
  transform: rotate(180deg);
}

.SelectionMenu__icon {
  $size: 1rem;
  @include u-flushIcon();
  display: block;
  height: $size;
  width: $size;
}

.SelectionMenu__icon > svg { // use element selector because markup comes from @fieldnation/font
  display: block;
  fill: $color-gray-100;;
}
