@import './helpers.scss';

.CustomEvent {
  color: $base-color;
  line-height: 1.5;
}

.CustomEvent__label {
  margin-bottom: $spacing-xs;
}
