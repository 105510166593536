@import '../../scss/helpers/all';

$Tip-widthHeight: 1rem;
$Tip-shadowWidth: .4rem;
$Tip-widthHeightShadow: $Tip-widthHeight + $Tip-shadowWidth;
$Tip-widthHeightShadowHalf: calc($Tip-widthHeightShadow / 2);
$Tip-offset: 1rem;
$Tip-blue: $color-blue-100;
$Tip-padding: (
    vertical: $spacing-md,
    horizontal: $spacing-md
);
