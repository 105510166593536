@import './_Stars-helpers';

.stars {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.stars__icon {
  color: map-get($Stars-colors, yellow);
  font-size: map-get($Stars-fontSize, sm);
  position: relative;
}

.stars__icon--md {
  font-size: map-get($Stars-fontSize, md);
}

.stars__icon--lg {
  font-size: map-get($Stars-fontSize, lg);
}

.stars__icon--green {
  color: map-get($Stars-colors, green);
}

.stars__icon--orange {
  color: map-get($Stars-colors, orange);
}

.stars__icon + .stars__icon {
  margin-left: $Stars-spacing;
}

.stars__icon::before {
  @include u-icon();
  color: map-get($Stars-colors, grey);
  content: $Stars-icon;
}

.stars__icon::after {
  @include u-icon();
  left: 0;
  position: absolute;
  top: 0;
}

.stars__icon--half::after ,
.stars__icon--full::after {
  content: $Stars-icon;
}

.stars__icon--half::after {
  overflow: hidden;
  width: 50%;
}

.stars__icon {
  display: inline-block;
  vertical-align: top;

  &::before,
  &::after {
    line-height: 1;
    vertical-align: top;
  }
}
