@import "./ColumnConfig-helpers.scss";

.ColumnsDisplay__columnsPicker {
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ColumnsDisplay__columns {
  flex-grow: 1;
  flex-shrink: 0;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  @include bp-min(73.125em) {
    width: calc(100% - #{$ColumnConfig-sidebar-width});
  }
}

.ColumnsDisplay__columnsInner {
  padding-bottom: $spacing-md;
}

.ColumnsDisplay__selections {
  background-color: $color-white;
  flex: 0 0 $ColumnConfig-sidebar-width;
  max-height: 100%;
  overflow: auto;
  position: relative;
}

.ColumnsDisplay__selectionsRow {
  cursor: move;
  transition: background-color .25s ease-in-out;
}

.ColumnsDisplay__selectionsRow:hover {
  background-color: $color-gray-10;
  cursor: move;

  button {
    color: $color-red-100;
  }
}

.ColumnsDisplay__selectionsHd {
  background-color: $color-white;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ColumnsDisplay__selectionsHd__intro {
  padding: $spacing-lg $spacing-md;
}

.ColumnsDisplay__selectionsBd {
  flex: 1 1 auto;
}

.ColumnsDisplay__selectionsFt {
  bottom: 0;
  position: sticky;
}

.ColumnsDisplay__Tiles {
  padding: $spacing-md $spacing-md 0 $spacing-md;
}

.ColumnsDisplay__dividedList__item {
  border-bottom: $border-width-sm solid $color-gray-50;
  padding: $spacing-sm;
}

.ColumnsDisplay__sortIcon {
  color: $color-gray-50;
}

.ColumnsDisplay__updatedBy {
  width: 10rem;
}
