@import '../../legacy/Construction/Field/_Field-helpers';

$date-picker-default-size: 3.2rem;
$date-picker-width-xs: .1rem;
$date-picker-calendar-max: 25rem;

.DayPicker {
  background-color: $color-white;
  border: $date-picker-width-xs solid $color-gray-50;
  border-radius: $spacing-sm;
  font-size: $text-font-size-md;
  max-width: $date-picker-calendar-max;
  min-width: $date-picker-calendar-max;
  padding: 0;
  user-select: none;
}

.DayPicker__wrapper {
  display: inline-table;
  left: 1rem;
  padding: $spacing-sm;
  user-select: none;
  z-index: 5;
}

.DayPicker__row {
  display: flex;
  flex-direction: row;
}

.DayPicker__holder {
  position: relative;
  width: 100%;
}

.DayPicker__holder-overlay {
  position: absolute;
  z-index: 100;
}

.DayPicker__holder-overlay + input {
  display: block;
}

.DayPicker__months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker__month {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  user-select: none;
}

.DayPicker__navBar {
  display: table-caption;
  font-family: $text-font-family-default;
  font-size: $text-font-size-md;
  padding: $spacing-sm;
  position: relative;
  top: $spacing-sm;
  user-select: none;
}

.DayPicker__caption {
  display: none;
}

.DayPicker__weekdays {
  display: table-header-group;
}

.DayPicker__weekdays-row {
  display: table-row;
}

.DayPicker__weekday {
  color: $color-gray-50;
  display: table-cell;
  font-family: $text-font-family-default;
  font-size: $text-font-size-md;
  text-align: center;
}

.DayPicker__weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker__body {
  display: table-row-group;
}

.DayPicker__week {
  display: table-row;
}

.DayPicker__day {
  color: $color-gray-80;
  cursor: pointer;
  display: table-cell;
  font-family: $text-font-family-default;
  font-size: $text-font-size-sm;
  padding: $spacing-sm;
  text-align: center;
  vertical-align: middle;
}

.DayPicker__week-number {
  border-right: $date-picker-width-xs solid $color-gray-50;
  color: $color-blue-20;
  cursor: pointer;
  display: table-cell;
  font-size: $text-font-size-md;
  min-width: $spacing-sm;
  padding: $spacing-sm;
  text-align: right;
  vertical-align: middle;
}

.DayPicker__disabled-interaction .DayPicker__day {
  cursor: default;
}

.DayPicker__footer {
  padding-top: $spacing-sm;
}

.DayPicker__today-button {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: $color-orange-20;
  cursor: pointer;
  font-size: $text-font-size-sm;
}

/* Default modifiers */
.DayPicker__outside {
  color: $color-gray-40;
  cursor: default;
  font-family: $text-font-family-semi-bold;
}

.DayPicker__disabled {
  color: $color-gray-30;
  cursor: default;
}

.DayPicker__today {
  font-family: $text-font-family-bold;
  font-size: $text-font-size-sm;
}

.DayPicker__input {
  display: inline-block;
}

.DayPicker__input-overlay-wrapper {
  position: relative;
}

.DayPicker__input-overlay {
  background: white;
  box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .15);
  left: 0;
  position: absolute;
  z-index: 1;
}

.DayPicker__selected-start {
  border-bottom-left-radius: $spacing-md;
  border-top-left-radius: $spacing-md;
}

.DayPicker__selected-end {
  border-bottom-right-radius: $spacing-md;
  border-top-right-radius: $spacing-md;
}

.DayPicker__selected {
  background-color: $color-blue-40;
}

.DayPicker__selected-single {
  background-color: $color-blue-40;
  border-radius: $spacing-md;
}

.Range_Middle_Text {
  align-items: flex-end;
  color: $color-gray-100;
  display: flex;
  font-size: $text-font-size-sm;
  padding: $spacing-sm;
  padding-left: 0;
  padding-right: $spacing-md;
}
