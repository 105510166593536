@import '../../../scss/helpers/_all';

.Listing__barContainer {
  margin-bottom: $spacing-md;
  position: sticky;
  top: 0;
  z-index: 4;
}

.Listing__TabsContainer {
  margin-bottom: $spacing-md;
}
