@import '~react-phone-number-input/style.css';
@import './PhoneInput';

// Overrides for react-phone-number-input
.react-phone-number-input__icon-image {
  vertical-align: top;
}

.react-phone-number-input__input {
  @extend .PhoneInput;
}

.react-phone-number-input__input:focus {
  @extend .PhoneInput;
}

.react-phone-number-input__ext {
  margin-bottom: 0;
  max-width: 10rem;
}
