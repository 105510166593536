@import './helpers.scss';

.ActionsMenu {
  position: relative;
}

.ActionsMenu--isTypePrimary {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ActionsMenu__text {
  padding-right: $spacing-lg;
}

.ActionsMenu__text--isTypePrimary {
  padding-right: $spacing-sm;
}

.ActionsMenu__icon {
  bottom: 0;
  color: $color-gray-60;
  display: flex;
  position: absolute;
  right: 0;
}

.ActionsMenu__icon--isTypePrimary {
  pointer-events: none;
}
