@import './helpers.scss';

.Menu {
  display: inline-block;
  position: relative;
}

.Menu--block {
  display: block;
}

.Menu__menu {
  background-clip: padding-box;
  background-color: $color-white;
  border: $border-width-sm solid rgba(0, 0, 0, .15);
  border-radius: $btn-radius;
  max-height: 45vh;
  min-width: 1.6rem;
  overflow: auto;
}

.Menu__menu--isOpen {
  display: block;
}

.Menu__menu--noWrap {
  white-space: nowrap;
}
