@import './helpers.scss';

.ActionTabs {
  background-color: $color-gray-10;
  border: $border-width-sm solid $color-gray-60;
  border-radius: $fn-border-radius-sm;
  display: flex;
}

.ActionTabs__item {
  @include u-resetButton();
  color: $color-gray-90;
  flex-grow: 1;
  font-family: $text-font-family-semi-bold;
  padding: $spacing-sm 0;
}

.ActionTabs__itemCount {
  font-family: $text-font-family-default;
  padding-left: $spacing-xs;
}

.ActionTabs__item--active.ActionTabs__item--active {
  background-color: $color-indigo-20;
  color: $color-gray-100;
}

.ActionTabs__item + .ActionTabs__item {
  border-left: $border-width-sm solid $color-gray-60;
}
