@import '../HorizontalList/helpers.scss';

$Option-svg-wh: 1.6rem;
$Option-fixed-line-height: 2.2rem;
$Options-icon-spacing-units: 1;
$Options-spacing: $Options-icon-spacing-units * $HorizontalList-spacing-unit;
$Options-secondary-padding: $Options-spacing + $Option-svg-wh;
$Options-svg-transition-miliseconds: 150;

:export {
  icon-spacing-unit: $Options-icon-spacing-units;
  svg-transition: $Options-svg-transition-miliseconds;
}
