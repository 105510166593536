@import '../Table/helpers.scss';

.TableRow {
  display: flex;
  flex-direction: row;
  position: relative;
  @include Table-color-modifiers();
}

.TableRow--notFirst {
  @include Table-cell-border-before-psuedo();
}

.TableRow--distinctBg {
  background-color: $color-gray-20;
}

.TableRow--border {
  border-bottom: $border-width-sm solid $color-gray-50;
}

.TableRow--maxContent {
  width: max-content;
}
