@import './Switch-helpers';

.Switch__withSideLabel {
  display: flex;
  flex-wrap: nowrap,
}

.Switch__withSideLabel--inlineFlex {
  display: inline-flex;
}

.Switch {
  @include u-resetLabel();
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans SemiBold';
  height: $switch-height;
  position: relative;
  width: $switch-width;
}

.Switch--withSideLabel {
  vertical-align: middle;
}

.Switch--block {
  display: block;
}

.Switch--disabled {
  cursor: not-allowed;
}

.Switch__yesNo {
  @include Switch-yesNo(map-get($switch-background-color, gray));
}

.Switch__yesNo__danger {
  @include Switch-yesNo(map-get($switch-background-color, gray));
}


.Switch__yesNo__redUnchecked {
  background-color: map-get($switch-background-color, red);
  border-color: map-get($switch-border-color, red);
}

.Switch__input:checked ~ .Switch__yesNo {
  background-color: map-get($switch-background-color, indigo);
  border-color: map-get($switch-border-color, indigo);
}

.Switch__input:checked ~ .Switch__yesNo__danger {
  background-color: map-get($switch-background-color, red);
  border-color: map-get($switch-border-color, red);
}


.Switch__yesNo::before,
.Switch__yesNo::after {
  display: block;
  font-size: $switch-font-size;
  line-height: 1.7;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateZ(0);
  transition: opacity $switch-animation-duration $switch-animation-type;
}

.Switch__yesNo--disabled {
  $color: map-get($switch-border-color, disabled);
  border-color: $color;
}

// no
.Switch__yesNo::after {
  color: $switch-color-off;
  content: attr(data-off);
  opacity: 1;
  right: $switch-yesno-h-offset;
}

.Switch__input:checked ~ .Switch__yesNo::after {
  opacity: 0;
}

.Switch__yesNo--disabled {
  $color: map-get($switch-border-color, disabled);

  &::before,
  &::after {
    color: $color;
  }
}

// yes
.Switch__yesNo::before {
  color: $switch-color-on;
  content: attr(data-on);
  left: $switch-yesno-h-offset;
  opacity: 0;
}

.Switch__input:checked ~ .Switch__yesNo::before {
  opacity: 1;
}

.Switch__yesNo__redUnchecked::after {
  color: $switch-color-on;
}

.Switch__input:checked ~ .Switch__yesNo::after {
  opacity: 0;
}

.Switch__input {
  opacity: 0;
  position: absolute;
}

.Switch__handle {
  @include Switch-handle();
}

.Switch__handle--disabled {
  box-shadow: map-get($switch-handle-shadow, disabled);
}

.Switch__input:checked ~ .Switch__handle {
  left: $switch-width - $switch-height + $switch-border-width;
}


.Switch__label {
  @include u-srOnly();
}

.Switch__withSideLabelSwitch {
  margin-right: $spacing-md;
}

.Switch__withSideLabelText {
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
}
