@import './_Stars-helpers';

.StarRating {
  font-size: 0;// remove white-space
}

.StarRating__item {
  display: inline-block;
  font-size: map-get($Stars-fontSize, sm);
  line-height: 1;

  & + & {
    margin-left: $Stars-spacing;
  }
}

.StarRating__item--md {
  font-size: map-get($Stars-fontSize, md);
}

.StarRating__label {
  color: map-get($Stars-colors, grey);
  cursor: pointer;
  height: 1em;
  line-height: 1;
  margin-bottom: 0;
  overflow: hidden;
  transition: all .2s ease-in-out;
  width: 1em;

  &::before {
    @include u-icon();
    content: $icon-star;
    display: block;
  }
}

.StarRating__label--yellow {
  color: map-get($Stars-colors, yellow);
}

.StarRating__label--green {
  color: map-get($Stars-colors, green);
}
