@import './helpers.scss';

.Typography--title {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xxl;
  letter-spacing: $text-letter-spacing-1;
  line-height: $text-line-height-xl;
}

.Typography--heading {
  font-family: $text-font-family-default;
  font-size: $text-font-size-lg;
  letter-spacing: $text-letter-spacing-2;
  line-height: $text-line-height-md;
}

.Typography--body,
.Typography--bodySemibold {
  font-size: $text-font-size-sm;
  line-height: $text-line-height-sm;
}

.Typography--bodySemibold {
  font-family: $text-font-family-semi-bold;
}

.Typography--sub,
.Typography--subBold {
  font-size: $text-font-size-xs;
  line-height: $text-line-height-xs;
}

.Typography--subBold {
  font-weight: bold;
}
