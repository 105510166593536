@import '../_Field-helpers';

.TimePicker__dropdown {
  background-color: map-get($Field-background-color, default);
  border: $FieldInput-border;
  border-radius: $FieldInput-border-radius;
  box-shadow: $Field-menu-shadow;
  max-height: 50vh;
  overflow: auto;
}

:global(.tether-element-attached-top) .TimePicker__dropdown {
  margin-top: $Field-menu-vertical-offset;
}

:global(.tether-element-attached-bottom) .TimePicker__dropdown {
  margin-bottom: $Field-menu-vertical-offset;
}

.TimePicker__dropdownList {
  @include u-resetList();
}

.TimePicker__dropdownItem {
  @include Field-input-transition();
  background-color: map-get($Field-background-color, default);
  cursor: pointer;
  padding: $spacing-xs;

  &:hover {
    background-color: map-get($Field-background-color, itemHover);
  }
}
