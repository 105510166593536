@import '../../scss/helpers/all';
@import '../legacy/Construction/Field/_Field-helpers.scss';
@import './helpers.scss';

.Search {
  display: flex;
  height: 100%;
  position: relative;
}

.Search__focusIndicator {
  border-bottom: $border-width-md solid transparent;
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: all .2s ease-in-out;
}

.Search__input {
  @include Field-input-element-styles();
  border: none;
  display: flex;
  height: 100%;

  &:focus {
    box-shadow: none;
  }

  &:focus ~ .Search__focusIndicator {
    border-bottom-color: $color-indigo-60;
  }
}

.Search__input--init { // initialize the collapse input
  visibility: hidden;
  width: 0;
}

.Search__input--collapsed {
  transition: all .25s ease-in-out;
  visibility: hidden;
  width: 0;
}

.Search__input--expanded {
  transition: all .25s ease-in-out;
  visibility: visible;
  width: $search-input-expanded-width;
}

.Search__input--hasIconLeft {
  padding-left: $spacing-md * 2 + $spacing-lg;
}

.Search__input--hasIconRight {
  padding-left: $spacing-md;
}

.Search__label {
  @include u-srOnly();
}

.Search__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Search__icon--left {
  left: $spacing-md;
}

.Search__icon--right {
  right: .7rem;
}

.Search__icon--rightSpacingMd {
  right: $spacing-md;
}
