@import '../../../../../scss/helpers/_all';

$Stars-colors: (
    yellow: $color-yellow-80,
    green: $color-green-100,
    orange: $color-orange-100,
    grey: $color-gray-60
);

$Stars-fontSize: (
    sm: 1.4rem,
    md: 1.6rem,
    lg: 2.4rem
);

$Stars-icon: $icon-star;
$Stars-spacing: .2rem;
