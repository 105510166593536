@import './helpers.scss';

.Table {
  position: relative;
}

.Table__table {
  display: block;
  font-size: $text-font-size-xs;
}

.Table__footer {
  $height: .1rem;
  bottom: 0;
  margin-top: -$height;
  overflow-x: auto;
  overflow-y: hidden;
  position: sticky;
  width: 100%;
  z-index: $Table-footer-zindex;

  &Inner {
    height: $height;
  }
}
