@import './_NavigationMenu-helpers.scss';

.MenuItem {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.MenuItem__link {
  @include u-resetButton();
  @include u-resetLabel();
  box-sizing: border-box;
  color: $color-gray-100;
  display: block;
  padding: $spacing-sm $NavigationMenu-horizontal-padding;
  width: 100%;
}

.MenuItem__link[disabled] {
  color: $color-gray-60;
  cursor: not-allowed;
}

.MenuItem__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuItem__checkbox {
  padding-right: $spacing-sm;
}

.MenuItem__iconOrCount {
  display: flex;
  margin-left: auto;
  padding-left: $spacing-md;
}

.MenuItem__iconWithMoreItems {
  display: flex;
}

.MenuItem__iconWithMoreWrapper {
  display: flex;
}

.MenuItem--iconWithMore {
  display: flex;
  justify-content: space-between;
}

.MenuItem__iconWrapper {
  display: flex;
  margin-left: $spacing-sm;
}

.MenuItem__iconActive {
  position: relative;
}

.MenuItem__iconActive::before {
  background-color: rgba(black, .05);
  border-radius: 50px;
  content: '';
  height: 33px;
  position: absolute;
  transform: translate(-5px, -4px);
  width: 33px;
}

.MenuItem__iconWithMoreWrapper .MenuItem__actionIcon {
  outline: 0;
}

.MenuItem__leftIcon {
  display: flex;
  padding-right: $spacing-sm;
}

.MenuItem__icon {
  @include u-resetButton();
  color: $color-gray-80;
}

.MenuItem__icon--green {
  color: $color-green-80;
}

.MenuItem__icon--blue {
  color: $color-blue-100;
}

.MenuItem__icon--yellow {
  color: $color-yellow-80;
}

.MenuItem__icon--red {
  color: $color-red-80;
}

.MenuItem__icon--indigo {
  color: $color-indigo-60;
}

.MenuItem__icon--orange {
  color: $color-orange-60;
}

.MenuItem__icon--gray {
  color: $color-gray-80;
}
