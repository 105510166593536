@import './SummaryView-helpers';

.SummaryView__container {
  background: $color-white;
  border-bottom: $border-width-sm solid $color-gray-50;
  border-top-left-radius: $fn-border-radius-md;
  border-top-right-radius: $fn-border-radius-md;
  box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.14), 0 .2rem .1rem -.1rem rgba(0,0,0,.12), 0 .1rem .3rem 0 rgba(0,0,0,.2);
  padding: $spacing-md;

  &>p {
    color: $color-gray-80;
    font-style: italic;
    margin: 0;
    padding: $spacing-md 0;
  }
}

.SummaryView__content {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.SummaryCard__container {
  border: $border-width-sm solid $color-gray-50;
  border-radius: $fn-border-radius-md;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  @media (max-width: 1200px) {
    min-width: auto;
  }

  .SummaryCard__content__title {
    color: $color-gray-100;
    font-size: $text-font-size-xxl;
    line-height: $text-font-size-xl;
    margin-bottom: $spacing-sm;
  }

  .SummaryCard__content__subtitle {
    color: $color-gray-90;
    font-size: $text-font-size-sm;
    margin-bottom: 0;
    margin-right: $spacing-sm;
  }

  .SummaryCard__content__meta {
    color: $color-gray-80;
    font-size: $text-font-size-xs;
    margin-bottom: 0;
  }

  .SummaryCard__content__icon {
    color: $color-gray-80;
  }
}

.SummaryCard__content {
  align-items: flex-start;
  background: $color-gray-10;
  border-radius: $fn-border-radius-md;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: $spacing-md;
}

.SummaryCard__content__main {
  padding-left: $spacing-sm;

  &>div {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-sm + $spacing-xs;
  }
}
