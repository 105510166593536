@import '../Table/helpers.scss';

.TableColumnHeader {
  @include Table-cell();
  line-height: $text-letter-spacing-3;
  min-height: 48px;
  vertical-align: bottom;
}

.TableColumnHeader:hover {
  background-color: $color-gray-10;
}

.TableColumnHeader:hover:not(.TableColumnHeader--sticky):not(.TableColumnHeader--stickyBorderLeft):not(.TableColumnHeader--isLastColumn) {
  border-right: $border-width-md solid $color-gray-60;
}

:global(.react-draggable-transparent-selection) {
  .TableColumnHeader:hover:not(.TableColumnHeader--sticky) {
    border-right: none;
  }
}

.TableColumnHeader--config {
  position: absolute !important; /* stylelint-disable declaration-no-important */
}

.TableColumnHeader--sticky {
  @include Table-cell-sticky();
}

.TableColumnHeader--stickyBorderRight {
  border-right: $Table-border;
}

.TableColumnHeader--stickyBorderLeft {
  border-left: $Table-border;
}

.TableColumnHeader.TableColumnHeader--isLastColumn:hover {
  border-right: none;
}

.TableColumnHeader--isLastColumn {
  :global(.react-resizable-handle) {
    display: none;
  }
}

.TableColumnHeader__outer {
  @include Table-cellOuter();

  :global(.react-resizable-handle) {
    bottom: 0;
    cursor: col-resize;
    position: absolute;
    right: 0;
    top: 0;
    width: .4rem;
  }
}

.TableColumnHeader__outer--horizontalSm {
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
}

.TableColumnHeader__outer--horizontalCenter {
  justify-content: center;
}

:global(.react-draggable-transparent-selection) {
  :global(.resizing) {
    border-right: $border-width-md solid $color-indigo-60;
  }
}

.TableColumnHeader__inner {
  @include Table-cellInner();
  color: $color-gray-90;
  display: flex;
  font-weight: 400;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
}

.TableColumnHeader__inner--sortable {
  cursor: pointer;
}

.TableColumnHeader__sortIcon {
  margin-left: $spacing-xs;
}

.TableColumnHeader__sortIcon--hoverDisplay {
  display: none;
}

.TableColumnHeader--sortable:hover .TableColumnHeader__sortIcon--hoverDisplay {
  display: block;
}

.TableColumnHeader__sortIcon--visible {
  display: block;
}

// disables sortable icon appearance while resizing on the adjacent column
:global(.react-draggable-transparent-selection) {
  .TableColumnHeader--sortable:hover .TableColumnHeader__sortIcon--hoverDisplay {
    display: none;
  }
}

.TableColumnHeader__sortIcon--active {
  color: $color-indigo-100;
}

.TableColumnHeader__innerText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
