@import './helpers.scss';

.MoreMenu__menuWrapper {
  left: -1px !important; /* stylelint-disable-line declaration-no-important */
  overflow: hidden;
  z-index: 99;
  // border of Create and list of Create menu are not showing same align due to shade. forcefully used left: -1px to adjust it.
}

.MoreMenu__menu {
  box-shadow: 0 .3rem .4rem 0 rgba(0,0,0,.14), 0 .3rem .3rem -.2rem rgba(0,0,0,.12), 0 .1rem .8rem 0 rgba(0,0,0,.2);
}

/* stylelint-disable */
// need to show overflow when tether is open to correctly show box-shadow
:global(.tether-element-isOpened):not(:global(.tether-element-isClosing)).MoreMenu__menuWrapper {
  overflow: visible;
}
