@import '../../scss/helpers/_all.scss';

$flashAlert-colors: (
  danger: $color-red-100,
  info: $color-indigo-80,
  success: $color-green-100,
  warning: $color-orange-100,
  content: $color-white
);
$flashAlert-padding: $spacing-md;
$flashAlert-width: 38.5rem - ($flashAlert-padding * 2);
$flashAlert-icon-font-size: 2.6rem;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@mixin flashAlert-spin() {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
}

$flashAlert-breakpoint: map-get($screen-sizes, md);

/*
  These styles have been abstracted into mixins to be re-purposed
  for react-notification-system since it's markup cannot be changed
 */

@mixin flashAlert-root() {
  $padding: $flashAlert-padding;
  background-color: map-get($flashAlert-colors, content);
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: .6rem;
  box-shadow: 0 .8rem 4.5rem 0 rgba(0, 0, 0, .25);
  display: inline-block;
  font-size: 1.4rem;
  padding: $flashAlert-padding;
  width: $flashAlert-width;
  z-index: map-get($z-index, flash-alert);
}

@mixin flashAlert-danger() {
  border-top-color: map-get($flashAlert-colors, danger);
}

@mixin flashAlert-info() {
  border-top-color: map-get($flashAlert-colors, info);
}

@mixin flashAlert-success() {
  border-top-color: map-get($flashAlert-colors, success);
}

@mixin flashAlert-warning() {
  border-top-color: map-get($flashAlert-colors, warning);
}

@mixin flashAlert-heading() {
  font-size: 2rem;
  margin-bottom: $spacing-xs;
}

@mixin flashAlert-icon-danger() {
  background-color: map-get($flashAlert-colors, danger);
}

@mixin flashAlert-icon-info() {
  background-color: map-get($flashAlert-colors, info);
}

@mixin flashAlert-icon-success() {
  background-color: map-get($flashAlert-colors, success);
}

@mixin flashAlert-icon-warning() {
  background-color: map-get($flashAlert-colors, warning);
}
