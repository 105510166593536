@import '../../Table/helpers.scss';

.Table__wrapper {
  box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.14), 0 .2rem .1rem -.1rem rgba(0,0,0,.12), 0 .1rem .3rem 0 rgba(0,0,0,.2);
}

.Table__footer {
  background-color: $color-white;
  border-top: $Table-border;
  padding: $spacing-sm $spacing-md;

  &--sticky {
    @extend .Table__footer;
    bottom: 0;
    position: sticky;
    z-index: 3;
  }
}

.Table__inlineActions--position {
  position: relative;
}

.StaticAction__buttons {
  display: inline-flex;
}

.StaticAction__content {
  display: inline-flex;
  overflow: hidden;
  vertical-align: middle;
}
