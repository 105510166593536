// Font Sizes
$font-size-root: $font-size-root-from-config;
$font-size-base: $base-font-size;
$font-family-base: $base-font-family;

// Colors
$gray-dark:         $base-color !default;
$gray:            #3a3a3a !default;
$gray-light:        #5C5C5C !default;
$gray-lighter:        $color-gray-60;

$brand-primary:       #ff6300 !default;
$brand-success:       map-get($abstractInput-colors, success);
$brand-info:        #8aa5ff !default;
$brand-warning:       map-get($abstractInput-colors, warning);
$brand-danger:        map-get($abstractInput-colors, danger);

$enable-flex: true;

$grid-gutter-width: 2rem;

$text-muted:        $color-gray-80;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element
$font-size-root:      14px !default;

$line-height: $text-line-height-base;

$link-color: $color-indigo-60;
$link-color__secondary: $color-gray-80;
$link-hover-color: $color-indigo-100;
$link-hover-decoration: none;

$rem-modifier:        0.7692307692;

$font-size-base:       calc(1rem/$rem-modifier) !default;
$font-size-lg:         1.625rem !default;
$font-size-sm:         calc(.875rem/$rem-modifier) !default;
$font-size-xs:         calc(.75rem/$rem-modifier) !default;

$font-size-h1:         calc(2.5rem/$rem-modifier) !default;
$font-size-h2:         calc(2rem/$rem-modifier) !default;
$font-size-h3:         calc(1.75rem/$rem-modifier) !default;
$font-size-h4:         calc(1.5rem/$rem-modifier) !default;
$font-size-h5:         calc(1.25rem/$rem-modifier) !default;
$font-size-h6:         calc(1rem/$rem-modifier) !default;

$display1-size:         calc(6rem/$rem-modifier) !default;
$display2-size:         calc(5.5rem/$rem-modifier) !default;
$display3-size:         calc(4.5rem/$rem-modifier) !default;
$display4-size:         calc(3.5rem/$rem-modifier) !default;

// over-ride bootstrap grid-breakpoints with our breakpoints
$grid-breakpoints: $screen-sizes;

$border-radius: $fn-border-radius-sm;
$border-radius-lg: $fn-border-radius-sm;
$border-radius-sm: $fn-border-radius-xs;

$input-bg-disabled: $color-gray-30;

$input-border-color: $color-gray-50;

$input-border-radius: $border-radius-lg;

$input-border-focus: $color-blue-60;

$input-group-addon-bg: $color-gray-30;

$pagination-active-bg: map-deep-get($oldColorPalette, blue, dark);
$pagination-active-border: map-deep-get($oldColorPalette, blue, dark);
$pagination-padding-x: 1rem;
$pagination-color: $color-gray-70;

$table-bg-accent: $color-gray-10;
$table-border-color: $color-gray-50;

$alert-border-radius: .2rem;
