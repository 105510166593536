@import './helpers.scss';

.ActionBar {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.ActionBar--align-center {
  justify-content: center;
}

.ActionBar--align-right {
  justify-content: flex-end;
}

.ActionBar--align-left {
  justify-content: flex-start;
}

.ActionBar__tertiary {
  flex-grow: 1;
  height: 100%;
}

.ActionBar__primary {
  padding-bottom: $ActionBar-padding-vertical;
  padding-right: $ActionBar-padding-horizontal;
  padding-top: $ActionBar-padding-vertical;
}

.ActionBar__dividedList {
  display: inline-flex;
  height: 100%;
}

.ActionBar__dividedListItem {
  align-items: center;
  display: inline-flex;
  padding: $ActionBar-padding-vertical $ActionBar-padding-horizontal;
}

.ActionBar__dividedListItem + .ActionBar__dividedListItem {
  border-left: $border-width-sm solid $color-gray-50;
}
