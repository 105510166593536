@import './helpers.scss';

.Item {
  @include u-resetButton();
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex-direction: row;
  font-size: $base-font-size;
  padding: $spacing-xs $spacing-md;
  text-align: left;
  transition: background-color .25s ease-in-out;
  width: 100%;
}

.Item--hasExtraSpacing {
  padding: $spacing-sm $spacing-md;
}

.Item:hover {
  background-color: $color-gray-10;
}

.Item__inner--icon {
  color: $color-gray-80;
}

.Item__inner + .Item__inner {
  margin-left: $spacing-sm;
}

.Item__inner--hasExtraSpacing + .Item__inner--hasExtraSpacing {
  margin-left: $spacing-md;
}

.Item:disabled {
  opacity: $btn-disabledOpacity;
}
