@import './DividedList-helpers';

$dividedList-border-color: $color-gray-100;

.dividedList {
  @include container-border(top);
}

.dividedList__item {
  @include container-root(sm);
  @include container-border(bottom);
}
