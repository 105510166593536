@import './helpers.scss';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';

.fn-calendar {
  background-color: $color-white;
  // Global Overrides
  &.rbc-calendar {
    min-height: 55rem;
  }

  .rbc-header {
    font-weight: normal;
    padding: $spacing-sm;
    text-align: left;
  }

  // hide the all day cell until we upgrade because it shows events over all resources
  .rbc-allday-cell {
    display: none;
  }

  // hide the time headers for each cell, repeated info and makes no sense
  .rbc-event-label {
    display: none;
  }

  // fixed bottoms of titles getting cut off
  .rbc-timeslot-group {
    min-height: 6rem;
  }

  //time gutter overrides
  .rbc-time-gutter .rbc-time-slot {
    font-size: 1.2rem;
    text-align: right;
  }

  //time gutter overrides for utilization page for day detail drawer
  &.fn-time-gutter-style {
    .rbc-time-gutter .rbc-time-slot {
      font-size: $text-font-size-xs;
      padding-top: $spacing-sm;
      text-align: right;
    }
  }

  //remove the today blue background for now
  .rbc-today {
    background-color: $color-white;
  }

  // Toolbar Overrides
  .rbc-btn-group {
    button {
      font-family: "Open Sans SemiBold", sans-serif;
      font-size: 1.2rem;
      font-weight: 800;
      transition: all ease-in-out .2s;

      &.rbc-active {
        box-shadow: none;
      }
    }
  }

  .fn-month-content {
    overflow: auto;
  }

  // Month Overrides
  .rbc-month-view {
    border-top: none;
    min-height: 110rem;

    .rbc-row-segment {
      margin-bottom: $spacing-xs;
      text-align: center;
    }

    .rbc-show-more {
      padding-top: $spacing-xs;
    }

    .rbc-off-range-bg {
      background-color: $color-gray-10;
      opacity: .5;
    }

    .rbc-event {
      margin-right: $spacing-sm;
      padding: $spacing-xs $spacing-xs 0 $spacing-xs;
      width: calc(100% - #{$spacing-sm});
    }
  }

  .rbc-date-cell {
    font-size: 1.2rem;
    font-weight: bold;
    padding: $spacing-sm;
    text-align: left;

    &.rbc-off-range {
      font-weight: normal;
    }
  }

  // Week Overrides
  .rbc-time-view {
    border: none;

    .rbc-row {
      min-height: 3.2rem;
    }

    .rbc-header {
      border-bottom: none;
      text-align: center;

      .rbc-time-header-cell {
        a {
          text-align: center;
        }
      }
    }
  }

  // Event Overrides
  .rbc-event {
    $top-border-width: .4rem;
    $padding: $spacing-xs;
    background-color: $color-blue-20;
    border: .2rem solid $color-blue-100;
    border-radius: $fn-border-radius-sm;
    color: $color-gray-100;
    min-height: 3rem;
    padding: $padding;
    padding-top: $top-border-width + $padding;
    position: relative;
    transition: background-color .25s ease-in-out, border-color .25s ease-in-out;

    &::before {
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  // need to re-apply position absolute from library css
  .rbc-day-slot .rbc-event {
    position: absolute;
  }

  .rbc-event-content {
    font-family: map-get($fonts, opensanssemibold);
    font-size: 1.2rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }

  .rbc-time-header-content {
    border-left: 0;
    position: relative;

    &::after {
      border-left: .1rem solid $color-gray-40;
      bottom: 0;
      content: ' ';
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .rbc-current-time-indicator {
    background-color: $color-orange-60;
    height: .2rem;
  }

  .rbc-time-header-content + .rbc-time-header-content {
    margin-left: 0;
  }

  .fn-calendar-day,
  .fn-calendar-week {
    .rbc-time-header-gutter,
    .rbc-time-gutter {
      border-right-color: $Calendar-border-color-dark;
    }

    .rbc-time-header-content {
      border-left: 0;
      position: relative;

      &::after {
        border-left: .1rem solid $color-gray-60;
        bottom: 0;
        content: ' ';
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }

  .fn-calendar-day {
    .rbc-time-content {
      .rbc-day-slot:nth-child(1n) .rbc-events-container {
        border-left-color: $Calendar-border-color-dark;
      }
    }
  }

  .fn-calendar-week {
    .rbc-header {
      width: 5rem;
    }

    .rbc-day-slot {
      min-width: 5rem;
    }

    .rbc-time-content {
      .rbc-day-slot:nth-child(7n+2) .rbc-events-container {
        border-left-color: $Calendar-border-color-dark;
      }
    }
  }
}

// adding hover to the open space if they are selectable of the time slots and removing pointer events
.fn-calendar-selectable {
  .rbc-day-slot {
    .rbc-timeslot-group {
      .rbc-time-slot:hover {
        background-color: $color-gray-40;
        transition: background-color .25s ease-in-out;
      }
    }

    .rbc-events-container {
      pointer-events: none;
    }

    .rbc-events-container > * {
      pointer-events: auto;
    }
  }
}
