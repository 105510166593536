@import './helpers.scss';

.Truncate {
  margin-right: -1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  overflow: hidden;
  padding-right: 1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  position: relative;

  &::before {
    bottom: 0;
    content: '\2026';
    position: absolute;
    right: .2rem; // not sure why this gets cutt off in DataTable without this
  }

  &::after {
    background-color: $Truncate-background-color;
    content: '';
    height: 1em;
    margin-top: .2em; // stylelint-disable-line declaration-property-unit-disallowed-list
    position: absolute;
    right: 0;
    width: 1em;
  }

  &--breakAll {
    word-break: break-all;
  }

  &--keepAll {
    word-break: keep-all;
  }

  &--breakWord {
    word-break: break-word;
  }

  // native line-clamp styles

  @supports (-webkit-line-clamp: 3) {
    -webkit-box-orient: vertical; // stylelint-disable property-no-vendor-prefix
    display: -webkit-box;

    &::before,
    &::after {
      display: none !important; // stylelint-disable declaration-no-important
    }
  }
}



