@import '../../scss/helpers/all';

.FileInput {
  background-color: $color-gray-10;
  border: $border-width-sm dashed $color-gray-50;
  border-radius: .4rem;
}

.FileInput--activeAccept {
  border-color: $color-green-100;
}

.FileInput--activeReject {
  border-color: $color-red-100;
}

.FileInput--disabled {
  background-color: $color-white;
  border-style: solid;
  transition: all .25s ease-in-out;
}

.FileInput__section {
  padding: $spacing-sm $spacing-md;
}

.FileInput__section + .FileInput__section {
  border-top: $border-width-sm solid $color-gray-50;
}

.FileInput__section--center {
  text-align: center;
}

.FileInput__helpText {
  color: $color-gray-80;
  margin-top: $spacing-sm;
}

.FileInput__upload-button {
  margin-left: $spacing-xs;
}
