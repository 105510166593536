@import './helpers.scss';
@import '../Search/helpers.scss';

.Toolbar {
  background-color: $color-white;
  border-radius: $fn-border-radius-sm;
  box-shadow: 0 .2rem .2rem 0 rgba(0,0,0,.14), 0 .3rem .1rem -.2rem rgba(0,0,0,.12), 0 .1rem .5rem 0 rgba(0,0,0,.2);
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  overflow: hidden;
}

.Toolbar__item {
  align-items: center;
  display: flex;
}

.Toolbar__search {
  display: block;
}

.Toolbar__search--collapsed {
  min-width: $search-input-collapsed-width;
  transition: all .25s ease-in-out;
}

.Toolbar__search--expanded {
  min-width: $search-input-expanded-width;
  transition: all .25s ease-in-out;
}

.Toolbar__item + .Toolbar__item {
  border-left: $border-width-sm solid $color-gray-40;

  .Toolbar__itemInner {
    margin: 0 auto;
  }
}

.Toolbar__item--main {
  flex-grow: 1;
}

.Toolbar__itemInner {
  padding: $spacing-sm;
}

.Toolbar__itemPaddingSm {
  padding: $spacing-sm;
}

.Toolbar__itemInner--main {
  flex-basis: 100%;
}

.Toolbar__viewSwitcherContainer {
  background-color: $color-white;
  box-shadow: 0 .3rem .4rem 0 rgba(0,0,0,.14), 0 .3rem .3rem -.2rem rgba(0,0,0,.12), 0 .1rem .8rem 0 rgba(0,0,0,.2);
  cursor: pointer;
  padding: $spacing-sm 0;
}

.Toolbar__viewSwitcherItem {
  align-items: center;
  display: flex;
  padding: $spacing-sm 0;
}

.Toolbar__viewSwitcherItem--active {
  background-color: $color-indigo-20;
}

.Toolbar__viewSwitcherIcon {
  display: inline-flex;
  padding-left: $spacing-md;
  padding-right: $spacing-sm;
}

.Toolbar__viewSwitcherIcon--active {
  color: $color-indigo-100;
  display: inline-flex;
}

.Toolbar__viewSwitcherIcon--inactive {
  color: $color-gray-70;
  display: inline-flex;
}

.Toolbar__viewSwitcherLabel {
  padding-left: $spacing-sm;
  padding-right: $spacing-md;
}

.Toolbar__savedFilter {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
}

.Toolbar__savedFilterName {
  margin-right: $spacing-sm;
  white-space: nowrap;
}

// export to use in JS files
:export {
  searchInputCollapsedWidth: $search-input-collapsed-width;
  searchInputExpandedWidth: $search-input-expanded-width;
}
