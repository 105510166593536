@import '../Loader/helpers.scss';

@keyframes loadingAnimation {
  50% {
    opacity: .15;
  }
}

.LoaderIndicator {
  font-size: 0;
  height: 100%;
  max-height: $Loader-rec-height;
  text-align: center;
}

.LoaderIndicator--sm {
  height: $Loader-rec-height * $Loader-small-modifier;
  max-height: $Loader-rec-height * $Loader-small-modifier;
}

.LoaderIndicator__rect {
  animation: loadingAnimation $Loader-animation-duration infinite ease-in-out;
  background-color: $color-orange-100;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: $Loader-rec-width;
}

.LoaderIndicator--sm > .LoaderIndicator__rect {
  width: $Loader-rec-width * $Loader-small-modifier;
}

.LoaderIndicator__rect + .LoaderIndicator__rect {
  margin-left: $Loader-rec-spacing;
}

.LoaderIndicator--sm > .LoaderIndicator__rect + .LoaderIndicator__rect {
  margin-left: $Loader-rec-spacing * $Loader-small-modifier;
}

.LoaderIndicator__rect--1 {
  animation-delay: $Loader-animation-duration / 6;
  @include Loader-rec-small();
}

.LoaderIndicator__rect--2 {
  animation-delay: $Loader-animation-duration / 4;
}

.LoaderIndicator__rect--3 {
  animation-delay: calc($Loader-animation-duration / 2);
  @include Loader-rec-small();
}
