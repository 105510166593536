@import './helpers.scss';

.FlashAlert {
  @include flashAlert-root();
  left: 50%;
  position: fixed;
  top: 15rem;
  @include bp-max($flashAlert-breakpoint) {
    margin-left: -(calc($flashAlert-width / 2));
  }
  @include bp-min($flashAlert-breakpoint) {
    margin-left: (
      -(calc($flashAlert-width / 2)) + calc($page-sidebar-and-drawer-width / 2)
    );
  }
}

.FlashAlert--danger {
  @include flashAlert-danger();
}

.FlashAlert--info {
  @include flashAlert-info();
}

.FlashAlert--success {
  @include flashAlert-success();
}

.FlashAlert--warning {
  @include flashAlert-warning();
}

.FlashAlert__icon {
  $widthHeight: 6.2rem;
  border-radius: 50%;
  color: $color-white;
  float: left;
  height: $widthHeight;
  line-height: 1;
  margin-right: $flashAlert-padding;
  position: relative;
  text-align: center;
  width: $widthHeight;
}

.FlashAlert__icon__elem {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.FlashAlert__icon__elem__inner {
  display: block; // needs block for animation
  font-size: $flashAlert-icon-font-size;
}

.FlashAlert__icon__elem__inner--spin {
  @include flashAlert-spin();
}

.FlashAlert__icon__elem__inner::before {
  display: block;
}

.FlashAlert__icon__elem__inner.icon-refresh-2 {
  font-size: $flashAlert-icon-font-size + 1rem;
}

.FlashAlert__icon__elem__inner.icon-glyphish-refresh {
  font-size: $flashAlert-icon-font-size + 2rem;
}

.FlashAlert__icon--danger {
  @include flashAlert-icon-danger();
}

.FlashAlert__icon--info {
  @include flashAlert-icon-info();
}

.FlashAlert__icon--success {
  @include flashAlert-icon-success();
}

.FlashAlert__icon--warning {
  @include flashAlert-icon-warning();
}

.FlashAlert__bd {
  overflow: hidden;
}

.FlashAlert__hdg {
  @include flashAlert-heading();
}
