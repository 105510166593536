@import './_DatePicker-helpers';

:global {
  @import '~react-datepicker/dist/react-datepicker';
}

:global {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    min-width: 32rem;
    z-index: 9999;
  }
}

.DatePicker {
  :global(.react-datepicker__input-container) {
    display: flex;
    flex: 1 1 0%;
  }
  flex-direction: row;
  width: 100%;

  > div:first-child {
    flex-grow: 1; // react-datepicker doesn't enable changing the markup so we have to use this gross selector
  }
}

.DatePicker__input {
  @include Field-input-base();
}

.DatePicker__input--hasAppend {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.DatePicker__input--error {
  @include Field-input-error();
}

.DatePicker__downDown {
  border: $FieldInput-border;
  border-radius: $FieldInput-border-radius;
  box-shadow: $Field-menu-shadow;

  &:global(.react-datepicker) {
    font-size: 1.1rem;
  }

  :global {
    .react-datepicker__input-container {
      display: block;
    }

    .react-datepicker-wrapper {
      display: block;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: 1.3rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      line-height: 2.4rem;
      margin: $spacing-xs;
      width: 2.4rem;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: $color-gray-20;
      border-bottom-color: map-get($FieldInput-border-color, default);
    }

    .react-datepicker__navigation {
      border-width: .9rem;
      color: inherit;
      font-size: 2rem;
      line-height: 1;
      z-index: map-get($z-index, date-picker);
    }

    // previous / default
    .react-datepicker__navigation::before {
      content: "\00AB";
      font-family: Arial, Helvetica, sans-serif; // needs to be arial to get character right
      left: 0;
      position: absolute;
      top: -.5rem;
    }

    .react-datepicker__navigation--next::before {
      content: "\00BB";
      left: auto;
      right: 0;
    }

    .react-datepicker__day:hover {
      background-color: $color-indigo-20;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--keyboard-selected:hover {
      background-color: map-get($FieldInput-border-color, focus);
    }
  }
}

:global(.react-datepicker__tether-element-attached-top) > .DatePicker__downDown {
  margin-top: -$spacing-sm + $Field-menu-vertical-offset;
}

:global(.react-datepicker__tether-element-attached-bottom) > .DatePicker__downDown {
  margin-bottom: $spacing-xs + $Field-menu-vertical-offset;
}
