@import '../../../../../scss/helpers/_all';
// @import '~react-quill/dist/quill.core';
// @import '~react-quill/dist/quill.snow';

// overrides
$quill-blue: $color-indigo-80;
$quill-charcoal: $color-gray-100;
$quill-grey: $color-gray-50;

@mixin TextEdit-del-styles($isAuthor:false) {
  background-color: $color-gray-100!important; // stylelint-disable-line declaration-no-important
  text-decoration: none!important; // stylelint-disable-line declaration-no-important
  @if $isAuthor {
    color: $color-white!important; // stylelint-disable-line declaration-no-important
  } @else {
    color: $color-gray-100!important; // stylelint-disable-line declaration-no-important
  }
}
