@import './Modal-helpers';

.Modal__close {
  position: absolute;
  right: $spacing-md;
  top: .8rem;
}

.Modal__body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  padding: $spacing-md;
  position: relative;
  @include u-webkitScroll();
}

.Modal__body--overflow {
  overflow: auto;
}

.Modal__body--gray10 {
  background: $color-gray-10;
}

.Modal__body--gray30 {
  background: $color-gray-30;
}


.Modal__overlay {
  pointer-events: none;
}

.Modal__dialog {
  display: flex;
  flex-direction: column;
  margin: $spacing-sm;
  pointer-events: auto;
  position: relative;
  width: auto;
}

@include bp-min($screen-size-sm) {
  .Modal__dialog {
    margin: map-get($modal-dialog-vertical-margin, sm) auto;
    max-height: calc(100vh - #{map-get($modal-dialog-vertical-margin, sm)*2});
    width: 60rem;
  }

  .Modal__dialog--centered {
    left: 50%;
    margin: 0;
    top: 50%;
    -ms-transform: translate(-50%, -50%); // stylelint-disable-line property-no-vendor-prefix
    transform: translate(-50%, -50%);
  }

  .Modal__dialog--small {
    width: 40rem;
  }
}

@include bp-min($screen-size-md) {
  .Modal__dialog--large {
    width: 90rem;
  }
}

@include bp-min($screen-size-md) {
  .Modal__dialog--extraLarge {
    width: 120rem;
  }
}

@include bp-max(map-get($screen-sizes, sm)) {
  .Modal__dialog {
    margin-top: map-get($modal-dialog-vertical-margin, xs);
    max-height: calc(100vh - #{map-get($modal-dialog-vertical-margin, xs)*2});
  }
}

.Modal__header {
  background-color: $color-white;
  padding: $spacing-md $spacing-md 0 $spacing-md;
}

.Modal__header--BorderBottom {
  border-bottom: $modal-border solid $color-gray-50;
  padding-bottom: $spacing-md;
}

.Modal__header--BorderRadius {
  border-top-left-radius: $fn-border-radius-sm;
  border-top-right-radius: $fn-border-radius-sm;
}

.Modal__content {
  background-clip: padding-box;
  background-color: $color-white;
  border: $modal-border solid rgba($color-gray-100, .2);
  box-shadow: 0 .8rem 1.2rem 0 rgba($color-gray-100, .3);
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: 0;
  position: relative;
}

.Modal__content--overflow {
  overflow: hidden;
}

.Modal__content--BorderRadius {
  border-radius: $fn-border-radius-sm;
}

.Modal__primary {
  background-color: $color-orange-100;
  color: $color-white;
}

.Modal__danger {
  background-color: $color-red-100;
  color: $color-white;
}

.Modal__info {
  background-color: $color-blue-100;
  color: $color-white;
}

.Modal__warning {
  background-color: $color-yellow-80;
  color: $color-white;
}

.Modal__success {
  background-color: $color-green-100;
  color: $color-white;
}

.Modal__footer {
  border-top: $modal-border solid $color-gray-50;
  padding: $spacing-md;
  text-align: right;
}

.Modal__footer--hasActionBar {
  padding: 0;
  text-align: left;
}
