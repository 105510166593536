@import "./_PinnedMenu-helpers.scss";

.PinnedMenu__item {
  color: $color-gray-100;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  line-height: 2.1rem;
  overflow: hidden;
  padding: $PinnedMenu-itemPadding-v $PinnedMenu-itemPadding-right $PinnedMenu-itemPadding-v $PinnedMenu-itemPadding-h;
  position: relative;
  text-overflow: ellipsis;
  transition: background-color .25s ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: $color-gray-10;
    color: $color-gray-100;
  }
}

.PinnedMenu__item--active {
  &,
  &:hover {
    background-color: $color-blue-20;
  }
}

.PinnedMenu__selectedIcon {
  position: absolute;
  right: $PinnedMenu-selectedIcon-rightSpace;
}

.PinnedMenu__divider {
  margin: 0 $PinnedMenu-dividerMargin-h;
}

.PinnedMenu__content {
  background-color: $color-white;
  border: $border-width-sm solid $color-gray-50;
  border-radius: .3rem;
  box-shadow: 0 .6rem 1.2rem 0 rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  max-width: 33.2rem;
  min-width: 15rem;
  overflow: auto;
  padding: $spacing-xs 0 $spacing-sm;
  position: fixed;
}
