@import './_TinyBand-helpers';

.TinyBand {
  $root: &;
  @include notificationBand-type-to-background-and-color();
  border-bottom: $border-width-sm solid $notificationBand-divider-color;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  padding: $spacing-xs $spacing-md;
  text-align: center;
  text-transform: uppercase;

  &--status {
    border-bottom: none;

    &#{$root} {
      @include notificationBand-type-to-background-and-color('status');
    }
  }
}

.TinyBand--clickable {
  cursor: pointer;
}
