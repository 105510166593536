@import './shared';

// Screen-sizes import and assignment
// @import './_shared-config'; // file generated from /lib/shared-config.json via gulp
$screen-sizes: (
    xs: $screen-size-xs,
    sm: $screen-size-sm,
    md: $screen-size-md,
    lg: $screen-size-lg,
    xl: $screen-size-xl,
);
$font-size-root-from-config: $root-font-size;

// ---------------------------------------------------------------------
// Global Vars
//----------------------------------------------------------------------
$fonts: (
  opensans: #{Open Sans, helvetica, sans-serif},
  opensanssemibold: #{Open Sans SemiBold, helvetica, sans-serif},
  opensanslight: #{Open Sans Light},
  icon: 'fnicons'
);
$base-font-size: $base-font-size;
$base-font-family: map-get($fonts, opensans);

// ---------------------------------------------------------------------
// Colors
//----------------------------------------------------------------------

// *** $colorPalette imported from shared-config.json ***

$base-color: $color-gray-100;

// ---------------------------------------------------------------------
// Border Radius
//----------------------------------------------------------------------

// $border-radii imported from shared-config.json

// colors in $oldColorPalette are depracated, but can
// be moved into $colorPalette. $colorPalette is
// documented in component library
$oldColorPalette: (
  black: (
    primary: #000000
  ),
  blue: (
    primary: #a7c4e7,
    dark: #8aa5ff
  ),
  green: (
    primary: #62c3ae
  ),
  grey: (
    primary: #555555,
    dark: #343434,
    light: #bfbfbf
  ),
  orange: (
    primary: #f06623,
    dark: #cb4727
  ),
  red: (
    primary: #ee4856
  ),
  white: (
    primary: #ffffff,
    dark: #ffffff
  ),
  yellow: (
    primary: #f5ae4e,
    dark: #d37228
  )
);

// ---------------------------------------------------------------------
// Z-index
//----------------------------------------------------------------------
$z-index: (
  sidebar-flyout: -1,
  navigation-search-overlay: 1,
  headerSearch-type-indicator: 3,
  distance-graph: 5,
  date-picker: 5,
  status-bar-dot: 8,
  status-bar-message: 9,
  flash-alert: 10,
  react-datalist: 999,
  page-sidebar: 1001,
  page-sidebar-footer: 1001,
  page-sidebar-body: 1002,
  loader: 1002,
  page-header: 1003,
  navigation-search: 1003,
  notication-band: 1004,
  file-processing: 1005,
  react-modal-panel: 1006,
  save-bar: 1007,
  screen-notification: 1007,
  tether-component: 1008,
  react-modal: 1009,
  component-above-modal: 1010
);

// ---------------------------------------------------------------------
// Misc
//----------------------------------------------------------------------
$page-sidebar-and-drawer-width: 23rem;

// ---------------------------------------------------------------------
// Header
//----------------------------------------------------------------------
$page-header-height: 7.5rem;
// $page-v2-header-height imported from shared-config.json
// $page-v2-subnavigation-height imported from shared-config.json
// $page-v2-staff-nav-height imported from shared-config.json
$masthead-size-medium: 66.25em;

// Icon content import
@import '~@fieldnation/font/dist/icon-content.scss';
$mobile-nav-breakpoint-max: map-get($screen-sizes, lg);
