@import './_NotificationBand-helpers';

.Action {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.Action:hover {
  text-decoration: none;
}

.Action + .Action {
  margin-left: $spacing-xl;
}
