@import '../Grid/helpers.scss';

.GridColumn {
  $padding: calc($grid-gutter / 2);
  min-height: 1px; // not sure why we need this
  padding-left: $padding;
  padding-right: $padding;
  position: relative; // brought over from bootstrap classes. Only needed for push and pulls but leaving it here in case things where built around this being here
  @each $label, $map in $screen-sizes {
    @include bp-min($map) {
      @for $i from 1 through $grid-numberOfparts {
        $width: percentage(calc($i/$grid-numberOfparts));

        &--#{$label}-#{$i} {
          flex: 0 0 $width;
          max-width: $width;
          width: $width;
        }

        &--#{$label}-push-#{$i} {
          left: $width;
        }

        &--#{$label}-pull-#{$i} {
          right: $width;
        }
      }
    }
  }
}

.GridColumn--divided {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter + $grid-border-width;
  position: relative;

  &::before {
    border-left: $grid-border-width solid $grid-border-color;
    bottom: 0;
    content: ' ';
    left: -$grid-border-width;
    position: absolute;
    top: 0;
  }

  &::after {
    border-top: $grid-border-width solid $grid-border-color;
    content: ' ';
    height: 0;
    left: $grid-gutter;
    position: absolute;
    right: $grid-gutter;
    top: 0;
  }
}

.GridColumn--vSpace {
  margin-top: $grid-gutter;

  &::after {
    top: -(calc($grid-gutter / 2));
  }

  &.GridColumn--divided {
    margin-top: $grid-gutter * 2 + $grid-border-width;

    &::after {
      top: -($grid-gutter + $grid-border-width);
    }
  }
}
