@import './_Status-helpers';

@keyframes StatusLoading {
  from {
    opacity: 0;
    top: 100%;
  }

  50% {
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    top: -100%;
  }
}

.Status {
  @include status-baseStyle();
}

.Status--sm {
  font-size: $text-font-size-xs;
  line-height: 1.3;
  padding: $spacing-xs $spacing-sm;
}

.Status--inlineFlex {
  display: inline-flex;
}

.Status--isLoading .Status__inner::before {
  animation: StatusLoading 1.5s infinite;
  background-color: rgba($color-white,.5);
  content: ' ';
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.Status--success {
  background-color: $color-green-80;
}

.Status--info {
  background-color: $color-blue-80;
}

.Status--danger {
  background-color: $color-red-80;
}

.Status--active {
  background-color: $color-gray-100;
}

.Status--warning {
  background-color: $color-yellow-80;
}

.Status--light {
  background-color: $color-gray-40;
}

.Status--progress {
  background-color: $color-indigo-60;
}
