@import '../../scss/helpers/_all';

.Message__icon {
  border-radius: 10rem;
  display: inline-block;
  padding-bottom: $spacing-lg;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-top: $spacing-xl;
}

.Message__heading {
  padding: $spacing-md 0;
}

.Message__container {
  justify-content: center;
  padding: $spacing-md $spacing-md 0 $spacing-md;
  text-align: center;
}

.Message__danger {
  background-color: $color-red-20;
  color: $color-red-80;
}

.Message__info {
  background-color: $color-indigo-20;
  color: $color-indigo-80;
}

.Message__warning {
  background-color: $color-orange-20;
  color: $color-orange-60;
}

.Message__success {
  background-color: $color-green-20;
  color: $color-green-60;
}

@include bp-max(map-get($screen-sizes, sm)) {
  .Message__container {
    padding: $spacing-sm $spacing-sm 0 $spacing-sm;
  }

  .Message__icon {
    padding-bottom: $spacing-sm;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    padding-top: $spacing-md;
  }
}
