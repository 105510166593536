@import '../../../scss/helpers/_all';

.DateTimePicker {
  display: flex;
  flex-wrap: nowrap;
}

.DatePicker,
.TimePicker {
  width: 50%;
}

.DatePicker {
  margin-right: $spacing-sm;
}
