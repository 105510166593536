@import './_box-helpers';

.box {
  background-color: $color-gray-30;
  border-radius: .4rem;
  padding: $box-padding;
}

.box__hdr {
  border-bottom: $border-width-sm solid $box-border-color;
  margin-bottom: $box-padding;
  padding-bottom: $box-padding;
}

.box__hdg {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
}

.box__grid {
  display: flex;
}

.box__grid--minWidthNotMet {
  flex-direction: column;

  .box__grid__column + .box__grid__column {
    border-top: $border-width-sm solid $box-border-color;
    margin-top: $box-padding;
    padding-top: $box-padding;
  }
}

.box__grid--minWidthMet {
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -$box-padding;
  margin-right: -$box-padding;

  .box__grid__column {
    padding-left: $box-padding;
    padding-right: $box-padding;
  }

  .box__grid__column + .box__grid__column {
    border-left: $border-width-sm solid $box-border-color;
  }

  .box__grid__column--6of12 {
    width: percentage(6/12);
  }

  .box__grid__column--4of12 {
    width: percentage(4/12);
  }
}
