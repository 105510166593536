@import './_NotificationExpander-helpers';

.NotificationExpander__banner {
  line-height: 1;
}

.NotificationExpander__icon {
  @include u-flushIcon();
}

.NotificationExpander__icon::before {
  @include u-icon();
  content: $icon-chevron-down;
}

.NotificationExpander__icon--isExpanded::before {
  content: $icon-chevron-up;
}
