@import './helpers.scss';
@import './FilterSearch';

.FilterEditPanel__filterValues {
  margin-top: $spacing-sm;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  position: relative;
}

.FilterEditPanel__textInput {
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
}

.FilterEditPanel__dateInput {
  padding-left: $spacing-md;
  padding-right: $spacing-md;
}

.FilterEditPanel__filterEditActionsButton {
  float: right;
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
}

.FilterEditPanel__filterEditActions {
  background-color: $color-white;
  border-bottom: $border-width-sm solid $color-gray-50;
  border-top: $border-width-sm solid $color-gray-50;
  bottom: 0;
  overflow: auto;
  position: sticky;
  z-index: 2;
}

.FilterEditPanel__filterEditCount {
  float: left;
  margin-left: $spacing-md;
  margin-top: $spacing-sm;
}

.FilterEditPanel__filtersLabel {
  margin-bottom: $spacing-sm;
}

.FilterEditPanel__dateSeparator {
  &:last-child {
    margin-bottom: $spacing-sm;
  }

  &:first-child {
    margin-top: $spacing-sm;
  }

  & + & {
    margin-top: $spacing-md;
  }
}

.FilterEditPanel__slider {
  & + & {
    margin-top: $spacing-md;
  }

  &:last-child {
    margin-bottom: $spacing-sm;
  }
}

.FilterEditPanel__sliderValue {
  margin-top: $spacing-sm;
}

.FilterEditPanel__filtersEmpty {
  @include Toolbar-filterEmpty();
}

.FilterEditPanel__filterOptions {
  max-height: 28rem;
  overflow-y: auto;
  position: relative;
}

.FilterEditPanel__filterSection {
  @include Toolbar-filterSection();
}

.FilterEditPanel__filterOption {
  @include Toolbar-filterOption();
}

.FilterEditPanel__typeaheadChips {
  display: flex;
  flex-wrap: wrap;
  margin: 0 $spacing-sm $spacing-sm 0;
}

.FilterEditPanel__typeaheadChip {
  margin: 0 0 $spacing-sm $spacing-sm;
}

.FilterEditPanel__filterPanel {
  @include Toolbar-filterPanel();
  overflow-y: hidden;
}

.FilterEditPanel__singleFilterPanel {
  @include Toolbar-filterPanel();
  overflow-y: hidden;
  transform: translateX(-15.9rem);
}

.FilterEditPanel__filterPanel--date {
  overflow: visible;
}

.FilterEditPanel__filterSearch {
  @include Toolbar-filterSearch();
  position: sticky;
}

.FilterEditPanel__booleanMultiSelect {
  padding: 0 $spacing-md 0 $spacing-md;

  &--container {
    display: flex;
    flex-direction: column;
  }

  &--top {
    @extend .FilterEditPanel__booleanMultiSelect;
    background-color: $color-gray-20;
    border-bottom: 1px solid $color-gray-40;
    padding: $spacing-md $spacing-md $spacing-sm $spacing-md;
  }

  &--bottom {
    @extend .FilterEditPanel__booleanMultiSelect;
    margin-top: $spacing-md;
    padding-bottom: $spacing-sm;
  }
}

.FilterEditPanel__booleanNumber--bottom {
  margin-top: $spacing-sm;
  padding-bottom: $spacing-sm;
}

.FilterEditPanel__fixedTop {
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 100;
}

.FilterEditPanel__filtersOptionFetchingError {
  height: 7rem;
  margin-left: $spacing-md;
  margin-top: $spacing-md;
}

.FilterEditPanel__filtersOptionLoading {
  height: 8rem;
}

.FilterEditPanel__booleanNumberRange--top {
  padding: $spacing-sm $spacing-md;
}

.FilterEditPanel__filtersLabel--bold {
  color: $color-gray-80;
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: $spacing-sm;
}

.FilterEditPanel__filtersLabel--info {
  color: $color-gray-80;
  font-size: 1.2rem;
  margin-bottom: $spacing-md;
}

.FilterEditPanel__numericFiltersLabel--icon {
  @extend .FilterEditPanel__filtersLabel--bold;
  margin-bottom: 0;
}

.FilterEditPanel__location--container {
  padding: $spacing-sm $spacing-md;
}

.FilterEditPanel__location--section {
  margin-bottom: $spacing-lg - $spacing-2-xs;
}

.FilterEditPanel__numericRangeLabelOptional {
  color: $color-gray-80;
  font-style: italic;
  white-space: nowrap;
}
