@import './helpers.scss';

.TabList {
  background-color: $color-gray-10;
  border-bottom: $border-width-sm solid $color-gray-50;
  display: flex;
}

.TabList__item {
  @include u-resetButton();
  color: $color-gray-100;
  flex-grow: 1;
  overflow: hidden;
  padding: $spacing-md 0;
  position: relative;

  &:focus {
    outline: 0;
  }

  &::after {
    border-bottom: $TabList-border-width solid transparent;
    bottom: -$TabList-border-width;
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    transition: all .2s ease-in-out;
  }

  &:hover::after {
    border-bottom-color: $color-gray-50;
    bottom: 0;
  }
}

.TabList__item--active.TabList__item--active {
  background: $color-white;
  color: $color-gray-100;
  font-weight: 600;

  &::after {
    border-bottom-color: $color-indigo-60;
    bottom: 0;
  }
}

.TabList_Count {
  margin-left: $spacing-sm;
}
