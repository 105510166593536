@import '../../../../../scss/helpers/_all';
$switch-border-color: white;
$switch-border-width: .1rem;

$switch-height: 1.6rem;
$switch-width: 3.2rem;
$switch-border-radius: 1.2rem;
$switch-font-size: 1.2rem;

$switch-yesno-h-offset: .8rem;

$switch-color-on: $color-white;
$switch-color-off: $color-gray-100;

$switch-border-color: (
    default: $color-gray-60,
    green: $color-green-100,
    red: $color-red-80,
    indigo: $color-indigo-60,
    disabled: $color-gray-50
);

$switch-background-color: (
    default: $color-white,
    green: $color-green-100,
    red: $color-red-100,
    grey: $color-gray-40,
    indigo: $color-indigo-60,
);

$switch-handle-background: $color-white;
$switch-handle-shadow: (
   default: (0 .1rem .1rem 0 rgba(0, 0, 0, .14), 0 .2rem .1rem -.1rem rgba(0, 0, 0, .12), 0 .1rem .3rem 0 rgba(0, 0, 0, .2)),
   disabled: 0 0 .1rem 0 rgba(0, 0, 0, .3)
);

$switch-animation-duration: .24s;
$switch-animation-type: ease;

@mixin Switch-yesNo($bg: map-get($switch-background-color, default)) {
  background-color: $bg;
  border: $switch-border-width solid map-get($switch-border-color, default);
  border-radius: $switch-border-radius;
  bottom: 0;
  box-shadow: map-get($switch-handle-shadow, default);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all $switch-animation-duration $switch-animation-type;
}

@mixin Switch-handle($height: $switch-height) {
  $handleWidthHeight: $height - ($switch-border-width * 2);

  background-color: $switch-handle-background;
  border-radius: 100%;
  box-shadow: map-get($switch-handle-shadow, default);
  content: ' ';
  display: block;
  height: $handleWidthHeight;
  left: $switch-border-width;
  position: absolute;
  top: $switch-border-width;
  transition: left $switch-animation-duration $switch-animation-type;
  width: $handleWidthHeight;
}
