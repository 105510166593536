@import '../../scss/helpers/_all.scss';

/******************************
* Gauge Header
******************************/

.GaugeBar__header_container {
  color: $color-gray-90;
  display: flex;
}

.GaugeBar__header_start_label {
  order: 1;
  width: 60%;
}

.GaugeBar__header_end_label {
  display: flex;
  justify-content: flex-end;
  order: 2;
  padding-right: $spacing-sm;
  width: 15%;
}

.GaugeBar__header_overage_label {
  display: flex;
  justify-content: flex-end;
  order: 3;
  width: 25%;
}

/******************************
* Gauge Body
******************************/

.GaugeBar__body_foundation {
  background-color: $color-gray-50;
  display: flex;
  height: 3rem;
  margin: $spacing-2-xs 0 0 0;
  width: 100%;
}

.GaugeBar__body_main_base {
  &-gray {
    align-items: center;
    background-color: $color-gray-30;
    display: flex;
    flex-grow: 7.5;
    margin-right: $spacing-2-xs;
    position: relative;
  }

  &-gray::before {
    border-left: $fn-border-radius-sm solid transparent;
    border-top: $fn-border-radius-sm solid $color-gray-50;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
  }

  &-white {
    align-items: center;
    background-color: $color-white;
    display: flex;
    flex-grow: 7.5;
    margin-right: $spacing-2-xs;
    position: relative;
  }

  &-white::before {
    border-left: $fn-border-radius-sm solid transparent;
    border-top: $fn-border-radius-sm solid $color-gray-50;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
  }
}

.GaugeBar__body_overage_base {
  &-gray {
    align-items: center;
    background-color: $color-gray-30;
    display: flex;
    flex-grow: 2.5;
    position: relative;
  }

  &-gray::before {
    border-right: $fn-border-radius-sm solid transparent;
    border-top: $fn-border-radius-sm solid $color-gray-50;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  &-white {
    align-items: center;
    background-color: $color-white;
    display: flex;
    flex-grow: 2.5;
    position: relative;
  }

  &-white::before {
    border-right: $fn-border-radius-sm solid transparent;
    border-top: $fn-border-radius-sm solid $color-gray-50;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }
}

.GaugeBar__body_container {
  background-color: $color-gray-40;
  border-bottom-right-radius: $fn-border-radius-md;
  border-top-right-radius: $fn-border-radius-md;
  height: 1.2rem;
  position: relative;
  width: 100%;
}

.GaugeBar__body_base {
  background-color: $color-gray-50;
  border-bottom-left-radius: $fn-border-radius-md;
  border-top-left-radius: $fn-border-radius-md;
  height: 1.2rem;
  position: relative;
  width: 100%;
}

.GaugeBar__body_overlay {
  background-color: $color-gray-90;
  border-bottom-left-radius: $fn-border-radius-md;
  border-top-left-radius: $fn-border-radius-md;
  height: 1.2rem;
  position: absolute;
}

/******************************
* Gauge Footer
******************************/

.GaugeBar__footer_container {
  color: $color-gray-90;
  display: flex;
  font-size: $text-font-size-md;
}

.GaugeBar__footer_start_label {
  order: 1;
  width: 60%;
}

.GaugeBar__footer_end_label {
  display: flex;
  justify-content: flex-end;
  order: 2;
  padding-right: $spacing-sm;
  width: 15%;
}

.GaugeBar__footer_overage_label {
  display: flex;
  justify-content: flex-end;
  order: 3;
  width: 25%;
}
