@import '../HelpText/_HelpText-helpers';

.HelpTextIcon {
  display: block;
}

.HelpTextIcon--error {
  $fill: map-get($HelpText-color, error);
  color: $fill;

  > .HelpTextIcon__fill {
    fill: $fill;
  }
}

.HelpTextIcon--success {
  $fill: map-get($HelpText-color, success);
  color: $fill;

  > .HelpTextIcon__fill {
    fill: $fill;
  }
}
