@import '../../../../../scss/helpers/_all';

.BackText__button {
  display: flex;
}

.BackText__icon {
  @include u-icon();
  content: $icon-back-arrow-reversed;
  display: block;
}
