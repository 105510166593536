@import '../_Field-helpers';

.CreditCardInput {
  position: relative;
}

.CreditCardInput__icon {
  height: 2.5rem;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
}
