@import './_FixedPopover-helpers.scss';

.FixedPopover__Trigger {
  display: inline-block;
}

.FixedPopover__Trigger__pointer {
  border-bottom: .6rem solid $color-white;
  border-left: .6rem solid transparent;
  border-right: .6rem solid transparent;
  height: 0;
  position: fixed;
  transform: translate(-.6rem,-.6rem);
  width: 0;
}

.FixedPopover__Content {
  overflow: visible;
  position: fixed;
}

// Styles for animating FixedPopover
:global(.FixedPopover-animation-appear),
:global(.FixedPopover-animation-enter),
:global(.FixedPopover-animation-appear.FixedPopover-animation-appear-active),
:global(.FixedPopover-animation-enter.FixedPopover-animation-enter-active),
:global(.FixedPopover-animation-leave),
:global(.FixedPopover-animation-leave.FixedPopover-animation-leave-active) {
  &.FixedPopover__Content {
    overflow: hidden;
  }
}

:global(.FixedPopover-animation-appear),
:global(.FixedPopover-animation-enter) {
  .FixedPopover__ContentWrapper {
    @include Navigation-animate-enter();
  }

  .FixedPopover__Trigger__pointer,
  &.FixedPopover__Content { // need to control opacity to sync the visibility of box-shadow with the dropdown menu animation
    opacity: 0;
  }
}

:global(.FixedPopover-animation-appear.FixedPopover-animation-appear-active),
:global(.FixedPopover-animation-enter.FixedPopover-animation-enter-active) {
  .FixedPopover__ContentWrapper {
    @include Navigation-animate-leave();
    transition: $Navigation-transition;
  }

  .FixedPopover__Trigger__pointer,
  &.FixedPopover__Content {
    opacity: 1;
    transition: $Navigation-transition;
  }
}

:global(.FixedPopover-animation-leave) {
  .FixedPopover__ContentWrapper {
    @include Navigation-animate-leave();
  }

  .FixedPopover__Trigger__pointer,
  &.FixedPopover__Content {
    opacity: 1;
  }
}

:global(.FixedPopover-animation-leave.FixedPopover-animation-leave-active) {
  .FixedPopover__ContentWrapper {
    @include Navigation-animate-enter();
    transition: $Navigation-transition;
  }

  .FixedPopover__Trigger__pointer,
  &.FixedPopover__Content {
    opacity: 0;
    transition: $Navigation-transition;
  }
}
