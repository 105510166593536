@import '../../../../../scss/helpers/_all';

@include bp-min(36.25rem) {
  .ButtonBarWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 25rem;
  }
}
