@import '../MultiButton/helpers.scss';

.MultiButton {
  overflow: hidden;
  width: 100%;
}

.MultiButton--inline {
  display: inline-block;
}

.MultiButton__hiddenElement {
  left: 0;
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
