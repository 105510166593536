@import '../../scss/helpers/all';

// needed to up the specificity so this over rides
.TimeGridHeader.TimeGridHeader.TimeGridHeader {
  padding-top: $spacing-sm;
  text-align: left;
}

.TimeGridHeader__mutedText {
  color: $color-gray-80;
}
