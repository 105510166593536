
@import './_ActionableRow-helpers';

.ActionableRow__mainAndBody {
  display: flex;
  flex-direction: flex-row;
}

.ActionableRow__main {
  padding-right: map-get($ActionableRow-main-padding, right);
}

.ActionableRow__body {
  flex-grow: 2;
}

.ActionableRow__actions {
  white-space: nowrap;
}
