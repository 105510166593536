@import '../../scss/helpers/_all';

$right-width: 6.4rem;
$badge-width: 2.2rem;
$badge-font-size: 1.2rem;
$shadow-rgba: rgba(0, 0, 0, .03);

@mixin GroupCard-right {
  align-self: stretch;
  background-color: $shadow-rgba;
  min-width: $right-width;
  z-index: 2;
}
