@import './_Button-helpers';

.Button {
  border: $border-width-sm solid transparent;
  border-radius: $btn-radius;
  cursor: pointer;
  display: inline-block;
  font-size: $btn-font-size;
  line-height: $text-line-height-xs;
  padding: $btn-padding-v $btn-padding-h;
  text-align: center;
  transition-duration: .25s;
  transition-property: background-color, border-color, color; // can't animate all properties (we don't want to animate width)
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &--gray10 {
    background-color: $color-gray-10;
    border-color: $color-gray-10;
    color: $color-gray-100;
    @include btn-hover-focus(true) {
      background-color: $color-gray-30;
      border-color: $color-gray-30;
      outline-color: $color-gray-30;
    }

    &:active,
    &.Button--isActive {
      &:not([disabled]) {
        background-color: $color-gray-50;
        border-color: $color-gray-50;
        outline-color: $color-gray-50;
      }
    }

    &.Button--block {
      text-align: left;
    }
  }

  &--gray {
    border-color: $color-gray-60;
    color: $color-gray-80;

    &.Button--isActive {
      &:not([disabled]) {
        background-color: $color-gray-50;
        border-color: $color-gray-60;
        color: $color-gray-100;
      }
    }
    @include btn-hover-focus(true) {
      background-color: $color-gray-10;
      border-color: $color-gray-60;
      color: $color-gray-80;
    }
  }

  &--primary-reverse {
    background-color: transparent;
    border-color: transparent;
    color: $color-indigo-60;
    margin: $text-letter-spacing-3 $text-letter-spacing-3 $text-letter-spacing-3 $spacing-md;
    padding: $text-letter-spacing-3;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $color-indigo-100;
      outline: $text-letter-spacing-3;
      text-decoration: none;
    }
  }

  &--gray10-white-bg {
    background-color: $color-gray-10;
    border-color: $color-gray-10;
    color: $color-gray-100;
    @include btn-hover-focus(true) {
      background-color: $color-white;
      border-color: $color-white;
      outline-color: $color-white;
    }
  }
}

.Button--hasFixedHeight {
  height: 4rem;
  padding-bottom: 0;
  padding-top: 0;
}

.Button--block {
  display: block;
  width: 100%;
}

.Button--primary {
  background-color: $color-indigo-60;
  border-color: $color-indigo-60;
  color: $color-white;
  @include btn-hover-focus(true) {
    background-color: $color-indigo-80;
    border-color: $color-indigo-80;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $color-indigo-100;
      border-color: $color-indigo-100;
    }
  }
}

.Button--danger {
  background-color: $color-red-80;
  border-color: $color-red-80;
  color: $color-white;
  @include btn-hover-focus(true) {
    background-color: $color-red-60;
    border-color: $color-red-60;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $color-red-100;
      border-color: $color-red-100;
    }
  }
}

// have disabled primary and secondary button styling the same
.Button--secondary {
  @include btn-secondary-base-styles();
  @include btn-hover-focus(true) {
    background-color: $color-indigo-80;
    border-color: $color-indigo-80;
    color: $color-white;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $color-indigo-100;
      border-color: $color-indigo-100;
      color: $color-white;
    }
  }
}

// fade disabled buttons
.Button:disabled {
  opacity: $btn-disabledOpacity;
}

.Button--sm {
  border-radius: .2rem;
  padding: $spacing-xs $spacing-sm;
}

.Button--xs {
  border-radius: .2rem;
  font-size: 1.2rem;
  padding: 0;
}

.Button--link,
.Button--text {
  background-color: transparent;
  color: $color-indigo-80;
  @include btn-hover-focus(true) {
    background-color: $color-indigo-20;
    border-color: $color-indigo-20;
  }
}

.Button--rightBorderRadiusDisabled {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.Button--rightBorderDisabled {
  border-right: 0;
}

.Button--leftBorderRadiusDisabled {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.Button__icon {
  padding-right: $spacing-xs;
  position: relative;
  top: -.2em;
}

.Button__Icon {
  display: inline-block;
  vertical-align: middle;

  &.Button_Icon--label {
    margin-right: $spacing-sm;
  }
}

.Button__icon,
.Button__txt--vAlignBaseline {
  vertical-align: baseline;
}

.Button__txt--vAlignMiddle {
  vertical-align: middle;
}
