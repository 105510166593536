@import '../_Field-helpers.scss';

@mixin Label-container() {
  @include u-resetLabel();
  margin-bottom: $spacing-xs;
}

@mixin Label-text() {
  color: $color-gray-80;
  font-family: map-get($fonts, opensanssemibold);
  font-size: $text-font-size-sm;
  font-weight: 600;
}
