@import '../../scss/helpers/_all';
@import '../GroupCard/helpers.scss';

.GroupCardHeader__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.GroupCardHeader__container--borderTop {
  border-top: $border-width-sm solid $color-gray-50;
}

.GroupCardHeader__container--borderBottom {
  border-bottom: $border-width-sm solid $color-gray-50;
}

.GroupCardHeader__container--info {
  background-color: $color-blue-20;
}

.GroupCardHeader__container--warning {
  background-color: $color-yellow-20;
}

.GroupCardHeader__container--critical {
  background-color: $color-red-20;
}

.GroupCardHeader__content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  padding: $spacing-md;
}

.GroupCardHeader--paddingZero {
  padding: 0;
}

.GroupCardHeader__actions {
  @include GroupCard-right();
  align-items: center;
  display: flex;
  justify-content: center;

  button {
    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .14);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }
  }
}

.GroupCardHeader__labelContent {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
}

.GroupCardHeader__labelContent--header {
  margin-right: $spacing-sm;
}

.GroupCardHeader__labelContent--subHeader {
  color: $text-muted;
  margin: 0;
}

.GroupCardHeader__value {
  color: $color-gray-80;
}

.GroupCardHeader__value--strikethrough {
  text-decoration: line-through;
}

.GroupCardHeader__value--positive {
  color: $color-green-80;
}

.GroupCardHeader__value--negative {
  color: $color-red-80;
}
