@import '../_Field-helpers';

.TextArea {
  @include Field-input-base();
  height: 13.5rem;
}

.TextArea--sm {
  height: 6rem;
}

.TextArea--error {
  @include Field-input-error();
}
