@import './_NotificationBand-helpers';

.Item {
  @include notificationBand-type-to-background-and-color();
  padding: map-get($notificationBand-padding, v) map-get($notificationBand-padding, h);
}

.Item--divided {
  border-bottom: $border-width-sm solid $notificationBand-divider-color;
}

.Item--closeEnabled {
  padding-right: map-get($notificationBand-padding, h) * 3;
}

.Item__media {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -(map-get($notificationBand-padding, v));
}

.Item__media__element {
  flex-grow: 0;
  margin-right: map-get($notificationBand-padding, h);
  margin-top: map-get($notificationBand-padding, v);
}

.Item__media__body {
  flex-basis: 0;
  flex-grow: 1;
  margin-top: map-get($notificationBand-padding, v);
}

.Item__icon {
  font-size: 2.8rem;
  line-height: 1;
}
