@import '../../scss/helpers/_all';

.Chip {
  align-items: center;
  border: $border-width-sm solid transparent;
  border-radius: $fn-border-radius-sm;
  display: inline-flex;
  flex-wrap: nowrap;
  padding: $spacing-xs $spacing-sm;
  position: relative;
  transition: all .25s ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: $color-gray-20;
    border: $border-width-sm solid $color-gray-60;
    cursor: pointer;
  }
}

.Chip--cursor--default {
  cursor: default;

  &:hover {
    cursor: default;
  }
}

.Chip--Small {
  height: 2.4rem;
}

.Chip--ESmall {
  padding: $spacing-xs * .5 $spacing-sm;
}

.Chip--active {
  background-color: $color-gray-40;
}

.Chip__Label,
.Chip__Value {
  display: inline-block;
}

.Chip--TextStrikethrough {
  text-decoration: line-through;
}

.Chip__Label {
  font-family: $text-font-family-semi-bold;
  margin-right: $spacing-sm;
}

.Chip__CloseIcon {
  align-items: center;
  background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0%, rgba(240, 240, 242, .48) 6%, $color-gray-20 24%);
  color: $color-gray-80;
  cursor: pointer;
  display: flex;
  height: 100%;
  opacity: 0;
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
  position: absolute;
  right: 0;
  transition: opacity .25s ease-in-out;
}

.Chip__Icon {
  align-items: center;
  color: $color-gray-80;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  padding-right: $spacing-sm;
}

.Chip__IconRight {
  padding-left: $spacing-sm;
  padding-right: 0;
}

.Chip:hover {
  .Chip__CloseIcon {
    opacity: 1;
  }
}

.Chip--warning {
  background-color: $color-yellow-20;

  &:hover {
    background-color: $color-gray-20;
    cursor: pointer;
  }

  .Chip__Icon {
    color: $color-yellow-60;
  }
}

.Chip--warning--dark {
  background-color: $color-orange-20;
  border: 1px solid $color-orange-40;
  font-size: 14px;
  padding: $spacing-xs * .5 $spacing-sm;

  &:hover {
    background-color: $color-orange-20;
    border: 1px solid $color-orange-40;
  }

  .Chip__Icon {
    color: $color-orange-60;
    margin-right: $spacing-xs;
    padding: 0;
  }
}

.Chip--info {
  background-color: $color-blue-20;

  &:hover {
    background-color: $color-gray-20;
    cursor: pointer;
  }

  .Chip__Icon {
    color: $color-blue-60;
  }
}

.Chip--success {
  background-color: $color-green-20;

  &:hover {
    background-color: $color-green-20;
    border: $border-width-sm solid transparent $color-green-60;
    cursor: pointer;
  }

  .Chip__Icon {
    color: $color-green-60;
  }
}

.Chip--success--dark {
  background-color: $color-green-20;
  border: 1px solid $color-green-40;
  color: $color-gray-100;
  font-size: 14px;
  font-weight: 400;
  padding: $spacing-xs * .5 $spacing-sm;

  &:hover {
    background-color: $color-green-20;
    border: 1px solid $color-green-40;
  }

  .Chip__Icon {
    color: $color-green-60;
    margin-right: $spacing-xs;
    padding: 0;
  }
}

.Chip--default--dark {
  background-color: $color-gray-20;
  border: 1px solid $color-gray-50;
  color: $color-gray-100;
  font-size: 14px;
  font-weight: 400;
  padding: $spacing-xs * .5 $spacing-sm;

  &:hover {
    background-color: $color-gray-20;
    border: 1px solid $color-gray-50;
  }

  .Chip__Icon {
    color: $color-gray-80;
    margin-right: $spacing-xs;
    padding: 0;
  }
}

.Chip--critical {
  background-color: $color-red-20;

  &:hover {
    background-color: $color-gray-20;
    cursor: pointer;
  }

  .Chip__Icon {
    color: $color-red-60;
  }
}
