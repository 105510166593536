@import './helpers.scss';

.ActiveFilter__wrapper {
  width: $toolbar-filter-width;
}

.ActiveFilter__filter {
  @include u-resetButton();
  border-radius: $fn-border-radius-lg;
  box-sizing: border-box;
  color: $color-gray-80;
  cursor: pointer;
  display: inline-flex;
  padding-left: $spacing-xs;
  padding-right: $spacing-sm;
  transition: background-color .25s ease-in-out;
}

.ActiveFilter__filterOption {
  @include Toolbar-filterOption();
  background-color: $color-white;
}

.ActiveFilter__filterLabel {
  font-family: $text-font-family-semi-bold;
}

.ActiveFilter__filter:hover {
  background-color: $color-gray-40;
}

.ActiveFilter__filter:focus {
  background-color: $color-gray-50;
}
