//
// Control
// ------------------------------

@import 'spinner';
@import 'mixins';

.Select {
  position: relative;

  // preferred box model
  &,
  & div,
  & input,
  & span {
    @include box-sizing(border-box);
  }

  // handle disabled state
  &.is-disabled > .Select-control {
    background-color: $select-input-bg-disabled;

    &:hover {
      box-shadow: none;
    }
  }

  &.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
  }
}

// base
.Select-control {
  background-color: $select-input-bg;
  border: $select-input-border-width solid $select-input-border-color;
  border-collapse: separate;
  border-color: lighten($select-input-border-color, 5%) $select-input-border-color darken($select-input-border-color, 10%);
  border-radius: $select-input-border-radius;
  border-spacing: 0;
  color: $select-text-color;
  cursor: default;
  display: table;
  height: $select-input-height;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  }

  .Select-input:focus {
    outline: none;
  }
}

.is-searchable {
  &.is-open > .Select-control {
    cursor: text;
  }
}

.is-open > .Select-control {
  @include border-bottom-radius( 0 );
  background: $select-input-bg;
  border-color: darken($select-input-border-color, 10%) $select-input-border-color lighten($select-input-border-color, 5%);

  // flip the arrow so its pointing up when the menu is open
  .Select-arrow {
    border-color: transparent transparent $select-arrow-color;
    border-width: 0 $select-arrow-width $select-arrow-width;
    top: -2px;
  }
}

.is-searchable {
  &.is-focused:not(.is-open) > .Select-control {
    cursor: text;
  }
}

.is-focused:not(.is-open) > .Select-control {
  border-color: $select-input-border-focus lighten($select-input-border-focus, 5%) lighten($select-input-border-focus, 5%);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0,  .1), 0 0 5px -1px fade($select-input-border-focus,50%);
}

.Select--multi > .Select-control .Select-value {
  overflow: hidden;
  padding-right: $spacing-sm;
  white-space: nowrap;
}

// placeholder
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: $select-input-placeholder;
  left: 0;
  line-height: $select-input-internal-height;
  max-width: 100%;
  overflow: hidden;
  padding-left: $select-padding-horizontal;
  padding-right: $select-padding-horizontal;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
}

.has-value.Select--single > .Select-control .Select-value,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
  .Select-value-label {
    color: $select-text-color;
  }

  a.Select-value-label {
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $select-link-hover-color;
      outline: none;
      text-decoration: underline;
    }
  }
}


// the <input> element users type in
.Select-input {
  // inherits `display: inline-block` from "react-input-autosize"
  height: $select-input-internal-height;
  padding-left: $select-padding-horizontal;
  padding-right: $select-padding-horizontal;
  vertical-align: middle;

  > input {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    line-height: 14px;  /* For IE 8 compatibility */
    margin: 0;
    outline: none;
    padding: (($select-input-internal-height - 14) / 2 - 2) 0 (($select-input-internal-height - 14) / 2 + 2);  /* For IE 8 compatibility */

    .is-focused & {
      cursor: text;
    }
  }
}

// fake-hide the input when the control is pseudo-focused
.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

// fake input
.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

// loading indicator
.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: $select-loading-size;
}

.Select-loading {
  @include Select-spinner($select-loading-size, $select-loading-color-bg, $select-loading-color);
  vertical-align: middle;
}


// the little cross that clears the field
.Select-clear-zone {
  @include animation( Select-animation-fadeIn 200ms );
  color: $select-clear-color;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: $select-clear-width;

  &:hover {
    color: $select-clear-hover-color;
  }
}

.Select-clear {
  display: inline-block;
  font-size: $select-clear-size;
  line-height: 1;
}

.Select--multi .Select-clear-zone {
  width: $select-clear-width;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  clip: rect(0,0,0,0);
  display: inline-block;
  height: 1px;
  margin: -1px; // stylelint-disable-line declaration-property-unit-disallowed-list
  overflow: hidden;
  width: 1px;
}


// arrow indicator
.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  padding-right: $select-arrow-width;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: ($select-arrow-width * 5);
}

.Select-arrow {
  border-color: $select-arrow-color transparent transparent;
  border-style: solid;
  border-width: $select-arrow-width $select-arrow-width calc($select-arrow-width / 2);
  display: inline-block;
  height: 0;
  position: relative;
  width: 0;
}

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: $select-arrow-color-hover;
}




// Animation
// ------------------------------

// fade in

@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
