@import './_Tile-helpers';

.Tile__bd {
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 $border-width-sm $border-width-sm 0 rgba(0,0,0,.14),
    0 .2rem .1rem -.1rem rgba(0,0,0,.12),
    0 0 .2rem 0 rgba(0,0,0,.2);
}

.Tile__division {
  padding: $spacing-md;
}

.Tile__division__heading {
  border-bottom: $border-width-sm solid $color-gray-50;
  color: $color-gray-90;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-md;
  margin-left: -$spacing-md;
  margin-right: -$spacing-md;
  margin-top: 0;
  padding-bottom: $spacing-md;

  h3,
  .Tile__division__count,
  .Tile__division__subHeading,
  .Tile__division__actions {
    align-self: center;
    padding-right: $spacing-sm;
  }

  h3 {
    padding-left: $spacing-md;
  }

  .Tile__division__count {
    background: $color-gray-40;
    border-radius: 1.2rem;
    font-size: 80%;
    height: 1.8rem;
    line-height: 1.5rem;
    margin-right: $spacing-sm;
    padding-left: $spacing-sm;
    text-align: center;
  }

  .Tile__division__subHeading {
    color: $color-gray-80;
    flex-grow: 2;
    font-size: 80%;
  }

  .Tile__division__subComponent {
    flex-grow: 100;
    text-align: right;
  }

  .Tile__division__actions {
    color: $color-gray-70;
    flex-grow: 1;
    padding-right: $spacing-md;
    text-align: right;

    button:focus {
      outline: 0;
    }

    a,
    button {
      padding-left: $spacing-xs;
    }
  }

  .Tile__division__action__button {
    display: inline;
    position: relative;
  }
}

.Tile__division__heading_compact {
  border-bottom: $border-width-sm solid $color-gray-50;
  color: $color-gray-90;
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacing-md;
  margin-right: -$spacing-md;
  margin-top: 0;
  padding-bottom: $spacing-md;

  h3,
  .Tile__division__count,
  .Tile__division__subHeading,
  .Tile__division__actions {
    align-self: center;
    padding-right: $spacing-sm;
  }

  h3 {
    padding-left: $spacing-md;
  }

  .Tile__division__count {
    background: $color-gray-40;
    border-radius: 1.2rem;
    font-size: 80%;
    height: 1.8rem;
    line-height: 1.5rem;
    margin-right: $spacing-sm;
    padding-left: $spacing-sm;
    text-align: center;
  }

  .Tile__division__subHeading {
    color: $color-gray-80;
    flex-grow: 2;
    font-size: 80%;
  }

  .Tile__division__subComponent {
    flex-grow: 100;
    text-align: right;
  }

  .Tile__division__actions {
    color: $color-gray-70;
    flex-grow: 1;
    padding-right: $spacing-md;
    text-align: right;

    button:focus {
      outline: 0;
    }

    a,
    button {
      padding-left: $spacing-xs;
    }
  }

  .Tile__division__action__button {
    display: inline;
    position: relative;
  }
}

.Tile__division + .Tile__division {
  border-top: $border-width-sm solid $color-gray-50;
  padding-top: $spacing-md;
}

.Tile__division__empty {
  color: $color-gray-80;
  font-style: italic;
  padding: $spacing-md 0;
  text-align: center;
}

.Tile__division__spaceBetween {
  gap: 1.6rem 5rem;
  justify-content: space-between;
}

.Tile__division__statusChip {
  margin-right: $spacing-md;
  @media screen and (max-width: 1234px) {
    margin-left: $spacing-md;
  }
}
