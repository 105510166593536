@import '../../../../../scss/helpers/all';

$Indicator-width-height: 2rem;

$Indicator-colors: (
    white: $color-white,
    charcoal: $color-gray-100,
    green: $color-green-100
);

$Indicator-property-colors: (
    border: (
        default: map-get($Indicator-colors, charcoal),
        hover: map-get($Indicator-colors, charcoal),
        complete: map-get($Indicator-colors, green)
    ),
    background: (
        default: map-get($Indicator-colors, white),
        hover: map-get($Indicator-colors, white),
        complete: map-get($Indicator-colors, green)
    ),
    foreground: (
        default: map-get($Indicator-colors, white),
        hover: map-get($Indicator-colors, charcoal),
        complete: map-get($Indicator-colors, white)
    ),
);

@mixin IndicatorColorsToProperties($state:default) {
  @if ($state) {
    background-color: map-deep-get($Indicator-property-colors, background, $state);
    border-color: map-deep-get($Indicator-property-colors, border, $state);
    color: map-deep-get($Indicator-property-colors, foreground, $state);
  }
}
