@import '../Option/helpers.scss';

.Svg {
  height: $Option-fixed-line-height;
  position: relative;
  width: $Option-svg-wh;
}

.Svg__elem {
  $verticalMargins: calc(($Option-fixed-line-height - $Option-svg-wh) / 2);
  display: block;
  fill: $color-gray-60;
  height: $Option-svg-wh;
  position: absolute;
  top: $verticalMargins;
  // transition: all .25s ease-in-out;
  width: $Option-svg-wh;
}

.Svg__elem--base {
  z-index: 1;
}

.Svg__elem--secondary {
  z-index: 2;
}

.Svg__elem--disabled {
  fill: $color-gray-50;
}

.Svg__elem--selected,
.Svg__elem--indeterminate {
  fill: $color-indigo-60;
}

.Svg__elem--selected.Svg__elem--disabled,
.Svg__elem--indeterminate.Svg__elem--disabled {
  fill: rgba($color-indigo-60, .5);
}

// needed to hide base svg when secondary svg has opacity
.Svg__elem--hidden {
  visibility: hidden;
}

:global {
  .Svg-enter {
    opacity: .01;
    transform: scale(0);
  }

  .Svg-enter.Svg-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all #{$Options-svg-transition-miliseconds}ms ease-in-out;
  }

  .Svg-leave {
    opacity: 1;
    transform: scale(1);
  }

  .Svg-leave.Svg-leave-active {
    opacity: .01;
    transform: scale(0);
    transition: all #{$Options-svg-transition-miliseconds}ms ease-in-out;
  }
}
