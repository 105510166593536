@import '../../../scss/helpers/all';

.Tooltip {
  height: 0;
  position: absolute;
  width: 0;
  z-index: 2;
}

.Tooltip__overlay {
  background-color: rgba($color-gray-100, .25);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Tooltip__close {
  position: absolute;
  right: $spacing-xs;
  top: $spacing-xs;
}

.Tooltip__pullCenter {
  transform: translateX(-50%);
}
