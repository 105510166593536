/* stylelint-disable  declaration-property-unit-disallowed-list */

@import '../../../../../scss/helpers/all';

.Thumbnail__curatednetwork--border {
  border: $color-orange-60;
  border-style: solid;
  border-width: 2px;
}

.Thumbnail {
  border-radius: 50%;
  display: inline-flex;
  overflow: hidden;
  padding: 1.8px;
  width: 5rem;
}

.Thumbnail__main {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  width: 100%;
}

.Thumbnail--widthAuto {
  display: flex;
  width: auto;
}

.Thumbnail__inner {
  height: 0;
  padding-bottom: 100%;
}
