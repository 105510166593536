@import '../../../../scss/helpers/_all.scss';

.IconIndicator {
  $hw: .8rem;
  animation-duration: .25s;
  animation-iteration-count: 1;
  animation-name: popout;
  animation-timing-function: ease-in-out;
  background-color: $color-red-80;
  border-radius: 50%;
  height: $hw;
  transform: perspective(1px) translateZ(0) translateY(.2rem) scale(0);
  width: $hw;
}

@keyframes popin {
  0% {
    transform: translateY(.2rem) scale(0);
  }

  60% {
    transform: translateY(-.1rem) scale(1.2);
  }

  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes popout {
  0% {
    transform: translateY(0) scale(1);
  }

  100% {
    transform: translateY(.2rem) scale(0);
  }
}

.IconIndicator--active {
  animation-duration: .25s;
  animation-name: popin;
  transform: translateY(0) scale(1);
}

.IconIndicator--red {
  background-color: $color-red-80;
}

.IconIndicator--blue {
  background-color: $color-blue-80;
}

.IconIndicator--green {
  background-color: $color-green-80;
}

.IconIndicator--orange {
  background-color: $color-orange-60;
}

.IconIndicator--yellow {
  background-color: $color-yellow-60;
}

.IconIndicator--gray {
  background-color: $color-gray-60;
}
