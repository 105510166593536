@import '../../scss/helpers/_all';

$rem-2: 2rem;

.Carousel {
  width: 100%;

  &__Wrap {
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      display: block;
      margin-top: 100%;
    }
  }

  &__Slides {
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__Slide {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      position: relative;
      top: 0;
      transition: box-shadow ease 1s, filter ease 2s, top ease 1s;

      &__Wrap {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
      }

      &--Overlay {
        &::before {
          content: '';
          height: 100%;
          position: absolute;
          transition: box-shadow ease 1s;
          width: 100%;
          z-index: 1;
        }

        &:hover::before {
          box-shadow: inset 0 0 0 200rem rgba(0, 0, 0, .65);
        }
      }

      &:hover .Carousel__Actions {
        opacity: 1;
        visibility: visible;
      }

      &:hover .Carousel__Actions__Opener {
        opacity: 1;
        visibility: visible;
      }

      &--Blur {
        filter: blur(.3rem);
        transition: filter ease 1s;
      }

      &--Blur &__Thumbnail {
        opacity: 1;
      }

      &__Image {
        border-radius: $fn-border-radius-sm;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &__Thumbnail {
        border-radius: $fn-border-radius-sm;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        position: absolute;
        transition: opacity ease 1s;
        width: 100%;
      }

      &__Id {
        align-items: center;
        background-color: $color-gray-40;
        border-radius: 10rem;
        color: $color-gray-100;
        cursor: default;
        display: flex;
        height: 2rem;
        left: .5rem;
        padding: $spacing-2-xs $spacing-xs;
        position: absolute;
        text-decoration: none;
        top: 1rem;
        z-index: 2;

        &--BgColor {
          &--green40 {
            background-color: $color-green-40;
          }

          &--yellow40 {
            background-color: $color-yellow-40;
          }

          &--red40 {
            background-color: $color-red-40;
          }
        }
      }

      &--Selected {
        border: .3rem solid $color-indigo-100;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, .5);
        top: -.5rem;

        &:focus {
          outline: none;
        }
      }

      &__Icon {
        display: flex;
        font-size: 20rem;
        position: absolute;
      }

      &--1 {
        height: 100%;
        width: 100%;
      }

      &--4 {
        height: 46%;
        margin: 2%;
        width: 46%;

        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        &__Icon {
          font-size: 10rem;
        }
      }

      &--16 {
        height: 21%;
        margin: 2%;
        width: 21%;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }

        &__Icon {
          font-size: 5rem;
        }
      }
    }
  }

  &__Actions {
    opacity: 0;
    position: absolute;
    transition: visibility .3s linear, opacity .3s linear;
    visibility: hidden;
    z-index: 1;

    &__Opener {
      background-color: transparent;
      border: none;
      color: white;
      height: 2rem;
      opacity: 0;
      position: absolute;
      right: .5rem;
      top: 1rem;
      transition: visibility .3s linear, opacity .3s linear;
      visibility: hidden;
      z-index: 2;

      &::before {
        content: "\2022 \2022 \2022";
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__Pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-sm;

    &__Links {
      display: block;

      &__Link {
        align-items: center;
        background-color: $color-gray-40;
        border-radius: 10rem;
        border-style: none;
        color: $color-gray-100;
        display: inline-flex;
        height: 2rem;
        justify-content: center;
        margin: 0 $spacing-2-xs;
        text-decoration: none;

        &:focus {
          outline: none;
        }

        &--BgColor {
          &--green40 {
            background-color: $color-green-40;
          }

          &--yellow40 {
            background-color: $color-yellow-40;
          }

          &--red40 {
            background-color: $color-red-40;
          }
        }

        &--Active {
          background-color: $color-gray-100;
          color: white;
        }

        &--IsDotted {
          &::before {
            content: "\2022 \2022 \2022";
          }
        }

        &--IsInDot {
          display: block;
          margin: $spacing-2-xs auto;
        }
      }

      &--IsInDot {
        max-height: 21rem;
        overflow-y: auto;
      }
    }
  }
}
