@import './helpers';

.DrawerAnimationAndLayout {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.DrawerAnimationAndLayout__content {
  width: $Drawer-width;
}

.DrawerAnimationAndLayout__content--enter {
  overflow: hidden;
  transform: translateX(100%);
  width: 0;

  &Active {
    transform: translateX(0);
    transition-duration: $Drawer-duration;
    width: $Drawer-width;
  }
}

.DrawerAnimationAndLayout__content--leave {
  overflow: hidden;
  transform: translateX(0);
  width: $Drawer-width;

  &Active {
    transform: translateX(100%);
    transition-duration: $Drawer-duration;
    width: 0;
  }
}
