@import '../../scss/helpers/_all.scss';

.BodyText--xs {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xxs;
  line-height: $text-line-height-xxxs;
}

.BodyText--sm {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xs;
  line-height: $text-line-height-xxs;
}

.BodyText--md {
  font-family: $text-font-family-default;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
}

.BodyText--mdSemiBold {
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
}

.BodyText--mdBold {
  font-family: $text-font-family-bold;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
}
