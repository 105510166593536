@import '../GroupCard/helpers.scss';

.GroupCardContentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.GroupCardContentBody {
  flex-grow: 1;
  margin-left: $spacing-xl;
  margin-right: $spacing-md;
}

.GroupCardContentBody---midSizeLeftPadding {
  margin-left: $spacing-md;
}

.GroupCardContainerContainer__gutter {
  @include GroupCard-right();
  background: rgba(0,0,0,.03);
  display: flex;
}
