@import '../../scss/helpers/_all.scss';

.Heading--h1 {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xxxl;
  letter-spacing: $text-letter-spacing-1;
  line-height: $text-line-height-xl;
}

.Heading--h2 {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xxl;
  letter-spacing: $text-letter-spacing-2;
  line-height: $text-line-height-lg;
}

.Heading--h3 {
  font-family: $text-font-family-default;
  font-size: $text-font-size-xl;
  letter-spacing: $text-letter-spacing-3;
  line-height: $text-line-height-md;
}

.Heading--h4 {
  font-family: $text-font-family-default;
  font-size: $text-font-size-lg;
  letter-spacing: $text-letter-spacing-3;
  line-height: $text-line-height-sm;
}

.Heading--h5 {
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-md;
  letter-spacing: $text-letter-spacing-4;
  line-height: $text-line-height-sm;
}

.Heading--h6 {
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-sm;
  letter-spacing: $text-letter-spacing-5;
  line-height: $text-line-height-xs;
}
