@import './_Media-helpers';

.Media {
  display: flex;
  flex-direction: row;
}

.Media--vMiddle {
  align-items: center;
}

.Media__element {
  flex-grow: 0;
  margin-right: $Media-spacing;
}

.Media__body {
  flex-grow: 1;
}
