@import './_Label-helpers.scss';

.Label {
  @include Label-container();
  display: flex;
  flex-direction: row;
}

.Label--srOnly {
  @include u-srOnly();
}

.Label__text {
  @include Label-text();
}

.Label__desc {
  color: $color-gray-80;
  font-style: italic;
  margin-left: $spacing-xs;
  white-space: nowrap;
}

.Label--horizontal {
  display: inline;
  font-weight: 400;
}

.Label__help {
  display: inline-flex;
  font-size: $text-font-size-md;
  margin-left: $spacing-xs;
}
