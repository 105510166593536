@import './helpers.scss';

.Tip {
  background-color: $color-white;
  border: $border-width-sm solid $color-gray-40;
  border-radius: $fn-border-radius-sm;
  box-shadow: 0 0 .4rem 0 rgba(0,0,0,.25);
  color: $color-gray-100;
  font-size: $text-font-size-xs;
  max-width: 26.5rem;
  padding: map-get($Tip-padding, vertical) map-get($Tip-padding, horizontal);
  position: relative;
}

.Tip--isFlush {
  padding: 0;
}

.Tip__arrow {
  height: $Tip-widthHeightShadow;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  width: $Tip-widthHeightShadow;
}

.Tip__arrow::before {
  background-color: $color-white;
  box-shadow: 0 0 $Tip-shadowWidth 0 rgba(0,0,0,.25);
  content: ' ';
  height: $Tip-widthHeight;
  position: absolute;
  transform: rotate(45deg);
  width: $Tip-widthHeight;
}

.Tip--widthAuto {
  max-width: none;
}

.Tip--blue {
  background-color: $Tip-blue;
  color: $color-white;
}

.Tip--blue > .Tip__arrow::before {
  background-color: $Tip-blue;
}

// Exceptions for non-bem selectors below to work with react-tether

/* tether top */
:global(.tether-element-attached-bottom.tether-element-attached-center) {
  .Tip {
    transform: translateY(-$Tip-offset);
  }

  .Tip__arrow {
    bottom: -$Tip-widthHeightShadowHalf;
    height: $Tip-widthHeightShadowHalf;
    left: 50%;
    margin-left: -$Tip-widthHeightShadowHalf;
    visibility: visible;
  }

  .Tip__arrow::before {
    bottom: $Tip-shadowWidth;
    left: calc($Tip-shadowWidth / 2);
  }

  &:global(.tether-out-of-bounds-bottom) .Tip {
    display: none;
  }
}

// tether right
:global(.tether-element-attached-middle.tether-element-attached-left) {
  .Tip {
    transform: translateX($Tip-offset);
  }

  .Tip__arrow {
    left: -$Tip-widthHeightShadowHalf;
    margin-top: -$Tip-widthHeightShadowHalf;
    top: 50%;
    visibility: visible;
    width: $Tip-widthHeightShadowHalf;
  }

  .Tip__arrow::before {
    left: $Tip-shadowWidth;
    top: calc($Tip-shadowWidth / 2);
  }

  &:global(.tether-out-of-bounds-left) .Tip {
    display: none;
  }
}

// tether bottom
:global(.tether-element-attached-top.tether-element-attached-center) {
  .Tip {
    transform: translateY($Tip-offset);
  }

  .Tip__arrow {
    height: $Tip-widthHeightShadowHalf;
    left: 50%;
    margin-left: -$Tip-widthHeightShadowHalf;
    top: -$Tip-widthHeightShadowHalf;
    visibility: visible;
  }

  .Tip__arrow::before {
    left: calc($Tip-shadowWidth / 2);
    top: $Tip-shadowWidth;
  }

  &:global(.tether-out-of-bounds-top) .Tip {
    display: none;
  }
}

// tether left
:global(.tether-element-attached-middle.tether-element-attached-right) {
  .Tip {
    transform: translateX(-$Tip-offset);
  }

  .Tip__arrow {
    margin-top: -$Tip-widthHeightShadowHalf;
    right: -$Tip-widthHeightShadowHalf;
    top: 50%;
    visibility: visible;
    width: $Tip-widthHeightShadowHalf;
  }

  .Tip__arrow::before {
    right: $Tip-shadowWidth;
    top: calc($Tip-shadowWidth / 2);
  }

  &:global(.tether-out-of-bounds-right) .Tip {
    display: none;
  }
}


