@import '../../../../scss/helpers/all';

.InfoIcon__btn {
  @include u-resetButton();
  @include u-icon();
  @include u-flushIcon();

  &::before {
    content: $icon-info-circle;
  }

  &.InfoIcon__btn__default {
    color: $color-blue-80;
  }

  &.InfoIcon__btn__indigo {
    color: $color-indigo-60;
  }
}
