@import './_Button-helpers';

$bg-primary-hover: #D9EEFD;
$bg-warning-hover: #FDCBA9;

.Button {
  border: $border-width-sm solid transparent;
  border-radius: $fn-border-radius-md;
  cursor: pointer;
  display: inline-block;
  font-size: $btn-font-size;
  letter-spacing: 0;
  line-height: $text-line-height-xs;
  padding: $btn-padding-v $btn-padding-h;
  text-align: center;
  transition-duration: .25s;
  transition-property: background-color, border-color, color; // can't animate all properties (we don't want to animate width)
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.Button--hasFixedHeight {
  height: 4rem;
  padding-bottom: 0;
  padding-top: 0;
}

.Button--block {
  display: block;
  width: 100%;
}

.Button--primary {
  background-color: $color-blue-20;
  border-color: $color-blue-40;
  color: $color-blue-80;
  @include btn-hover-focus(true) {
    background-color: $bg-primary-hover;
    border-color: $color-blue-60;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $bg-primary-hover;
      border-color: $color-blue-60;
    }
  }
}

.Button--secondary {
  background-color: $color-white;
  border-color: $color-gray-50;
  color: rgba(0,0,0,.6);
  @include btn-hover-focus(true) {
    background-color: $color-gray-20;
    border-color: $color-gray-60;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $color-gray-20;
      border-color: $color-gray-60;
    }
  }
}

.Button--warning {
  background-color: $color-orange-20;
  border-color: $color-orange-40;
  color: $color-orange-80;
  @include btn-hover-focus(true) {
    background-color: $bg-warning-hover;
    border-color: $color-orange-60;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $bg-warning-hover;
      border-color: $color-orange-60;
    }
  }
}

.Button--gray10 {
  background-color: $color-gray-10;
  border-color: $color-gray-10;
  color: $color-gray-100;
  @include btn-hover-focus(true) {
    background-color: $color-gray-30;
    border-color: $color-gray-30;
    outline-color: $color-gray-30;
  }

  &:active,
  &.Button--isActive {
    &:not([disabled]) {
      background-color: $color-gray-50;
      border-color: $color-gray-50;
      outline-color: $color-gray-50;
    }
  }

  &.Button--block {
    text-align: left;
  }
}

.Button--fade {
  background-color: $color-gray-10;
  border-color: $color-gray-50;
  color: $color-gray-60;
}

// fade disabled buttons
.Button:disabled {
  opacity: $btn-disabledOpacity;
}

.Button--sm {
  border-radius: $fn-border-radius-sm;
  font-size: $text-font-size-xs;
  line-height: $text-line-height-xxs;
  padding: $spacing-xs $spacing-sm;
}

.Button--xs {
  border-radius: $fn-border-radius-sm;
  font-size: $text-font-size-xs;
  line-height: $text-line-height-xxs;
  padding: 0;
}

.Button--rightBorderRadiusDisabled {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.Button--rightBorderDisabled {
  border-right: 0;
}

.Button--leftBorderRadiusDisabled {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.Button__Icon {
  display: inline-block;
  vertical-align: middle;

  &.Button_Icon--label {
    margin-right: $spacing-xs;
  }
}

.Button--primary--icon {
  color: $color-blue-60;
}

.Button--secondary--icon {
  color: $color-gray-60;
}

.Button--warning--icon {
  color: $color-orange-80;
}

.Button__txt--vAlignBaseline {
  vertical-align: baseline;
}

.Button__txt--vAlignMiddle {
  vertical-align: middle;
}
