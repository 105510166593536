@import './_TextEdit-helpers';

.TextEdit__toolbar {
  border-bottom: none!important; // stylelint-disable-line declaration-no-important

  :global
  & {
    $grey: (
            default: $color-gray-100,
            hover: $color-gray-60
        );

    button,
    .ql-stroke,
    .ql-fill {
      transition: all .2s ease-in-out;
    }

    button {
      color: map-get($grey, default);
      margin-right: $spacing-xs;

      &:hover {
        color: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important */

        .ql-stroke {
          stroke: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important */
        }

        .ql-fill {
          fill: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important */
        }
      }
    }

    .ql-active {
      background-color: $color-white!important; // stylelint-disable-line declaration-no-important

      border-radius: .3rem;
      color: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important *//* stylelint-disable-line declaration-no-important */

      .ql-stroke {
        stroke: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important */
      }

      .ql-fill {
        fill: map-get($grey, hover)!important;/* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

.TextEdit__editor {
  :global
  & {
    .quill-contents {
      border-top: none!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}

.TextEdit__output {
  :global
  & {
    del {
      @include TextEdit-del-styles(false);
    }

    .fn_tool_tips_secret_desc {
      @include TextEdit-del-styles(false);
    }
  }
}

.TextEdit__output--authorMode {
  :global
  & {
    del,
    .fn_tool_tips_secret_desc {
      color: $color-white!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}

.TextEdit__output {
  z-index: 1;
}
