@import './helpers';

.DrawerTransition {
  bottom: 0;
  display: flex;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
}

.DrawerTransition__content {
}

.DrawerTransition__content--sm {
  width: 33.6rem;
}

.DrawerTransition__content--lg {
  width: 47.2rem;
}

.DrawerTransition__content--enter {
  overflow: hidden;
  transform: translateX(100%);

  &Active {
    transform: translateX(0);
    transition-duration: $Drawer-duration;
  }
}

.DrawerTransition__content--leave {
  overflow: hidden;
  transform: translateX(0);

  &Active {
    transform: translateX(100%);
    transition-duration: $Drawer-duration;
  }
}
