@import '../../../scss/helpers/_all';
@import './helpers.scss';

.IdentityCell {
  font-family: $base-font-family;
  width: 40rem;
  @include clearfix();
}

.IdentityCell__link {
  @include u-textLink();
}

.IdentityCell__header {
  overflow: hidden;
}

.IdentityCell__subheader {
  color: $color-gray-90;
  font-size: $font-size-sm;
  overflow: hidden;
}

.IdentityCell__thumbnail {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $fn-border-radius-full;
  float: left;
  height: $thumbnail-dimension;
  margin-left: $spacing-xs;
  margin-right: $spacing-sm;
  width: $thumbnail-dimension;
}

.IdentityCell__pointer {
  cursor: pointer;
}
