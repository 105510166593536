@import '../../scss/helpers/_all.scss';

.PricingTable {
  border-collapse: separate;
  border-spacing: 0;
}

.PricingTable--stretch {
  width: 100%;
}

.PricingTable tr th:first-child,
.PricingTable tr td:first-child {
  border-left: $border-width-sm solid $color-gray-50;
}

/* top-left border-radius */
.PricingTable tr:first-child th:first-child {
  border-top-left-radius: $fn-border-radius-md;
}

/* top-right border-radius */
.PricingTable tr:first-child th:last-child {
  border-top-right-radius: $fn-border-radius-md;
}

/* bottom-left border-radius */
.PricingTable tr:last-child td:first-child {
  border-bottom-left-radius: $fn-border-radius-md;
}

/* bottom-right border-radius */
.PricingTable tr:last-child td:last-child {
  border-bottom-right-radius: $fn-border-radius-md;
}

.PricingTable__header {
  height: $spacing-4-xl;
}

.PricingTable__headerAction {
  background-color: $color-white;
}

.PricingTable__cell {
  border-bottom: $border-width-sm solid $color-gray-50;
  border-right: $border-width-sm solid $color-gray-50;
  font-size: $text-font-size-sm;
  padding: $spacing-md;
  text-align: left;
}

.PricingTable__headerTitle {
  background-color: $color-gray-100;
  color: $color-white;
  font-size: $text-font-size-lg;
  font-weight: 100;
}

.PricingTable__cell--center {
  text-align: center;
}

.PricingTable__cellContent--background {
  background-color: $color-gray-10;
}

.PricingTable__category--background {
  background-color: $color-gray-30;
}

.PricingTable__category--font {
  font-size: $text-font-size-lg;
  font-weight: 500;
}

.PricingTable__categoryItem {
  padding-left: $spacing-xl;
}

:export {
  activePkg1BorderColor: $color-blue-60;
  activePkg2BorderColor: $color-teal-60;
  activePkg3BorderColor: $color-indigo-60;
  activePkg4BorderColor: $color-yellow-60;
  activePkg5BorderColor: $color-orange-60;
  activePkg6BorderColor: $color-red-60;
  activePkg7BorderColor: $color-green-60;
  activePkg8BorderColor: $color-purple-60;
  activePkg9BorderColor: $color-gray-60;
  borderWidthMd: $border-width-md;
}
