@import '../_Field-helpers.scss';

$Select-default-background-color: (
    hover: $color-gray-30,
    focus: $color-blue-20,
);

$Select-multi-background-color: (
    default: $color-gray-30,
    hover: $color-red-20
);

$Select-tag-color-default: $color-gray-100;
$Select-tag-font-size: 1.2rem;
$Select-tag-border-radius: .2rem;
$Select-tag-padding-h: $spacing-xs;

$Select-tag-value-spacing: .5rem;
