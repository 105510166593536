@import '../../../scss/helpers/_all.scss';

.ItemContent {
  border-bottom: 1px solid $color-gray-40;
  display: flex;
  padding: $spacing-sm * 1.5 0;
}

.ItemContent:hover {
  background-color: $color-gray-10;
}

.TotalContent {
  display: flex;
  margin-top: $spacing-sm;
}

.ReadUnReadArea {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 22px;
}

.ThumbnailArea {
  margin: auto;
  width: 32px;
}

.MessageArea {
  cursor: pointer;
  flex: 1;
  height: 100%;
  margin: auto $spacing-sm;
}

.ActionArea {
  margin-right: $spacing-sm;
  text-align: end;
  width: 35px;
}

.MessageAreaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Thumb {
  background-color: $color-gray-40;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex-shrink: 0;
  height: 3rem;
  margin-right: $spacing-sm;
  margin-top: $spacing-xs;
  vertical-align: middle;
  width: 3rem;
}

.Title {
  color: $color-gray-100;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 225px;
}

.Timestamp {
  color: $color-gray-80;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .4px;
  line-height: 19.92px;
  text-align: right;
}

.ItemText {
  color: $color-gray-80;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .17px;
  line-height: 20.02px;
}

.Unread {
  background-color: $color-blue-100;
  border-radius: 50%;
  float: left;
  height: 1rem;
  width: 1rem;
}

.Read {
  background-color: $color-white;
}

.Dot {
  margin-top: $spacing-sm;
}

.User {
  display: flex;
  float: left;
  height: 100%;
}

.WOId {
  color: $color-gray-60;
  font-weight: 400;
}

.AvailableStatus {
  color: $color-gray-60;
  font-weight: 400;
}

.Action {
  cursor: pointer;
}

.NoLongerStatus {
  font-size: 12px;
  font-style: italic;
}

.ShowDetails {
  color: $color-indigo-60;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.ItemArea {
  display: contents;
}
