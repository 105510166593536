@import '../../../../../scss/helpers/_all';

$Tabs-border-color: $color-gray-50;
$Tabs-border-width: $border-width-sm;
$Tabs-border-radius: $fn-border-radius-xs;
$Tabs-item-padding: (
    v: $spacing-sm,
    h: $spacing-md
);
$Tabs-panel-padding: (
    v: map-get($Tabs-item-padding, h),
    h: map-get($Tabs-item-padding, h),
);
$Tabs-item-spacing: $spacing-xs;
$Tabs-background: (
    default: $color-white,
    muted: $color-gray-10
);

@mixin Tabs-item() {
  background-color: map-get($Tabs-background, muted);
  border-left: $Tabs-border-width solid $Tabs-border-color;
  border-right: $Tabs-border-width solid $Tabs-border-color;
  border-top: $Tabs-border-width solid $Tabs-border-color;
  cursor: pointer;
  display: flex;
  margin-left: -$Tabs-border-width;
  transition: background-color .25s ease-in-out;
}

@mixin Tabs-item-active() {
  background-color: map-get($Tabs-background, default);
  border-color: $Tabs-border-color;
  cursor: default;
}

@mixin Tabs-item-inner() {
  border-bottom: $Tabs-border-width solid $Tabs-border-color;
  color: $color-gray-80;
  display: block;
  font-family: "Open Sans";
  font-size: $text-font-size-sm;
  line-height: $text-line-height-sm;
  padding: map-get($Tabs-item-padding, v) map-get($Tabs-item-padding, h);
}

@mixin Tabs-item-inner-active() {
  border-bottom-color: $color-white;
  color: $color-gray-100;
  font-family: "Open Sans SemiBold";
}
