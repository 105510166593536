@import '../../scss/helpers/all';

.IconActions {
  @include u-resetList();
}

.IconActions--hasSpacing {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
}
