@import '../../scss/helpers/_all';

$Table-cell-checkbox-padding-left: $spacing-md;
$Table-border: $border-width-sm solid $color-gray-50;
$Table-sticky-zindex: 2;
$Table-footer-zindex: 3;

$Table-row-color: (
  nuetral: $color-gray-10,
  neutral: $color-gray-10,
  info: $color-blue-20,
  warning: $color-yellow-20,
  danger: $color-red-20,
  success: $color-green-20,
  selected: $color-indigo-20,
);

@mixin Table-head-and-body() {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@mixin Table-cell() {
  background-color: $color-white;
  flex-grow: 0;
  padding: 0;
  text-align: left;
  vertical-align: top;
}

@mixin Table-cell-spacing() {
  padding-bottom: calc($spacing-xs / 2);
  padding-top: calc($spacing-xs / 2);
}

@mixin Table-cell-sticky() {
  position: sticky;
  z-index: $Table-sticky-zindex;
}

@mixin Table-cellOuter() {
  align-items: center;
  display: flex;
  /* stylelint-disable declaration-no-important */
  height: 100% !important; /* needs to over-ride inline height which is a required prop of react-resizable */
  /* stylelint-enable */
  overflow: hidden;
  padding: $spacing-xs $spacing-xs $spacing-xs $spacing-md;
  position: relative;
}

@mixin Table-cellInner() {
  box-sizing: border-box;
}

@mixin Table-cell-border-before-psuedo() {
  &::before {
    content: ' ';
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@mixin Table-color-modifiers() {
  transition: background-color .15s ease-in-out;
  @each $label, $map in $Table-row-color {
    &--#{$label} {
      background-color: $map;
    }
  }
}
