@import '../../scss/helpers/_all';

.bannerContainer {
  border: $border-width-sm solid $color-blue-60;
  border-radius: $fn-border-radius-sm;
  display: flex;
  padding: $spacing-md;
}

.banner {
  &--info {
    background-color: $color-blue-20;
    border: $border-width-sm solid $color-blue-60;
  }

  &--warning {
    background-color: $color-yellow-20;
    border: $border-width-sm solid $color-yellow-60;
  }

  &--alert {
    background-color: $color-orange-20;
    border: $border-width-sm solid $color-orange-60;
  }

  &--success {
    background-color: $color-green-20;
    border: $border-width-sm solid $color-green-60;
  }
}

.icon {
  &--warning {
    color: $color-yellow-60;
  }

  &--info {
    color: $color-blue-60;
  }

  &--alert {
    color: $color-orange-80;
  }

  &--success {
    color: $color-green-60;
  }
}

.bannerText {
  color: $color-gray-90;
  font-size: $text-font-size-sm;
  font-weight: 400;
  line-height: $text-line-height-xs;
  margin-left: $spacing-sm;
  width: 100%;
}

.bannerTitle {
  font-weight: 600;
}
