.Icon {
  display: inline-block;
  position: relative;
}

.Icon--block {
  display: block;
}

.Icon--disablePointerEvent {
  pointer-events: none;
}

.Icon__svg {
  $offset: .2rem;
  bottom: $offset;
  fill: currentColor;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

.Icon__svg > svg {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
