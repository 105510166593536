@import '../../../scss/helpers/_all.scss';

.MainMessage {
  border: 1px solid;
  border-color: $color-gray-80;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
  min-height: 30px;
  position: relative;
  width: 100%;
}

.Form {
  margin-top: $spacing-xs * .0788;
  max-height: 350px;
  min-height: 50px;
  overflow: hidden;
  width: 100%;
}

.Input {
  border: none;
  margin-top: $spacing-xs;
  padding-left: $spacing-sm;
  resize: none;
  width: 100%;
}

.InputButtonWrapper {
  bottom: 0;
  position: absolute;
  right: 0;
}

.AttachmentLabel {
  cursor: pointer;
  display: inherit;
}

.AttachmentLabel input {
  display: none;
}

.AttachmentImageContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
  width: 80%;
}

.AttachmentNonImageContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-sm;
}

.AttachmentBlock {
  margin-left: $spacing-sm;
  padding-bottom: $spacing-sm;
  position: relative;
}

.AttachmentImage {
  border-radius: 5px;
  height: 52px;
  width: 64px;
}

.AttachmentNonImage {
  border: 1px solid;
  border-color: $color-gray-50;
  border-radius: 5px;
  height: 52px;
  margin-bottom: $spacing-sm;
  margin-left: $spacing-sm;
  padding-bottom: $spacing-sm;
  padding-left: $spacing-sm;
  position: relative;
  width: 280px;
}

.AttachmentRemoveImageIcon {
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: -9px;
}

.AttachmentRemoveNonImageIcon {
  cursor: pointer;
  position: absolute;
  right: -9px;
  top: -9px;
}

.AttachmentName {
  display: inline-flex;
  position: absolute;
  top: 14px;
}

.Attachment_Icon--audioFile {
  color: $color-red-60;
}

.Attachment_Icon--videoFile {
  color: $color-red-80;
}

.Attachment_Icon--zipFile {
  color: $color-gray-80;
}

.Attachment_Icon--image {
  color: $color-orange-80;
}

.AttachmentNameContainer {
  font-family: $text-font-family-semi-bold;
  max-width: 91%;
  padding-right: $spacing-xs;
  position: relative;
  top: $spacing-xs;
}


.Input::-webkit-scrollbar {
  width: 0;
}

textarea.Input:focus {
  border-color: $color-gray-80;
  outline: none;
}

.ButtonContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: end;
}

.MessageCharCount {
  color: $color-gray-50;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .15px;
  margin-right: $spacing-sm;
  padding-right: $spacing-xs;
  padding-top: $spacing-xs;
  z-index: 9;
}

.Icon {
  color: $color-gray-60;
  cursor: pointer;
  height: 28px;
  padding-right: $spacing-sm;
  padding-top: $spacing-sm * 1.5;
  width: 100%;
}

.IconWithCharCount {
  padding-top: $spacing-xs;
}

.IconFlex {
  display: inline-flex;
  flex-direction: row;
  float: right;
  padding-bottom: $spacing-sm;
}

.SendPad {
  top: 4px
}

.AttachmentScroll {
  max-height: 200px;
  -ms-overflow-style: none;  /* IE and Edge */
  overflow-y: scroll;
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.AttachmentScroll::-webkit-scrollbar {
  display: none;
}
