@import '../Table/helpers.scss';
@import '../Table/styles.scss';

.TableCell {
  @include Table-cell();
  @include Table-color-modifiers();
  @include Table-cell-spacing();
  position: relative;
}

.TableCell--isNotFirstRow {
  @include Table-cell-border-before-psuedo();
}

.TableCell--sticky {
  @include Table-cell-sticky();
}

.TableCell--stickyBorderLeft {
  border-left: $Table-border;
}

.TableCell--stickyBorderRight {
  border-right: $Table-border;
}

.TableCell__outer {
  @include Table-cellOuter();
}

.TableCell--config {
  position: absolute;
}

.TableCell__outer--horizontalSm {
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
}

.TableCell__inner {
  @include Table-cellInner();
}

.TableCell--distinctBg {
  background-color: $color-gray-20;
}

.TableCell--rowHeight1 {
  height: 4rem;
}

.TableCell--rowHeight2 {
  height: 4.4rem;
}

.TableCell--rowHeight3 {
  height: 5.1rem;
}

.TableCell--borderRight {
  border-right: $border-width-sm solid $color-gray-50;
}

.TableCell--alignCenter {
  text-align: center;
}
