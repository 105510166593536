@import './helpers.scss';

.IconButton {
  @include u-resetButton();
  border-radius: 50%;
  box-sizing: border-box;
  color: $color-gray-80;
  display: inline-flex;
  padding: map-get($IconButton-padding, v) map-get($IconButton-padding, h);
  transition: background-color .25s ease-in-out;
}

.IconButton:hover,
.IconButton--active {
  background-color: $color-gray-40;
}

.IconButton--light {
  color: $color-white;
}

.IconButton--light:hover,
.IconButton--light.IconButton--active {
  background-color: rgba($color-gray-40, .5);
}

.IconButton__small {
  padding: $spacing-xs;
}

// classname to override :hover
.IconButton[disabled] {
  background-color: transparent;
  color: $color-gray-60;
  transform: translateZ(0); // need this for chrome to render correctly when switching states
}

.IconButton--fade {
  background-color: transparent;
  color: $color-gray-60;
  transform: translateZ(0);
}
