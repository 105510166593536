$container-padding: $spacing-md;
$container-padding-sm: $spacing-sm;
$container-border-size: $border-width-sm;
$container-border-color: $color-gray-50;
$container-border-style: $container-border-size solid $container-border-color;
$container-shadow: (
  color: rgba($color-gray-50, .5)
);
$container-background-color: (
  default: $color-white,
  grey: $color-gray-30
);

@mixin container-root($size:def) {
  background-color: map-get($container-background-color, default);
  @if ($size == sm) {
    padding: $container-padding-sm;
  } @else if  ($size == mix) {
    padding-bottom: $container-padding-sm;
    padding-left: $container-padding;
    padding-right: $container-padding;
    padding-top: $container-padding-sm;
  } @else {
    padding: $container-padding;
  }
}

@mixin container-border($sides...) {
  @each $side in $sides {
    @if $side == 'top' {
      border-top: $container-border-style;
    }
    @if $side == 'right' {
      border-right: $container-border-style;
    }
    @if $side == 'bottom' {
      border-bottom: $container-border-style;
    }
    @if $side == 'left' {
      border-left: $container-border-style;
    }
  }
}

@mixin close-button($container-padding: $spacing-md, $closePadding: $spacing-sm) {
  @include u-resetButton();
  font-size: $text-font-size-sm;
  line-height: 1;
  opacity: .75;
  padding: $closePadding;
  transition: opacity .25s ease-in-out;

  &::before {
    color: $color-gray-100;
    position: relative;
    top: .1rem;
  }

  &--light::before {
    color: $color-white;
  }

  &:hover {
    opacity: 1;
  }
}

@mixin container-close-button($container-padding: $spacing-md) {
  $closePadding: $spacing-sm;
  @include close-button($container-padding, $closePadding);
  position: absolute;
  right: $container-padding - $closePadding;
  top: 50%;
  transform: translateY(-50%);
}
