@import '../../scss/helpers/_all.scss';

.FileProcessing {
  border-radius: $fn-border-radius-sm;
  bottom: $spacing-lg;
  box-shadow: 0 .8rem 1.2rem 0 rgba($color-gray-100, .3);
  left: $spacing-lg;
  max-width: 40rem;
  min-width: 40rem;
  overflow: hidden;
  position: fixed;
  z-index: map-get($z-index, file-processing);
}

.FileProcessing__errorIndicator {
  color: $color-red-60;
  margin-right: $spacing-sm;
  max-height: 2.4rem;
}

.FileProcessing__header {
  align-items: center;
  background-color: $color-gray-100;
  border-radius: $fn-border-radius-sm $fn-border-radius-sm 0 0;
  color: $color-white;
  display: flex;
  padding: $spacing-xs $spacing-xs $spacing-xs $spacing-md;

  &--hasSuccess {
    border-top: .4rem solid $color-green-60;
  }

  &--hasError {
    border-top: .4rem solid $color-red-60;
  }

  &--first {
    flex-grow: 99;
  }

  &--last {
    margin-left: $spacing-sm;
  }
}

.FileProcessing__body {
  background-color: $color-white;
  overflow: hidden;
  transition: height 1000ms ease-in-out;

  &--collapsed {
    display: none;
  }
}

.FileProcessing__row {
  align-items: center;
  display: flex;
  min-height: 5.6rem;
  overflow: hidden;
  padding: $spacing-sm $spacing-sm $spacing-sm $spacing-md;
  transition: height 1000ms ease-in-out;

  &--fullPadding {
    padding: $spacing-md;
  }

  &--fullVerticalPadding {
    padding: $spacing-md $spacing-sm $spacing-md $spacing-md;
  }

  &--error > button {
    color: $color-red-60;
  }

  &--success > button {
    color: $color-green-60;
  }

  &--first {
    flex-grow: 100;
    min-height: 2.4rem;
  }

  &--last {
    max-height: 4rem;
  }

  &--message {
    &--success {
      color: $color-green-60;
    }

    &--error {
      color: $color-red-60;
    }

    &--processing {
      color: $color-orange-60;
    }
  }
}

.FileProcessing__row + .FileProcessing__row {
  border-top: 1px solid $color-gray-40;
}

