@import '../../scss/helpers/all';
@import '../GroupCard/helpers';

.GroupCardListItem__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.GroupCardListItem__container--info {
  background-color: $color-blue-20;
}

.GroupCardListItem__container--warning {
  background-color: $color-yellow-20;
}

.GroupCardListItem__container--critical {
  background-color: $color-red-20;
}

.GroupCardListItem--labelIcon {
  margin-right: $spacing-sm;
}

.GroupCardListItem--borderBottom {
  border-bottom: solid $color-gray-50 $border-width-sm;
}

.GroupCardListItem--borderTop {
  border-top: solid $color-gray-50 $border-width-sm;
}

.GroupCardListItem--leftChild {
  align-self: center;
  flex-grow: .99;
  margin: 0 $spacing-md 0 $spacing-xl;
  min-width: 1rem;
  overflow: hidden;
}

.GroupCardListItem--marginZero {
  margin: 0;
}

.GroupCardListItem--marginSm {
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
}

.GroupCardListItem--marginSmPlus {
  margin-left: $spacing-md;
  margin-right: $spacing-md;
}

.GroupCardListItem--innerLeftChild {
  align-self: center;
  margin-bottom: $spacing-sm;
  margin-top: $spacing-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GroupCardListItem--innerMiddleChild {
  flex: 1;
  margin: $spacing-sm;
}

.GroupCardListItem--middleContent {
  margin: auto;
}

.GroupCardListItem--innerRightChild {
  flex-grow: 0;
}

.GroupCardListItem--dotted {
  border-bottom: $border-width-sm dotted $color-gray-100;
}


.GroupCardListItem--rightChild {
  @include GroupCard-right();
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $spacing-sm 0 $spacing-sm 0;

  button {
    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .14);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }
  }
};

.GroupCardListItem--rightChild--strikethrough {
  text-decoration: line-through;
};
