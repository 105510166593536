@import '../Grid/helpers.scss';

.Section + .Section {
  margin-top: $grid-gutter;
}

.Section + .Section--divided {
  border-top: $grid-border-width solid $grid-border-color;
  padding-top: $grid-gutter;
}
