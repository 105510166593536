@import '../Label/helpers.scss';

.Legend {
  @include Label-container();
  @include Label-text();
}

.Legend--srOnly {
  @include u-srOnly();
}

.Legend--disabled {
  color: $color-gray-60;
}
