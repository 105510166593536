@import '../../../../../scss/helpers/_all';
$notificationBand-padding: (
    v: $spacing-sm,
    h: $spacing-lg
);
$notificationBand-divider-color: $color-gray-50;
$notificationBand-bg-color-red: $color-red-80;
$notificationBand-bg-color-orange: $color-orange-100;
$notificationBand-bg-color-grey: $color-gray-80;
$notificationBand-bg-color-green: $color-green-100;
$notificationBand-bg-color-blue: $color-blue-100;
$notificationBand-bg-color-light-blue: $color-blue-20;
$notificationBand-bg-color-white: $color-white;
$notificationBand-bg-color-orange80: $color-orange-80;
$notificationBand-bg-color-gray10: $color-gray-10;
$notificationBand-bg-color-warning-orange: rgba(246,228,217,1);
$notificationBand-text-color-warning-orange: rgba(0,0,0,.87);
$notificationBand-text-color-warning-gray10: rgba(0,0,0,.6);

$notificationBand-pointer-width: 3rem;
$notificationBand-pointer-height: 1.5rem;
$notificationBand-pointer-right: (
    "large": 8.3rem,
    "medium": 5rem
);

$notificationBand-types-and-colors: (
    notifications: (
        danger: (background: $notificationBand-bg-color-red),
        warning: (background: $notificationBand-bg-color-orange),
        default: (background: $notificationBand-bg-color-grey),
        success: (background: $notificationBand-bg-color-green),
        info: (background: $notificationBand-bg-color-blue),
        infoLight: (
            background: $notificationBand-bg-color-light-blue,
            color: $base-color
        ),
        secondary: (
            background: $notificationBand-bg-color-white,
            color: $base-color
        ),
        warning-orange: (
          background: $notificationBand-bg-color-warning-orange,
          color: $notificationBand-text-color-warning-orange
        ),
        orange80: (
          background: $notificationBand-bg-color-orange80,
          color: $color-white
        ),
        gray10: (
          background: $notificationBand-bg-color-gray10,
          color: $notificationBand-text-color-warning-gray10
        )
    ),
    status: (
        danger: (background: $color-red-80),
        warning: (background: $color-orange-80),
        default: (background: $color-gray-80),
        success: (background: $color-green-80),
        info: (background: $color-blue-80),
        secondary: (
            background: $notificationBand-bg-color-white,
            color: $base-color
        )
    )
);

@mixin notificationBand-type-to-background-and-color($family: 'notifications') {
  $colors: map-get($notificationBand-types-and-colors, $family);
  @each $label, $map in $colors {
    &--#{$label} {
      background-color: map-get($map, background);
      @if map-get($map, color) {
        color: map-get($map, color);
      } @else {
        color: $color-white;
      }
    }
  }
}
