@import '../../../../../scss/helpers/_all';
@import "../../Field/_Field-helpers.scss";

$PinnedMenu-scrollbarWidth: 1.6rem; // may vary across browsers

$PinnedMenu-icon-width: 1.4rem; // magic number
$PinnedMenu-itemPadding-v: .4rem;
$PinnedMenu-itemPadding-h: 1.6rem;

$PinnedMenu-itemPadding-extraSpaceWhenBold: .6rem;
$PinnedMenu-itemPadding-right-hover: $PinnedMenu-itemPadding-h + $PinnedMenu-icon-width;
$PinnedMenu-itemPadding-right: $PinnedMenu-itemPadding-right-hover + $PinnedMenu-itemPadding-extraSpaceWhenBold;

$PinnedMenu-dividerMargin-h: $PinnedMenu-scrollbarWidth;

$PinnedMenu-selectedIcon-rightSpace: $PinnedMenu-scrollbarWidth;

$PinnedMenuTypeAhead-padding-right: $PinnedMenu-itemPadding-h;
$PinnedMenuTypeAhead-padding-left: $PinnedMenu-itemPadding-h;
$PinnedMenuTypeAhead-padding-v: $PinnedMenu-itemPadding-v + $spacing-xs;

$PinnedMenuTypeAhead-search-rightSpace: $PinnedMenuTypeAhead-padding-right + 1rem;
$PinnedMenuTypeAhead-search-topSpace: $PinnedMenuTypeAhead-padding-v + .6rem;
