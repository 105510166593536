@import '../../../scss/helpers/_all.scss';

.MessageContent {
  white-space: pre-wrap;
}

.TotalContent {
  display: flex;
  margin-top: $spacing-sm;
}

.ThumbnailArea {
  width: 32px;
}

.MessageArea {
  flex: 1;
  height: 100%;
  margin: auto $spacing-sm;
}

.Thumb {
  background-color: $color-gray-40;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid $color-gray-40;
  border-radius: 50%;
  flex-shrink: 0;
  height: 3rem;
  margin-right: $spacing-sm;
  margin-top: $spacing-xs;
  vertical-align: middle;
  width: 3rem;
}

.Timestamp {
  color: $color-gray-80;
  float: right;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .4px;
  line-height: 19.92px;
  text-align: right;
}

.User {
  display: flex;
  float: left;
  height: 100%;
}

.ContentItemText {
  color: $color-gray-80;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .17px;
  line-height: 20.02px;
}

.UpporRow {
  display: flex;
}

.Title {
  flex: 1;
}

.AttachmentWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.AttachmentContainer {
  border: 1px solid $color-gray-40;
  border-radius: 8px;
  display: flex;
  flex: 0 1 48%;
  flex-direction: row;
  height: 52px;
  margin-left: $text-font-size-xxs / 8;
  margin-right: $text-font-size-xxs / 8;
  margin-top: $text-font-size-xxs / 4;
  padding: $spacing-sm;
}

.AttachedImage {
  border-radius: 4px;
  height: 32px;
  margin-right: $text-font-size-xxs / 8;
  width: 32px;
}

.Attachment_Icon {
  margin-top: $text-font-size-xxs / 4;
}

.Attachment_Icon--audioFile {
  color: $color-red-60;
}

.Attachment_Icon--videoFile {
  color: $color-red-80;
}

.Attachment_Icon--zipFile {
  color: $color-gray-80;
}

.Attachment_Icon--image {
  color: $color-orange-80;
}

.Attachment_Name {
  color: $color-gray-100;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-left: $text-font-size-xxs / 4;
  margin-top: $spacing-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Icon_Name {
  color: $color-gray-100;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-left: $text-letter-spacing-2;
  margin-top: $spacing-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
