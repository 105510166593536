@import './helpers';

.DrawerContainer {
  border-left: $border-width-sm solid $color-gray-50;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
