@import '../../scss/helpers/all.scss';

$Loader-animation-duration: 750ms;
$Loader-rec-height: 2.4rem;
$Loader-rec-height-small: 2rem;
$Loader-rec-width: .6rem;
$Loader-rec-spacing: .6rem;
$Loader-small-modifier: 4/6;

@mixin Loader-rec-small() {
  transform: scaleY(calc($Loader-rec-height-small/$Loader-rec-height));
}
