@import '../../../scss/helpers/all';

.NotificationBand__container {
  background-color: $color-gray-10;
  margin-top: 0;
  padding: $spacing-md;
  position: relative;
}

.NotificationBand--Info {
  border-bottom: $border-width-md solid $color-blue-60;
}

.NotificationBand--Danger {
  border-bottom: $border-width-md solid $color-orange-40;
}

.NotificationBand--Warning {
  border-bottom: $border-width-md solid $color-yellow-60;
}

.NotificationBand--Help {
  border-bottom: $border-width-md solid $color-blue-60;
}

.NotificationBand__ActionButton {
  margin-left: $spacing-sm;
}

.NotificationBand__Text {
  color: $color-gray-90;
  display: block;
}

.NotificationBand__PrimaryText {
  font-family: $text-font-family-semi-bold;
}

.NotificationBand__Icon {
  border-radius: $fn-border-radius-full;
  display: flex;
  margin-right: $spacing-md;
  padding: $spacing-xs;
}

.NotificationBand__Icon--Info {
  background-color: $color-blue-20;
  color: $color-blue-80;
}

.NotificationBand__Icon--Danger {
  background-color: $color-yellow-20;
  color: $color-orange-100;
}

.NotificationBand__Icon--Warning {
  color: $color-yellow-80;
}

.NotificationBand__Icon--Help {
  background-color: $color-blue-20;
  color: $color-blue-80;
}

.NotificationBand__Dismiss {
  padding-left: $spacing-sm;
}

.NotificationBand__Section {
  align-items: center;
  display: flex;
}
