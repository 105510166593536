@import '../../../../../scss/helpers/_all';

.Progress {
  background-color: $color-gray-50;
  border-radius: 1rem;
  height: .4rem;
  overflow: hidden;
  position: relative;
}

.Progress__bar {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: .5s linear;
}

.Progress__bar--red {
  background-color: $color-red-80;
}

.Progress__bar--orange {
  background-color: $color-orange-100;
}

.Progress__bar--green {
  background-color: $color-green-100;
}

.Progress__bar--gray {
  background-color: $color-gray-100;
}

.Progress__bar--green {
  background-color: $color-green-60;
}

.Progress__bar--indigo {
  background-color: $color-indigo-60;
}

.Progress__bar--yellow {
  background-color: $color-yellow-80;
}

.Progress__bar--blue {
  background-color: $color-blue-80;
}

.Progress__bar--gray {
  background-color: $color-gray-80;
}

.Progress__bar--orange {
  background-color: $color-orange-60;
}

.Progress__bar--teal {
  background-color: $color-teal-60;
}
