@import '../GroupCard/helpers.scss';

.WorkOrderCard__container {
  align-items: center;
  background-color: $color-white;
  border: $border-width-sm solid $color-gray-50;
  border-radius: $fn-border-radius-sm;
}

.WorkOrderCard__header {
  padding: $spacing-sm $spacing-md $spacing-sm $spacing-md;
}

.WorkOrderCard__actions {
  @include GroupCard-right();
  text-align: right;

  button {
    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .14);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }
  }
}

.WorkOrderCard__labelContent {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
}

.WorkOrderCard__labelContent--header {
  margin-right: $spacing-sm;
}

.WorkOrderCard__labelContent--subHeader {
  color: $text-muted;
  margin: 0;
}

.WorkOrderCard__mutedText {
  color: $text-muted;
}

.WorkOrderCard__body {
  background-color: $color-gray-30;
  border-bottom-left-radius: $fn-border-radius-sm;
  border-bottom-right-radius: $fn-border-radius-sm;
  font-size: $text-font-size-xs;
  padding: $spacing-sm $spacing-md;
  width: 100%;

  &--paddingTop {
    padding-top: $spacing-sm;
  }
}
