@import '../../../../../scss/helpers/_all';

$IconAside-iconWidth: 2rem;
$IconAside-iconWidth-lg: 3rem;

$IconAside-colors: (
    red: $color-red-100,
    blue: $color-indigo-80,
    green: $color-green-100,
    grey: $color-gray-50,
    grey80: $color-gray-80,
    blue60: $color-blue-60,
);
