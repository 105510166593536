@import './_Select-helpers.scss';

:global {
  // @import './scss/default.global';
  .FieldSelect__tetherElement {
    z-index: map-get($z-index, component-above-modal);

    .Select-menu-outer {
      position: static;
    }

    .Select-menu-outer.Select-menu-outer {
      border: none;
      border-radius: $FieldInput-border-radius;
      box-shadow: 0 3px 12px 2px rgba(20, 36, 35, .16);
    }

    &.tether-target-attached-bottom {
      .Select-menu-outer {
        margin-top: $Field-menu-vertical-offset;
      }
    }

    &.tether-target-attached-top {
      .Select-menu-outer {
        margin-bottom: $Field-menu-vertical-offset;
      }
    }

    .Select-menu {
      border-radius: $FieldInput-border-radius;
    }

    .Select-option {
      @include Field-input-transition();
      color: map-get($FieldInput-color, default);
      padding: $spacing-sm map-get($FieldInput-padding, h);
    }

    .Select-option.is-focused {
      background-color: map-get($Select-default-background-color, hover);
    }

    .Select-option.is-selected {
      background-color: map-get($Select-default-background-color, focus);
    }

    .Select-option.is-disabled {
      color: $color-gray-70;
      cursor: not-allowed;
    }

    .Select-option-group-label {
      color: $color-gray-70;
      font-size: $text-font-size-sm;
      padding: $spacing-xs;
      text-transform: capitalize;
    }

    .Select-option-group {
      padding: $spacing-xs $spacing-xs $spacing-xs $spacing-md;

      .Select-option {
        padding: $spacing-sm $spacing-lg;
      }
    }
  }
}

.FieldSelect {
  :global(.Select-control) {
    @include Field-input-base();
    display: table;
    padding: 0 $spacing-sm 0 map-get($FieldInput-padding, h);
  }

  :global(.Select-input) {
    padding-left: 0;
    padding-right: 0;
  }

  &:global(.Select--single) > :global(.Select-control) :global(.Select-value) {
    padding-left: map-get($FieldInput-padding, h);
    padding-right: map-get($FieldInput-padding, h);
  }

  :global(.Select-placeholder) {
    color: map-get($FieldInput-color, placeholder);
    padding-left: map-get($FieldInput-padding, h);
    padding-right: map-get($FieldInput-padding, h);
  }

  &:global(.is-focused:not(.is-open)) > :global(.Select-control),
  &:global(.is-focused) > :global(.Select-control) {
    @include Field-input-focus();
  }

  &:global(.is-disabled) > :global(.Select-control) {
    background-color: map-get($Field-background-color, disabled);
    border-color: map-get($FieldInput-border-color, disabled);
    cursor: not-allowed;
  }

  &:global(.is-disabled) :global(.Select-placeholder) {
    color: map-get($FieldInput-color, disabled);
  }

  :global(.Select-arrow-zone) {
    padding-right: 0;
  }

  :global(.Select-arrow),
  :global(.Select-arrow-zone:hover) > :global(.Select-arrow) {
    border-color: map-get($FieldInput-color, placeholder) transparent transparent;
  }

  &:global(.is-open) > :global(.Select-control) :global(.Select-arrow) {
    border-color: transparent transparent map-get($FieldInput-color, placeholder);
  }

  &:global(.is-open.tether-target-attached-top)  > :global(.Select-control.Select-control) {
    border-top-left-radius: $FieldInput-border-radius;
    border-top-right-radius: $FieldInput-border-radius;
  }

  &:global(.is-open.tether-target-attached-bottom)  > :global(.Select-control.Select-control) {
    border-bottom-left-radius: $FieldInput-border-radius;
    border-bottom-right-radius: $FieldInput-border-radius;
  }

  &:global(.Select--multi) {
    :global(.Select-input) {
      margin-left: $Select-tag-value-spacing;
    }

    :global(.Select-multi-value-wrapper) {
      margin-left: -#{$Select-tag-value-spacing};
    }

    :global(.Select-value) {
      @include Field-input-transition();
      background-color: map-get($Select-multi-background-color, default);
      border: none;
      border-radius: $Select-tag-border-radius;
      color: $Select-tag-color-default;
      font-size: $Select-tag-font-size;

      &:hover {
        background-color: map-get($Select-multi-background-color, hover);
      }

      + :global(.Select-input) {
        margin-left: $Select-tag-value-spacing;
      }
    }

    :global(.Select-value-icon) {
      border-right: none;
      padding: 0 $Select-tag-padding-h;

      &:hover {
        background-color: map-get($Select-multi-background-color, hover);;
        color: $Select-tag-color-default;
      }

      &:focus {
        background-color: map-get($Select-multi-background-color, hover);;
        color: $Select-tag-color-default;
      }
    }

    :global(.Select-value-label) {
      padding-left: $Select-tag-padding-h;
      padding-right: $Select-tag-padding-h;
    }

    &:global(.is-disabled) {
      :global(.Select-value) {
        background-color: map-get($Select-multi-background-color, default);
      }

      :global(.Select-value-icon) {
        display: none;
      }

      :global(.Select-value-label) {
        cursor: not-allowed;
      }
    }
  }
}

.TetherSelect--error {
  :global(.Select-control) {
    @include Field-input-error();
  }
}

.Wrapper {
  position: relative;

  &:focus-within {
    .Icon {
      visibility: visible;
    }

    .FieldSelect--icon {
      :global(.Select-control) {
        padding-left: map-get($FieldInput-padding, h) * 1.7;
      }

      :global(.Select-placeholder) {
        padding-left: map-get($FieldInput-padding, h) * 1.7;
      }

      &:global(.Select--single) > :global(.Select-control) :global(.Select-value) {
        padding-left: map-get($FieldInput-padding, h) * 1.7;
      }
    }
  }

  .Icon {
    align-items: center;
    color: $color-gray-80;
    display: flex;
    height: 100%;
    margin-left: $spacing-sm;
    position: absolute;
    visibility: hidden;
    z-index: 1;
  }
}
