@import '../../scss/helpers/_all';

$AbstractContainer-background: (
  default: $color-white,
  grey20: $color-gray-20,
  grey30: $color-gray-30,
  grey40: $color-gray-40,
);

$AbstractContainer-padding: (
  default: $spacing-md,
);

$AbstractContainer-border-width: $border-width-sm;
$AbstractContainer-border-color: $color-gray-50;

@mixin AbstractContainer-background-color($map) {
  @each $key, $value in $map {
    &--#{$key} {
      background-color: $value;
    }
  }
}
