@import './helpers.scss';

.GroupCard__container {
  border-radius: $fn-border-radius-sm;
  box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.14),
    0 .2rem .1rem -.1rem rgba(0,0,0,.12),
    0 0 .2rem 0 rgba(0,0,0,.2);
  position: relative;
}

.GroupCard__shadow {
  background: rgba(0,0,0,.03);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: $right-width;
  z-index: 1;
}

