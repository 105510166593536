@import './_Tile-helpers';

.Tile {

}

.Tile__division {
  margin-bottom: map-get($tile-padding, vertical);
}

.Tile__division + .Tile__division {
  border-top: $border-width-sm solid $tile-border-color;
  padding-top: map-get($tile-padding, vertical);
}

.Tile__division__hdg {
  font-size: $text-font-size-lg;
  line-height: $text-line-height-md;
}

.Tile__division__action {
  text-align: right;
}

.Tile__bd {
  background: $color-white;
  box-shadow: 0 1px 1px rgba(0,0,0, .05);
  padding: map-get($tile-padding, vertical) map-get($tile-padding, horizontal);
}

.Tile__sectionHdg {
  font-size: $text-font-size-md;
  line-height: $text-line-height-sm;
}

.Tile__section + .Tile__section {
  margin-top: map-get($tile-padding, vertical);
}
