@import './_Indicator-helpers';

.Indicator {
  @include IndicatorColorsToProperties(default);
  border-radius: 50%;
  border-style: solid;
  border-width: .2rem;
  height: $Indicator-width-height;
  text-align: center;
  transition: all .15s ease-in-out;
  width: $Indicator-width-height;
}

.Indicator--clickable {
  cursor: pointer;
}

.Indicator--disabled {
  opacity: .25;
}

.Indicator__icon {
  @include u-flushIcon();
  display: block;

  &::before {
    @include u-icon();
    content: $icon-checkmark;
    font-size: 1.2rem;
  }
}

.Indicator--hoverable:hover {
  @include IndicatorColorsToProperties(hover);
}

// chain to override hover
.Indicator--complete.Indicator--complete {
  @include IndicatorColorsToProperties(complete);
}
