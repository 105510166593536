@import "../../scss/helpers/all";

// this feels heavy-handed
body {
  background-color: $color-gray-30;
}

.UpgradeTier__container {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media(min-width: $screen-size-sm) {
  .UpgradeTier__container {
    bottom: 0;
  }
}

.UpgradeTier__body {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.UpgradeTier__tier {
  border-bottom: .2rem solid $color-orange-60;
  display: inline-block;
  padding-bottom: $spacing-xs;
}

@media(max-width: $screen-size-sm) {
  .UpgradeTier__tier {
    margin-top: $spacing-md;
  }
}

// stops eslint from yelling at me that css.UpgradeTier__right isn't defined
.UpgradeTier__right { }

@media(min-width: $screen-size-sm) {
  .UpgradeTier__right {
    display: inline-block;
    margin-left: $spacing-md;
  }
}

.UpgradeTier__image {
  @media(max-width: $screen-size-sm) {
    & {
      height: 25vh;
      max-height: 25vh;
    }
  }

  @media(min-width: $screen-size-sm) {
    & {
      float: left;
      //display: inline-flex;
      min-width: 30vw;
      width: 30vw;
    }
  }
}

.UpgradeTier__content {
  align-items: center;
  display: flex;
  max-width: 66vw;
  position: relative;
}

@media(max-width: $screen-size-sm) {
  .UpgradeTier__content {
    display: block;
    justify-content: center;
    margin-top: $spacing-md;
    text-align: center;
  }
}

.UpgradeTier__contentItem {
  color: $color-gray-90;
  margin-bottom: $spacing-md;
}

.UpgradeTier__marginRight {
  display: inline;
  margin-right: $spacing-sm;
}

.UpgradeTier__button {
  display: inline-block;

  @media(min-width: $screen-size-sm) {
    &:first-of-type {
      margin-right: $spacing-md;
    }
  }

  @media(max-width: $screen-size-sm) {
    &:last-of-type {
      margin-top: $spacing-sm;
    }
  }
}

.UpgradeTier__buttons {
  margin-top: $spacing-lg;
}

@media(max-width: $screen-size-sm) {
  .UpgradeTier__buttons {
    margin-bottom: $spacing-md;
  }
}
