/** @define c-notificationBand */

@import './_NotificationBand-helpers';

.notificationBand {
  @include notificationBand-type-to-background-and-color();
  display: none;
  margin-top: $notificationBand-pointer-height + $spacing-xs;
  position: relative;
}

.notificationBand--pointerDisabled {
  margin-top: 0;
}

.notificationBand--fixed {
  left: 0;
  margin-top: 0;
  position: fixed;
  right: 0;
  top: $page-v2-header-height;
  z-index: map-get($z-index, notication-band);
}

:global(.c-page__docRoot--2017navbar) .notificationBand--fixed {
  top: $page-header-height;
}

/* stylelint-disable selector-bem-pattern */
.notificationBand a:not([class]) {
  @include u-underlineUntilHover();
  color: inherit;
}

/* stylelint-enable */
.notificationBand--isVisible {
  display: block;
}

.notificationBand__pointer {
  border-bottom: $notificationBand-pointer-height solid transparent;
  border-left: (calc($notificationBand-pointer-width / 2)) solid transparent;
  border-right: (calc($notificationBand-pointer-width / 2)) solid transparent;
  content: ' ';
  margin-left: -(calc($notificationBand-pointer-width / 2));
  position: absolute;
  right: map-get($notificationBand-pointer-right, large);
  top: -$notificationBand-pointer-height;
}

.notificationBand--danger > .notificationBand__pointer {
  border-bottom-color: $notificationBand-bg-color-red;
}

.notificationBand--warning > .notificationBand__pointer {
  border-bottom-color: $notificationBand-bg-color-orange;
}

.notificationBand--default > .notificationBand__pointer {
  border-bottom-color: $notificationBand-bg-color-grey;
}

.notificationBand--success > .notificationBand__pointer {
  border-bottom-color: $notificationBand-bg-color-green;
}

.notificationBand--info > .notificationBand__pointer {
  border-bottom-color: $notificationBand-bg-color-blue;
}

.notificationBand__close {
  $close-padding: $spacing-xs;
  @include u-resetButton();
  padding: $close-padding;
  position: absolute;
  right: map-get($notificationBand-padding, h) - $close-padding;
  top: map-get($notificationBand-padding, v) - $close-padding;
}

@include bp-max(53.5em) {
  .notificationBand__pointer {
    right: map-get($notificationBand-pointer-right, medium);
  }
}
