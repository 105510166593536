@import '../../../../../scss/helpers/_all';

@mixin status-baseStyle() {
  background-color: $color-gray-80;
  border-radius: $fn-border-radius-lg;
  color: $color-white;
  display: inline-block;
  font-size: $text-font-size-base;
  overflow: hidden;
  padding: $spacing-xs $spacing-md;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}
