@import './helpers.scss';

.Calendar__event--placeholder {
  &:global(.rbc-event.rbc-event) {
    $color: $color-gray-50;
    background-color: $color;
    border-top-color: $color;
    border-width: 0;
  }
}

.Calendar__event {
  // Produce the CSS for coloring.
  @each $key, $color in $Calendar-colors {
    $root: &;

    &--#{$key} {
      &:global(.rbc-event.rbc-event) {
        background-color: map-get($color, background);
        border-color: map-get($color, border);
        color: map-get($color, border); // this will color the icon
      }
    }
  }
}

// remove border for unavailable
.Calendar__event--unavailable {
  &:global(.rbc-event.rbc-event) {
    border-color: transparent;
  }
}

// remove colored background for unconfirmed
.Calendar__event--unconfirmed {
  &:global(.rbc-event.rbc-event) {
    background-color: $color-white;
  }

  @each $key, $color in $calendar-unconfirmed-colors {
    &--#{$key} {
      &:global(.rbc-event.rbc-event) {
        /* stylelint-disable-next-line declaration-no-important */
        background-color: map-get($color, background) !important;
        border-color: map-get($color, border);
      }
    }
  }
}

// remove colored background for unconfirmed
.Popover__event {
  margin-left: -$spacing-sm;
}

.Popover__text {
  font-family: $text-font-family-semi-bold;
}
