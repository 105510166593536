@import './_FormGrid-helpers';

.FormGrid {
  margin-left: -$FormGrid-gutter;
  margin-top: -$FormGrid-gutter;
}

.FormGrid__row {

}

.FormGrid__row--engaged {
  display: flex;
  flex-direction: row;
}

.FormGrid__column {
  margin-left: $FormGrid-gutter;
  margin-top: $FormGrid-gutter;
  @each $align in $FormGrid-alignments {
    &--#{$align} {
      text-align: $align;
    }

    &--#{$align}--engaged.FormGrid__column--engaged {
      text-align: $align;
    }
  }
}

.FormGrid__column--engaged {
  flex-grow: 1;
}

.FormGrid__column--engaged.FormGrid__column--auto {
  flex-grow: 0;
}
