//
// Multi-Select
// ------------------------------


// Base
.Select--multi {

  // add margin to the input element
  .Select-input {
    margin-left: $select-padding-horizontal;
    padding: 0;
    vertical-align: middle;
  }

  // reduce margin once there is value
  &.has-value .Select-input {
    margin-left: $select-item-gutter;
  }

  // Items
  .Select-value {
    background-color: $select-item-bg;
    border: $border-width-sm solid $select-item-border-color;
    border-radius: $select-item-border-radius;
    color: $select-item-color;
    display: inline-block;
    font-size: $select-item-font-size;
    margin-left: $select-item-gutter;
    margin-top: $select-item-gutter;
    vertical-align: top;
  }

  // common
  .Select-value-icon,
  .Select-value-label {
    display: inline-block;
    vertical-align: middle;
  }

  // label
  .Select-value-label {
    @include border-right-radius( $select-item-border-radius );
    cursor: default;
    padding: $select-item-padding-vertical $select-item-padding-horizontal;
  }

  a.Select-value-label {
    color: $select-item-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // icon
  .Select-value-icon {
    @include border-left-radius( $select-item-border-radius );
    border-right: $border-width-sm solid $select-item-border-color;
    cursor: pointer;

    // move the baseline up by 1px
    padding: ($select-item-padding-vertical - 1) $select-item-padding-horizontal ($select-item-padding-vertical + 1);

    &:hover,
    &:focus {
      background-color: $select-item-hover-bg;
      color: $select-item-hover-color;
    }

    &:active {
      background-color: $select-item-border-color;
    }
  }
}

.Select--multi.is-disabled {
  .Select-value {
    background-color: $select-item-disabled-bg;
    border: $border-width-sm solid $select-item-disabled-border-color;
    color: $select-item-disabled-color;
  }
  // icon
  .Select-value-icon {
    border-right: $border-width-sm solid $select-item-disabled-border-color;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background-color: $select-item-disabled-bg;
    }
  }
}
