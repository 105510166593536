@import '../../scss/helpers/all';

$toolbar-filter-width: 28rem;
$toolbar-filter-search-height: 5.6rem;
$filter-option-height: 4rem;
$filter-panel-max-height: 40.5rem;

.FilterPanelWidth {
  width: $toolbar-filter-width;
}

@mixin Toolbar-filterPanel() {
  background-color: $color-white;
  box-shadow: 0 .3rem .4rem 0 rgba(0,0,0,.14), 0 .3rem .3rem -.2rem rgba(0,0,0,.12), 0 .1rem .8rem 0 rgba(0,0,0,.2);
  max-height: $filter-panel-max-height;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: $toolbar-filter-width;
}

@mixin Toolbar-filterSearch() {
  border-bottom: $border-width-sm solid $color-gray-50;
  height: $toolbar-filter-search-height;
  max-width: inherit;
  position: fixed;
  width: $toolbar-filter-width;
  z-index: 3;
}

@mixin Toolbar-filterOptions() {
  margin-top: $toolbar-filter-search-height;
}

@mixin Toolbar-filterOption() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: $filter-option-height;
  padding-left: $spacing-md;
  padding-right: $spacing-md;

  &:hover {
    background-color: $color-gray-30;
    cursor: pointer;
  }
}

@mixin Toolbar-filterSection() {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
}

@mixin Toolbar-filterEmpty() {
  align-items: center;
  color: $color-gray-60;
  display: flex;
  flex-direction: column;
  font-style: italic;
  height: $filter-option-height;
  justify-content: center;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
}
