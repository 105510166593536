@import '../../../scss/helpers/_all';

.ClearButtonWrapper button {
  color: $color-indigo-60;
  font-size: $text-font-size-xs;
}

.OptionContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: $text-font-size-xxs;
  margin-left: $text-font-size-xl;
  margin-top: $text-font-size-xxs;
}

.CheckboxContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: $spacing-xs;
  margin-left: $text-font-size-xl;
  margin-top: $spacing-xs;
}

.OptionNote {
  font-size: $text-font-size-xxs;
  margin-left: $text-font-size-xxs;
}

.OptionNoteLink {
  color: $color-indigo-60;
}

.FooterContainer {
  padding: $spacing-md;
}

.FooterContainerLeft {
  color: $color-gray-80;
  font-size: $text-font-size-xs;
  padding-top: $spacing-xs;
  text-align: left;
}

.FooterContainerRight {
  text-align: right;
}
