@import '../../../../../scss/helpers/_all';

.SaveBar {
  @include container-root();
  @include container-border(top);
  bottom: 0;
  box-shadow: 0 -.1rem .5rem 0 map-get($container-shadow, color);
  left: 0;
  position: fixed;
  right: 0;
  z-index: map-get($z-index, 'save-bar');
}
