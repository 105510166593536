@import "../../scss/helpers/all";

.TitleBar {
  background-color: $color-white;
  border-bottom: $border-width-sm solid $color-gray-50;
  padding: $spacing-md;
}

.TitleBar__title {
  color: $color-gray-80;
}

.TitleBar__contentComponent,
.TitleBar__buttonGroup {
  display: inline-block;
  vertical-align: middle;
}

.TitleBar__contentComponent {
  background-color: $color-gray-20;
  border-radius: $fn-border-radius-sm;
  height: 4rem;
  margin-right: $spacing-md;
  padding: $spacing-sm $spacing-md;
}

.TitleBar__contentComponent--noMarginRight {
  margin-right: 0;
}
