@import './_ScreenNotification-helpers';

.ScreenNotification {
  bottom: 0;
  box-shadow: 0 0 .5rem .5rem rgba(0,0,0, .2);
  max-width: 100%;
  min-width: 50%;
  position: fixed;
  right: 0;
  transition: all .5s ease-in-out;
  z-index: map-get($z-index, screen-notification);
}
