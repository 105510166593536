@import './_NavigationMenu-helpers.scss';

.NavigationMenu + .NavigationMenu {
  margin-top: $spacing-lg;
}

.NavigationMenu__list {
  @include u-resetList();
  color: $color-gray-100;
}

.NavigationMenu__header {
  background-color: $color-white;
  padding: $spacing-md $NavigationMenu-horizontal-padding;
}

.NavigationMenu__heading {
  color: $color-gray-80;
  font-weight: bold;
  line-height: $text-line-height-base;
}

.NavigationMenu__item {
  $fontSize: $base-font-size;
  border-right: $border-width-lg solid transparent;
  color: $color-gray-100;
  font-size: $fontSize;
  line-height: #{calc(2.4rem / $fontSize)};
  transition: all .25s ease-in-out;
}

.NavigationMenu__item,
.NavigationMenu__item.NavigationMenu__item.NavigationMenu__item--isDisabled {
  background-color: $color-white;
}

.NavigationMenu__item--applyHoverEffect:hover {
  background-color: $color-gray-10;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActive {
  background-color: $color-orange-20;
  border-right-color: $color-orange-60;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBorderPlacement--left {
  border-left: $border-width-lg solid transparent;
  border-right: none;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBorderPlacement--right {
  border-left: none;
  border-right: $border-width-lg solid transparent;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBorderColor--orange {
  border-left-color: $color-orange-60;
  border-right-color: $color-orange-60;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBorderColor--blue {
  border-left-color: $color-blue-60;
  border-right-color: $color-blue-60;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBackGroundColor--gray {
  background-color: $color-gray-20;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBackGroundColor--orange {
  background-color: $color-orange-20;
}

.NavigationMenu__item--isActive.NavigationMenu__item--isActiveBackGroundColor--indigo {
  background-color: $color-indigo-20;
}

.NavigationMenu__item--noBorder {
  border-right: none;
}
