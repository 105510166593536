@import './helpers.scss';

.hList {
  $rootSelector: &;

  &--inline {
    display: inline;
  }

  &__list {
    @include u-resetList();

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--inline {
      display: inline-flex;
    }

    &--vMiddle {
      align-items: center;
    }

    &--vBottom {
      align-items: flex-end;
    }

    &--noWrap {
      flex-wrap: nowrap;
    }

    &--divided > #{$rootSelector}__item {
      position: relative;
    }

    &--boxView {
      display: flex;
    }

    @for $i from 1 through 8 {
      $unit: $HorizontalList-spacing-unit;

      &--spaced#{$i}x {
        margin-bottom: -(calc($unit * $i / 2));
        margin-left: -($unit * $i);
        margin-top: -(calc($unit * $i / 2));
      }

      &--spaced#{$i}x > #{$rootSelector}__item {
        margin-bottom: calc($unit * $i / 2);
        margin-top: calc($unit * $i / 2);
        padding-left: $unit * $i;
      }

      &--spaced#{$i}x#{&}--divided > #{$rootSelector}__item::before {
        border-left: $border-width-sm solid $container-border-color;
        bottom: 0;
        content: ' ';
        position: absolute;
        right: -($unit * $i * .5);
        top: 0;
      }

      &--spaced#{$i}x#{&}--divided > #{$rootSelector}__item:last-child::before {
        border-left-width: 0;
      }
    }
  }
}

.hList__item {
  &--flexGrow {
    flex-grow: 1;
  }

  &--boxView {
    align-items: center;
    display: flex;
    flex: 1;
    height: 52px;
    justify-content: left;
    /* stylelint-disable */
    padding-left: 0 !important;
  }
}
