@import '../../../scss/helpers/_all';

$underlineWidth: 1ch;
$gap: .5 * $underlineWidth;

$otp4Length: 4;
$otp4Width: $otp4Length * ($underlineWidth + $gap);

$otp4Length: 6;
$otp6Width: $otp4Length * ($underlineWidth + $gap);

.Otp4InputContainer {
  input {
    background: repeating-linear-gradient(90deg,
      $color-gray-50 0, $color-gray-50 $underlineWidth,
      transparent 0, transparent $underlineWidth + $gap)
      0 100%/ #{$otp4Width - $gap} 2px no-repeat;
    background-position: 1.6rem 2.8rem;
    letter-spacing: $gap;
  }
}

.Otp6InputContainer {
  input {
    background: repeating-linear-gradient(90deg,
      $color-gray-50 0, $color-gray-50 $underlineWidth,
      transparent 0, transparent $underlineWidth + $gap)
      0 100%/ #{$otp6Width - $gap} 2px no-repeat;
    background-position: 1.6rem 2.8rem;
    letter-spacing: $gap;
  }
}
