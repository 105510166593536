@import '../../../../scss/helpers/all';

.SelectAll__trigger {
  @include u-resetButton();
  $dimensions: 2.4rem;
  display: block;
  height: $dimensions;
  width: $dimensions;

  &:global(.tether-enabled) {
    transform: rotate(180deg);
  }
}

.SelectAll__trigger:focus {
  outline: none;
}
