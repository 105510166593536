@import './_HelpText-helpers';

.HelpText {
  color: map-get($HelpText-color, default);
  margin-top: $spacing-xs;
  position: relative;
}

.HelpText--error {
  color: map-get($HelpText-color, error);
}

.HelpText--success {
  color: map-get($HelpText-color, success);
}

.HelpText--hasIcon {
  padding-left: $HelpText-icon-size + $spacing-xs;
}

.HelpText__icon {
  left: 0;
  line-height: 1;
  position: absolute;
  top: calc($text-line-height-base / 2) * $HelpText-font-size;
  transform: translateY(-50%);
}
