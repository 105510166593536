@import '../../scss/helpers/all';

.MassActionBar {
  background-color: $color-indigo-60;
  border-radius: $fn-border-radius-sm;
  flex-grow: 2;
  padding: $spacing-sm $spacing-md;
}

.MassActionBar__inner {
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.MassActionBarItem + .MassActionBarItem {
  padding-left: $spacing-sm;
}
