@import './_Addon-helpers';

.Addon {
  @include u-resetButton();
  @include Field-input-transition();
  background-color: $color-gray-10;
  border: $border-width-sm solid map-get($FieldInput-border-color, default);
  box-sizing: border-box;
  color: $base-color; // we dont't want to inherit color
  display: flex;
  height: $Addon-height;
  justify-content: center;
  position: relative;
  width: $Addon-width;

  &:hover {
    $bg: $color-gray-50;
    background-color: $bg;
    border-color: $bg;
  }

  &:focus {
    @include Field-input-focus();
    outline: none;
  }
}

.Addon--isLabel {
  align-items: center; // needs for firefox to keep the content Vertically centered
  padding: 0 $spacing-md;
  white-space: nowrap;
  width: auto;
}

.Addon--adjacentToDisabled {
  background-color: $color-white;
  border-color: map-get($FieldInput-border-color, disabled);
}

.Addon--left {
  border-bottom-left-radius: $FieldInput-border-radius;
  border-right-width: 0;
  border-top-left-radius: $FieldInput-border-radius;
}

.Addon--right {
  border-bottom-right-radius: $FieldInput-border-radius;
  border-left-width: 0;
  border-top-right-radius: $FieldInput-border-radius;
}

.Addon--active,
:global(.tether-enabled).Addon {
  background-color: $color-white;
}

.Addon__icon {
  left: calc(($Addon-width - 2.4rem) / 2); // need this for IE11 to render correctly
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
