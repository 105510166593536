@mixin u-resetButton() {
  background: transparent;
  border: none;
  box-sizing: content-box;
  padding: 0;
}

@mixin u-resetList() {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

@mixin u-srOnly() {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px; // stylelint-disable-line declaration-property-unit-disallowed-list
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin u-resetLabel() {
  display: inline;
  margin: 0;
}

@mixin u-resetLegend() {
  margin: 0;
}

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin u-textLink() {
  color: $link-color;
  text-decoration: $link-decoration;
  transition: color .25s ease-in-out;

  @include hover-focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus();
  }
}

@mixin u-textLinkSecondary() {
  color: $link-color__secondary;
  text-decoration: $link-decoration;
  transition: color .25s ease-in-out;

  @include hover-focus {
    color: $link-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus();
  }
}

@mixin u-underlineUntilHover() {
  text-decoration: underline;

  @include hover-focus {
    text-decoration: none;
  }
}

@mixin u-icon() {
  display: inline-block;
  font-family: map-get($fonts, icon);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
}

@mixin u-webkitScroll() {
  &::-webkit-scrollbar {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-gray-20;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-gray-50;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:horizontal:hover,
  &::-webkit-scrollbar-thumb:vertical:hover {
    background: $color-gray-60;
  }

  &::-webkit-scrollbar-thumb:horizontal:active,
  &::-webkit-scrollbar-thumb:vertical:active {
    background: $color-gray-70;
  }
}

@mixin u-flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin u-flexRow() {
  display: flex;
  flex-direction: row;
}

@mixin u-flexGrow() {
  flex: 1 0 auto;
}

@mixin u-flexAuto() {
  flex: 0 0 auto;
}

@mixin u-flushIcon() {
  line-height: 1;

  &::before {
    display: block;
  }
}
