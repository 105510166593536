@import './helpers.scss';

.Grid {
  overflow: visible;
}

.Grid--fillParent {
  display: flex;
  min-height: 100%;
}

.Grid--divided {
  overflow: hidden;
}

.Grid__root {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: -#{calc($grid-gutter / 2)};
  margin-right: -#{calc($grid-gutter / 2)};
}

.Grid__root--vSpace {
  margin-top: -$grid-gutter;
}

.Grid__root--divided {
  margin-left: -$grid-gutter;
  margin-right: -($grid-gutter + $grid-border-width);

  &.Grid__root--vSpace {
    margin-top: -($grid-gutter * 2 + $grid-border-width);
  }
}
