@import './_TextEdit-helpers';

@import './_react-quill';

.ql-toolbar {
  background-color: $color-gray-30;
}

.ql-editor {
  p,
  li {
    margin-bottom: 1em; // stylelint-disable-line declaration-property-unit-disallowed-list
  }

  del {
    @include TextEdit-del-styles(true);
  }

  &.ql-editor.ql-editor a {
    text-decoration: none;
  }
}
