@import '../../scss/helpers/_all';

$slider-before-after-space: $spacing-lg;
$slider-padding-top-space: $spacing-sm + $spacing-xs;
$slider-handle-size: $spacing-md + $spacing-sm;
$slider-handle-top-space: $spacing-sm + .2rem;
$active-badge-left-right-adjustment: $spacing-sm;

.DiscreteSlider__container {
  overflow: hidden;
}

.DiscreteSlider__label {
  color: $color-gray-80;
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
  margin-bottom: $spacing-sm;
}

.DiscreteSlider__wrapper {
  margin-left: $slider-before-after-space;
  margin-right: $slider-before-after-space;
}

.DiscreteSlider__main {
  background-color: transparent !important; // stylelint-disable-line declaration-no-important
  cursor: pointer;
  display: flex;
  min-height: $spacing-xxl + $spacing-md;
  padding-top: $slider-padding-top-space;
  position: relative;

  &::before {
    background-color: $color-indigo-60;
    border-radius: $spacing-xs 0 0 $spacing-xs;
    content: '';
    height: $spacing-xs;
    left: -$slider-before-after-space;
    position: absolute;
    width: $slider-before-after-space;
  }

  &::after {
    background-color: $color-gray-50;
    border-radius: 0 $spacing-xs $spacing-xs 0;
    content: '';
    height: $spacing-xs;
    position: absolute;
    right: -$slider-before-after-space;
    width: $slider-before-after-space;
  }

  &--disabled {
    &::before {
      background-color: $color-indigo-40;
    }
  }
}

.DiscreteSlider__handle {
  align-items: center;
  background-color: $color-indigo-60 !important; // stylelint-disable-line declaration-no-important
  border: none !important; // stylelint-disable-line declaration-no-important
  color: $color-white;
  display: flex;
  height: $slider-handle-size !important; // stylelint-disable-line declaration-no-important
  justify-content: center;
  margin-left: unset !important; // stylelint-disable-line declaration-no-important
  margin-top: -$slider-handle-top-space !important; // stylelint-disable-line declaration-no-important
  position: relative !important; // stylelint-disable-line declaration-no-important
  width: $slider-handle-size !important; // stylelint-disable-line declaration-no-important
  z-index: 2;

  &:active {
    background-color: $color-indigo-80 !important; // stylelint-disable-line declaration-no-important
    box-shadow: none;
  }

  &:focus {
    background-color: $color-indigo-80 !important; // stylelint-disable-line declaration-no-important
    box-shadow: none !important; // stylelint-disable-line declaration-no-important
  }

  &:hover {
    background-color: $color-indigo-80 !important; // stylelint-disable-line declaration-no-important
    box-shadow: none;
  }

  &--disabled {
    background-color: $color-indigo-40 !important; // stylelint-disable-line declaration-no-important

    &:active {
      background-color: $color-indigo-40 !important; // stylelint-disable-line declaration-no-important
    }

    &:focus {
      background-color: $color-indigo-40 !important; // stylelint-disable-line declaration-no-important
    }

    &:hover {
      background-color: $color-indigo-40 !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.DiscreteSlider__active_badge {
  background: $color-gray-40;
  border-radius: $fn-border-radius-sm;
  color: $color-gray-100;
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-sm;
  line-height: $text-line-height-xs;
  padding: $spacing-xs $spacing-sm $spacing-xs $spacing-sm;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: $spacing-lg + $spacing-xs;
  white-space: nowrap;

  &--disabled {
    background: $color-gray-20;
  }
}

.DiscreteSlider__active_badge_min {
  left: -$active-badge-left-right-adjustment;
}

.DiscreteSlider__active_badge_max {
  right: -$active-badge-left-right-adjustment;
}
