@import './helpers';

.SavedFilters__header {
  align-items: center;
  border-bottom: $border-width-sm solid $color-gray-50;
  display: flex;
  flex-wrap: nowrap;
  padding: $spacing-sm $spacing-md;
}

.SavedFilters {
  background-color: $color-white;
  box-shadow: 0 .1rem .5rem .1rem rgba($color-gray-100, .25);
  max-height: $filter-panel-max-height;
  overflow-y: auto;
  width: $toolbar-filter-width;
}

.SavedFilters__empty {
  color: $color-gray-70;
  padding: $spacing-lg;
  text-align: center;
}

.SavedFilters__headerLabel {
  flex-grow: 1;
}

.SavedFilters__option {
  @include Toolbar-filterOption();
}

.SavedFilters__optionSelected {
  @include Toolbar-filterOption();
  background-color: $color-indigo-20;
}

.SavedFilters__section {
  @include Toolbar-filterSection();
}

.SavedFilter__name {
  max-width: 22rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SavedFilters__deleteIcon {
  color: $color-gray-70;
  right: 1.5rem;
}

.SavedFilters__deleteIcon:hover {
  color: $color-gray-90;
  cursor: pointer;
}
