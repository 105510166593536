@import '../../../../../scss/helpers/_all';

.Card {
  @include container-root();
}

.Card--grey {
  background-color: map-get($container-background-color, grey);
}

.Card--grey20 {
  background-color: $color-gray-20;
}

.Card--small {
  padding: $container-padding-sm;
}
