@import './helpers';

.TooltipContent {
  font-size: $base-font-size;
  padding: $TooltipContent-padding;
}

.TooltipContent__hdr {
  padding-bottom: $TooltipContent-padding;
}

.TooltipContent__ftr {
  padding-top: $TooltipContent-padding;
}
