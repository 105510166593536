@import "../../../../scss/helpers/all";

$Navigation-itemHeight: $page-v2-header-height;
$Navigation--itemPadding-horizontal: 1.2rem;
$Navigation-drawerHorizontalPaddingRemNumber: 2;
$Navigation-drawerHorizontalPadding: $Navigation-drawerHorizontalPaddingRemNumber * 1rem;

$Navigation-iconFont-fontSize: 2rem;
$Navigation-icon-color: $color-gray-80;

$Navigation-divider-style: 1px solid $color-gray-80;

$Navigation-dropdownMenu-boxShadow: 0 .4rem .8rem 0 rgba(0, 0, 0, .1), 0 .2rem 1.6rem .4rem rgba(0, 0, 0, .14);

@mixin Navigation-arrow-icon() {
  @include u-icon();
  font-size: .8rem;
  margin-left: $spacing-sm;
}

// Navigation Animation Variables
$Navigation-animation-duration: 200;
$Navigation-animation-timingFunction: ease-in-out;
$Navigation-transition: all #{$Navigation-animation-duration}ms #{$Navigation-animation-timingFunction};

@mixin Navigation-animate-enter() {
  opacity: 0;
  transform: translateY(-100%);
  transform-origin: top;
}

@mixin Navigation-animate-leave() {
  opacity: 1;
  transform: translateY(0);
}

// export to use in JS files
:export {
  NavAnimationDuration: $Navigation-animation-duration;
  NavDrawerHorizontalPaddingRemNumber: $Navigation-drawerHorizontalPaddingRemNumber;
}
