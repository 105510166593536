@import './helpers.scss';

.PushDrawerContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.PushDrawerContainer__main {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.PushDrawerContainer__left {
  flex: 1 0 auto;
  height: 100%;
  overflow: auto;
  @include bp-max(map-get($screen-sizes, md)) {
    @include DrawerContainer-drawerPanelAbsolute();
  }
}

.PushDrawerContainer__left--absolute {
  @include DrawerContainer-drawerPanelAbsolute();
}
