@import '../../scss/helpers/_all';

.MSI__insights-popout {
  background-color: $color-white;
  border: none;
  border-radius: $fn-border-radius-sm;
  box-shadow: rgba(22, 39, 65, .24);
  font-family: $font-family-base;
  padding-bottom: $spacing-sm;
  text-align: center;
  width: 350px;
}

.MSI__insights-popout-holder,
.MSI__insights-popout-description,
.MSI__insights-popout-explore {
  margin: auto;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
}

.MSI_insights-popout-padding-top-xs {
  padding-top: $spacing-xs;
}

.MSI_insights-popout-padding-top-sm {
  padding-top: $spacing-sm;
}

.MSI_insights-popout-padding-top-md {
  padding-top: $spacing-md;
}

.MSI__insights-popout-label {
  color: $color-gray-100;
  font-size: $text-font-size-sm;
}

.MSI__insights-popout-description {
  color: $color-gray-80;
  font-size: $text-font-size-xs;
}

.MSI__insights-popout-price {
  color: $color-gray-100;
  font-family: $font-family-base;
  font-size: $text-font-size-xl;
}

.MSI__insights-popout-price-label {
  color: $color-gray-80;
  font-family: $font-family-base;
  font-size: $text-font-size-sm;
}

.MSI__insights-popout-explore {
  background-color: $color-gray-20;
  border-radius: $fn-border-radius-sm;
  margin: $spacing-md;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  padding-top: $spacing-xs;
  text-align: left;
}

.MSI__insights-popout-explore-header {
  color: $color-gray-100;
  font-family: $text-font-family-semi-bold;
  font-size: $text-font-size-md;
  padding: 0;
}

.MSI__insights-popout-explore-text {
  color: $color-gray-100;
  font-size: $text-font-size-xs;
}

.MSI__insights-popout-explore-button {
  padding: 0;
}

.MSI__insights-popout-button {
  border-top: $border-width-sm solid $color-gray-30;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  text-align: right;
}


.MSI__insights-chip {
  border-radius: $fn-border-radius-sm;
  color: $color-gray-100;
  font-size: $text-font-size-sm;
  max-height: 28px;
  padding: 0;
  text-align: center;
  width: 100%;
}

.MSI__insights-chip-blue {
  background-color: $color-blue-20;
  border: $border-width-sm solid $color-blue-40;
  color: $color-blue-80;
}


.MSI__insights-chip-gray {
  background-color: $color-gray-30;
  border: $border-width-sm solid $color-gray-50;
  color: $color-gray-80;
}

.MSI__insights-chip-green {
  background-color: $color-green-20;
  border: $border-width-sm solid $color-green-40;
  color: $color-green-80;
}


.MSI__insights-chip-yellow {
  background-color: $color-yellow-20;
  border: $border-width-sm solid $color-yellow-40;
  color: $color-yellow-80;
}

.MSI__insights-chip-label {
  color: $color-gray-100;
  display: inline-block;
  white-space: nowrap;
}

.MSI__insights-holder {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  margin: auto;
  padding: 0;
}

.MSI__insights-bar {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
  height: 20px;
  margin: auto;
  max-height: 20px;
  min-width: 300px;
  padding: 0;
  width: 100%;
}

.MSI__insights-bar-negative {
  background-color: $color-gray-50;
  border-bottom-left-radius: $border-radius-lg;
  border-top-left-radius: $border-radius-lg;
  height: .4rem;
  text-align: center;
}


.MSI__insights-bar-normal {
  align-items: center;
  background-color: $color-gray-30;
  border-left: $border-width-sm solid $color-gray-60;
  border-right: $border-width-sm solid $color-gray-60;
  height: 1.6rem;
  margin-bottom: auto;
  margin-top: auto;
  text-align: center;
}

.MSI__insights-bar-normal-inside {
  background-color: $color-gray-60;
  height: .4rem;
  position: relative;
  top: .6rem;
  width: 100%;
}

.MSI__insights-bar-positive {
  background-color: $color-gray-50;
  border-bottom-right-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
  height: .4rem;
  text-align: center;
}

.MSI__insights-bar-circle-zero,
.MSI__insights-bar-circle-low,
.MSI__insights-bar-circle-high,
.MSI__insights-bar-circle-normal {
  border-radius: $fn-border-radius-full;
  height: 1.2rem;
  margin: 0;
  padding: 0;
  width: 1.2rem;
  z-index: 2000;
}

.MSI__insights-bar-triangle {
  border-bottom: 1.2rem solid $color-gray-100;
  border-left: .6rem solid transparent;
  border-right: .6rem solid transparent;
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  z-index: 1999;
}

.MSI__insights-slider-icon {
  position: absolute;
  top: 4px;
  transform: translateX(7px);
}

.MSI__insights-bar-circle-zero {
  background-color: $color-gray-80;
}

.MSI__insights-bar-circle-low {
  background-color: $color-yellow-80;
}

.MSI__insights-bar-circle-high {
  background-color: $color-green-60;
}

.MSI__insights-bar-circle-normal {
  background-color: $color-blue-60;
}

.MSI_insights-popout-legend {
  align-items: center;
  display: inline-flex;
  margin: 0;
  padding: 0;
}

.MSI__RangeDistributionBar {
  margin: $spacing-md $spacing-md 0 $spacing-md;
  position: relative;
}
