@import '../../../../../scss/helpers/_all';

$btn-font-size: $text-font-size-sm;
$btn-padding-v: $spacing-sm;
$btn-padding-h: $spacing-md;
$btn-radius: $fn-border-radius-sm;
$btn-secondary-border-color: $color-indigo-60;
$btn-secondary-color: $btn-secondary-border-color;
$btn-disabledOpacity: .4;

@mixin btn-hover-focus($preventDisabled: false) {
  &:hover,
  &:focus,
  &:active,
  &.is-active {
    @if ($preventDisabled) {
      &:not([disabled]) {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@mixin btn-secondary-base-styles() {
  background-color: $color-white;
  border-color: $btn-secondary-border-color;
  color: $btn-secondary-color;
}
