@import "./_PinnedMenu-helpers.scss";

.PinnedMenuTypeAhead {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.PinnedMenuTypeAhead__Search {
  flex-grow: 0;
  padding: $PinnedMenuTypeAhead-padding-v $PinnedMenuTypeAhead-padding-right $PinnedMenuTypeAhead-padding-v $PinnedMenuTypeAhead-padding-left;
  position: relative;
}

.PinnedMenuTypeAhead__Content {
  flex-grow: 0;
  overflow-y: auto;
  position: relative; // https://github.com/fisshy/react-scroll/pull/33#issuecomment-309468753
}
