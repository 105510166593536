@import './helpers.scss';

.Option + .Option {
  padding-top: $spacing-xs;
}

.Option--marginMd {
  margin-left: $spacing-md;
}

.Option__label {
  @include u-resetLabel();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -$Options-spacing;
  margin-top: -#{calc($Options-spacing / 2)};
}

.Option__label--inlineFlex {
  display: inline-flex;
}

.Option__item {
  margin-left: $Options-spacing;
}

.Option__item--srOnly {
  @include u-srOnly();
}

.Option__input {
  @include u-srOnly();
}

.Option__text {
  $fontSize: $base-font-size;
  font-size: $fontSize;
  line-height: calc($Option-fixed-line-height / $fontSize);
  transition: all #{$Options-svg-transition-miliseconds}ms ease-in-out;
}

.Option__text--disabled {
  color: $color-gray-60;
}

.Option__text--bold {
  font-family: map-get($fonts, opensanssemibold);
}

.Option__secondary {
  margin-bottom: $spacing-xs;
  margin-top: -$spacing-xs;
  padding-left: $Options-secondary-padding;
}

.Option__success {
  color: $color-green-60;
}

.Option {
  &--boxView {
    align-items: center;
    background: $color-white;
    border: $border-width-sm solid $color-gray-50;
    border-radius: $fn-border-radius-md;
    box-shadow: .01rem .1rem .125rem .01rem $color-gray-60;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    height: 52px;
    justify-content: left;
    margin-bottom: $spacing-md;
    margin-left: $spacing-sm;
    margin-top: $spacing-sm;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
  }

  &--boxView__active {
    background: $color-indigo-20;
    border: $border-width-sm solid $color-indigo-60;
  }
}
