@import './helpers';

.Median {
  @include clearfix();

  &__row {
    $selector: &;

    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -(calc($Median-gutter / 2));
    margin-left: -$Median-gutter;
    margin-top: -(calc($Median-gutter / 2));

    &--noWrap {
      flex-wrap: nowrap;
    }

    &--verticalFlush {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
