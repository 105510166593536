@import './helpers.scss';

.MoreMenuWrapper {
  background-clip: padding-box;
  background-color: $color-white;
  border-radius: 0 0 $border-radius-sm $border-radius-sm;
  min-width: 1.5rem;
  overflow: hidden;
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
}

:global(.tether-element-attached-right) .MoreMenuWrapper {
  border-top-left-radius: $border-radius-sm;
}
