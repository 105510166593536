@import './_Panel-helpers';

.Panel {
  @include u-flexColumn();
  background-color: $color-white;
  bottom: 0;
  left: 0;
  overflow: hidden; //needed for drawer
  position: absolute;
  right: 0;
  top: 0;
  transition: padding-bottom .2s ease-in-out;
}

// chain not selectors to select neither
:global(.SaveBar__Body--isOpen) .Panel:not(.u-relative):not(.Panel--drawer) {
  padding-bottom: $Savebar-height;
}

.Panel__hd {
  @include u-flexAuto();
  border-bottom: $border-width-sm solid $Panel-border-color;
  min-height: 4.8rem;
  padding: $Panel-padding-vertical $Panel-padding-horizontal;
  padding-right: $spacing-xxl;
  position: relative;
}

.Panel__hd--danger {
  background-color: $color-red-100;
}

.Panel__hd--success {
  background-color: $color-green-100;
}

.Panel__hd--danger,
.Panel__hd--success,
.Panel__hd--danger .Panel__close::before,
.Panel__hd--success .Panel__close::before {
  color: $color-white;
}

.Panel__close {
  position: absolute;
  right: $spacing-md;
  top: 1.2rem;
}

.Panel__bd {
  @include u-flexColumn();
  @include u-flexGrow();
  position: relative;
}

.Panel__bd__container {
  @include u-flexColumn();
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.Panel__bd__col {
  @include u-flexAuto();
  @include bp-min(map-get($screen-sizes, md)) {
    height: 100%;// needed for drawer
  }
  overflow: auto;
  position: relative;
}

.Panel__bd__col--grey {
  background-color: $color-gray-30;
}

.Panel__bd__col__container__inner {
  padding: $Panel-padding-vertical $Panel-padding-horizontal;
}

/* stylelint-disable selector-bem-pattern */
.Panel__section + .Panel__section {
  margin-top: $Panel-padding-vertical;
}

.Panel__section + .Panel_section--disableVerticalSpacing {
  margin-top: 0;
}

.Panel__section--pulledTop {
  margin-top: -$Panel-padding-vertical;
}

/* stylelint-enable */
.Panel__section--flushHorizontal {
  margin-left: -$Panel-padding-horizontal;
  margin-right: -$Panel-padding-horizontal;
}

.Panel__bd__col__container__hdr {
  @include u-flexAuto();
  border-bottom: $border-width-sm solid $Panel-border-color;
  overflow: auto;
  padding: $Panel-padding-vertical;
}

.Panel__bd__col__container__ftr {
  @include u-flexAuto();
  background-color: $color-white;
  border-top: $border-width-sm solid $Panel-border-color;
  padding: $Panel-padding-vertical $Panel-padding-horizontal;
}

.Panel__bd__col__container__ftr--flush {
  padding: 0;
}

.Panel__bd__col__container__bd {
  @include u-flexGrow();
}

.Panel__bd__col__container__bd__container__inner {
  padding: $Panel-padding-vertical $Panel-padding-horizontal;
}

@include bp-max(map-get($screen-sizes, md)) {
  .Panel__bd {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include u-webkitScroll();
  }

  .Panel__bd__col--sidebar {
    border-bottom: $border-width-sm solid $Panel-border-color;
  }
}

@include bp-min(map-get($screen-sizes, md)) {
  .Panel--drawer {
    box-shadow: -.3rem 0 .2rem -.1rem map-get($container-shadow, color);
    left: auto;
    width: $Panel-sidebar-width;
  }

  .Panel__bd {
    flex-direction: row;
  }

  .Panel__bd__container {
    flex-direction: row;
  }

  .Panel__bd__col {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .Panel__bd__col--sidebar {
    @include u-flexAuto();
    width: $Panel-sidebar-width;
  }

  .Panel__bd__col + .Panel__bd__col {
    border-left: $border-width-sm solid $Panel-border-color;
  }

  .Panel__bd__col--main {

  }

  .Panel__bd__col__container__bd {
    position: relative;
  }

  .Panel__bd__col__container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .Panel__bd__col__container__bd__container {
    @include u-webkitScroll();
    bottom: 0;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* Component-level utilities */
/* These need to state at the bottom to override other rules */
.u-static {
  position: static;
}

.u-relative {
  position: relative;
}
