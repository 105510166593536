@import '../../../../scss/helpers/_all';

$FieldInput-color: (
    default: $color-gray-100,
    placeholder: $color-gray-80,
    disabled: $color-gray-80
);

$FieldInput-border-color: (
    default: $color-gray-60,
    focus: $color-indigo-60,
    disabled: $color-gray-50,
    error: $color-red-100,
    success: $color-green-100
);

$Field-background-color: (
    default: $color-white,
    disabled: $color-gray-10,
    itemHover: $color-indigo-20
);

$FieldInput-border-radius: $fn-border-radius-sm;
$FieldInput-padding: (
    v: $spacing-sm,
    h: $spacing-md
);
$FieldInput-border-width: $border-width-sm;
$FieldInput-border: $FieldInput-border-width solid map-get($FieldInput-border-color, default);
$Field-transition-property: background-color, color, border-color, box-shadow;
$Field-transition-duration: .25s;
$Field-transition-timing-function: ease-in-out;

$Field-addon-icon-size: $text-font-size-md;
$Field-prepend-width: 2.8rem;
$Field-addon-width: $Field-addon-icon-size * 2;
$Field-menu-shadow: 0 .4rem .8rem 0 rgba(0,0,0, .2);
$Field-menu-vertical-offset: .4rem;
$Field-input-font-size: $text-font-size-sm;
$Field-input-fixed-lined-height: $text-line-height-sm;

$Field-icon-width: 3.6rem;

@mixin Field-input-transition() {
  transition-duration: $Field-transition-duration;
  transition-property: $Field-transition-property;
  transition-timing-function: $Field-transition-timing-function;
}

@mixin Field-input-focus() {
  border-color: map-get($FieldInput-border-color, focus);
  box-shadow: inset 0 0 0 .1rem map-get($FieldInput-border-color, focus);
}

@mixin Field-input-base() {
  @include Field-input-transition();
  background-color: map-get($Field-background-color, default);
  border: $FieldInput-border;
  border-radius: $FieldInput-border-radius;
  box-sizing: border-box;
  color: map-get($FieldInput-color, default);
  display: block;
  font-size: $Field-input-font-size;
  line-height: $Field-input-fixed-lined-height;
  outline: none;
  padding: map-get($FieldInput-padding, v) map-get($FieldInput-padding, h);
  width: 100%;

  &::placeholder {
    @include Field-input-transition();
    color: map-get($FieldInput-color, placeholder);
  }

  &:focus {
    @include Field-input-focus();
  }

  &:disabled {
    background-color: map-get($Field-background-color, disabled);
    border-color: map-get($FieldInput-border-color, disabled);
    cursor: not-allowed;
  }

  &:disabled,
  &:disabled::placeholder {
    color: map-get($FieldInput-color, disabled);
  }
}

@mixin Field-input-error() {
  border-color: map-get($FieldInput-border-color, error);
}

@mixin Field-input-element-styles() {
  @include Field-input-base();

  &--hasPrepend {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &--hasAppend {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &--hasIconLeft {
    padding-left: $Field-icon-width;
  }

  &--hasIconRight {
    padding-right: $Field-icon-width;
  }

  &--error {
    @include Field-input-error();
  }
}
