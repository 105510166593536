@import '../GroupCard/helpers.scss';

.GroupCardAccordion__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
};

.GroupCardAccordion__container--borderTop {
  border-top: $border-width-sm solid $color-gray-50;
}

.GroupCardAccordion__container--borderBottom {
  border-bottom: $border-width-sm solid $color-gray-50;
}

.GroupCardAccordion__labelContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: $spacing-sm;
}

.FormLabel {
  color: $color-red-80;
}

.GroupCardAccordion__labelContent--header {
  font-family: Open Sans SemiBold, helvetica, sans-sarif;
  font-size: 16px;
  font-weight: inherit;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: $spacing-xs;
  word-break: break-word;
}

.GroupCardAccordion__labelContent--header--completed {
  color: $color-gray-80;
  font-family: Open Sans SemiBold, helvetica, sans-sarif;
  font-size: 16px;
  font-weight: inherit;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: $spacing-xs;
  word-break: break-word;
}

.GroupCardAccordion__labelContent--optional {
  color: $text-muted;
  font-size: 12px;
  letter-spacing: 0;
  margin: 0;
}

.GroupCardAccordion__content {
  align-self: center;
  flex-grow: 1;
  padding: $spacing-md;
};

.GroupCardAccordion__label {
  align-self: center;
  flex-grow: 1;
  word-break: break-all;
};

.GroupCardAccordion--default {
  background-color: inherit;
}

.GroupCardAccordion__header--info {
  background-color: $color-blue-20;
  position: relative;
}

.GroupCardAccordion__header--warning {
  background-color: $color-yellow-20;
  position: relative;
}

.GroupCardAccordion__header--critical {
  background-color: $color-red-20;
  position: relative;
}

.GroupCardAccordion__action {
  @include GroupCard-right();
  align-items: center;
  display: flex;
  justify-content: center;

  & > Button {
    color: $color-gray-100;

    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .14);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }

    span {
      margin-right: 0;
    }
  }
};

.GroupCardAccordion__expander {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $spacing-sm;
};

.IconComplete {
  color: $color-green-60;
};

.Icon {
  color: $color-indigo-60;
};

.CustomIcon {
  color: $color-gray-80;
  margin-right: -$spacing-md;
}
