@import './helpers.scss';

@keyframes loadingAnimation {
  50% {
    opacity: .15;
  }
}

.Loader {
  position: relative;
}

.Loader__wrapper,
.Loader__backdrop {
  position: absolute;
  z-index: map-get($z-index, loader);
}

.Loader__backdrop {
  background-color: rgba($color-white, .6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Loader__wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Loader--fill {
  height: 100%;
  min-height: 100%;
}

.Loader--fixed > .Loader__backdrop,
.Loader--fixed > .Loader__wrapper {
  position: fixed;
}
