@import '../_Field-helpers';

.Icon {
  @include u-resetButton();
  color: $color-gray-90;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $Field-icon-width;
  z-index: 1; // just needs a z-index to cover input background
}

.Icon--right {
  right: 0;
}

.Icon--left {
  left: 0;
}
