@import '../../scss/helpers/_all';

$Median-gutter: $spacing-md;

@mixin Median-item() {
  margin-bottom: calc($Median-gutter / 2);
  margin-left: $Median-gutter;
  margin-top: calc($Median-gutter / 2);

  &--breakWords {
    overflow: hidden;
    word-wrap: break-word;
  }

  &--alignRight {
    flex-grow: 1;
    text-align: right;
  }

  &--topAlign {
    align-self: flex-start;
  }

  &--bottomAlign {
    align-self: flex-end;
  }

  &--verticalFlush {
    margin-bottom: 0;
    margin-top: 0;
  }

  &--leftFlush {
    margin-left: 0;
  }

  &--overflowHidden {
    overflow: hidden;
  }
}
