@import './helpers';

.FilterSearch__filterSearch {
  @include Toolbar-filterSearch();
}

.FilterSearch__filterOptions {
  @include Toolbar-filterOptions();
}

.FilterSearch__filterPanel {
  @include Toolbar-filterPanel();
}

.FilterSearch__filterOption {
  @include Toolbar-filterOption();
}

.FilterSearch__filterOption__Section__Name__Space {
  padding-left: $spacing-md + $spacing-md;
}

.FilterSearch__filtersEmpty {
  @include Toolbar-filterEmpty();
}

.FilterSearch__filterSection__Name {
  color: $color-gray-70;
  font-size: $text-font-size-sm;
  padding: $spacing-md;
  text-transform: capitalize;
}

.FilterSearch__filterSection {
  @include Toolbar-filterSection();
}

.FilterSearch__filterSection + .FilterSearch__filterSection {
  border-top: $border-width-sm solid $color-gray-50;
}
