@import '../../scss/helpers/_all.scss';

.ExampleList {
  padding-top: $spacing-lg;
}

// Uses variables set in _tokens.scss file
.ExampleListItem--text {
  &-xs {
    font-size: $text-font-size-xxs;
  }

  &-sm {
    font-size: $text-font-size-xs;
  }

  &-md {
    font-size: $text-font-size-sm;
  }

  &-lg {
    font-size: $text-font-size-md;
  }

  &-xl {
    font-size: $text-font-size-xl;
  }
}
