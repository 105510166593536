@mixin arrow($direction, $color, $width, $depth) {
  content: ' ';
  @if $direction == "down" {
    border-bottom: none;
    border-left: calc($width/2) transparent solid;
    border-right: calc($width/2) transparent solid;
    border-top: $depth solid $color;
  }
}

// ---------------------------------------------------------------------
// Max-width media query
//----------------------------------------------------------------------
@mixin bp-max($point) {
  @media (max-width: $point - calc(1/16)) {
    @content;
  }
}

// ---------------------------------------------------------------------
// Min-width media query
//----------------------------------------------------------------------
@mixin bp-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

// ---------------------------------------------------------------------
// Min-width and Max-width media query
//----------------------------------------------------------------------
@mixin bp-from-to($point1, $point2) {
  @media (min-width: $point1) and (max-width: $point2 - calc(1/16)) {
    @content;
  }
}

// ---------------------------------------------------------------------
// Responsive suffix up
//----------------------------------------------------------------------
@mixin responsive-suffix-up($includeBase: false) {
  @if ($includeBase) {
    & {
      @content;
    }
  }
  @each $label, $map in $screen-sizes {
    @include bp-min($map) {
      &--up-#{$label} {
        @content;
      }
    }
  }
}

// ---------------------------------------------------------------------
// Responsive suffix down
//----------------------------------------------------------------------
@mixin responsive-suffix-down($includeBase: false) {
  @if ($includeBase) {
    & {
      @content;
    }
  }
  @each $label, $map in $screen-sizes {
    @include bp-max($map) {
      &--down-#{$label} {
        @content;
      }
    }
  }
}


// ---------------------------------------------------------------------
// Responsive suffix both
//----------------------------------------------------------------------
@mixin responsive-suffix-both($includeBase: false) {
  @if ($includeBase) {
    & {
      @content;
    }
  }
  @include responsive-suffix-down($includeBase) {
    @content;
  }
  @include responsive-suffix-up() {
    @content;
  }
}

// ---------------------------------------------------------------------
// Clearfix
//----------------------------------------------------------------------
@mixin clearfix() {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}
