@import './_NavigationMenu-helpers.scss';

.NestedMenuItem__applyNegativeMargin {
  margin-right: -$spacing-xs;
}

.NestedMenuItem {
  .NestedMenuItem__applyHoverEffect:hover {
    background-color: $color-gray-10;
    cursor: pointer;
  }

  .NestedMenuItem__applyPadding {
    padding-left: $spacing-md;

    &--level1 {
      padding-left: $spacing-md * 1;
    }

    &--level2 {
      padding-left: $spacing-md * 2;
    }

    &--level3 {
      padding-left: $spacing-md * 3;
    }

    &--level4 {
      padding-left: $spacing-md * 4;
    }

    &--level5 {
      padding-left: $spacing-md * 5;
    }

    &--level6 {
      padding-left: $spacing-md * 6;
    }
  }
}
