@import '../../scss/helpers/all';

.File__container {
  margin-bottom: $spacing-sm;
}

.File__closePartial {
  @include u-resetButton();
}

.File__thumbnail {
  height: 100px;
  margin-right: $spacing-sm;
  width: 100px;
}
